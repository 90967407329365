import React from 'react';
import PropTypes from 'prop-types';

import { withNamespaces } from 'react-i18next';

import SelectField from 'src/components/shared/SelectField/SelectField';
import Columns from 'src/components/shared/Columns/Columns';

const PersonalDataCategoriesField = ({
  name,
  options,
  required,
  values,
  className,
  appendPDC,
  t,
  ...props
}) => {
  const parse = ({ value }) => Array.from(new Set([...values, value]));

  const filteredOptions = options.filter(({ id }) => !values.includes(id));

  return (
    <Columns className={className}>
      {{
        left: (
          <SelectField
            {...props}
            {...{ name, required, parse }}
            options={filteredOptions}
            label={t('shared.personal_data_categories')}
            placeholder={t('declaration_form.pdc_step.personal_data_category_placeholder')}
            onChange={appendPDC}
          />
        ),
      }}
    </Columns>
  );
};

PersonalDataCategoriesField.defaultProps = {
  className: '',
  required: false,
  values: [],
};

PersonalDataCategoriesField.propTypes = {
  appendPDC: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  required: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(PersonalDataCategoriesField);

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import urls from 'src/constants/urls';

import PrivateRoute from 'src/components/shared/PrivateRoute/PrivateRoute';
import PublicRoute from 'src/components/shared/PublicRoute/PublicRoute';

import ConsentDeclarationsPage from 'src/components/ConsentDeclarationsPage/ConsentDeclarationsPage';
import ConsentDeclarationDetailsPage from 'src/components/ConsentDeclarationDetailsPage/ConsentDeclarationDetailsPage';
import ConsentsPage from 'src/components/ConsentsPage/ConsentsPage';
import ConsentDetailsPage from 'src/components/ConsentDetailsPage/ConsentDetailsPage';
import ContractDeclarationsPage from 'src/components/ContractDeclarationsPage/ContractDeclarationsPage';
import ContractDeclarationDetailsPage from 'src/components/ContractDeclarationDetailsPage/ContractDeclarationDetailsPage';
import ContractsPage from 'src/components/ContractsPage/ContractsPage';
import ContractDetailsPage from 'src/components/ContractDetailsPage/ContractDetailsPage';
import DashboardPage from 'src/components/DashboardPage/DashboardPage';
import LandingPage from 'src/components/LandingPage/LandingPage';
import SignIn from 'src/components/LandingPage/SignIn/SignIn';
import SignUp from 'src/components/LandingPage/SignUp/SignUp';
import UsersPage from 'src/components/UsersPage/UsersPage';
import UserDetailsPage from 'src/components/UserDetailsPage/UserDetailsPage';
import ProcessorsPage from 'src/components/ProcessorsPage/ProcessorsPage';
import ProcessorDetailsPage from 'src/components/ProcessorDetailsPage/ProcessorDetailsPage';
import ProcessorRecordDetailsPage from 'src/components/ProcessorRecordDetailsPage/ProcessorDetailsPage';
import ProfilePage from 'src/components/ProfilePage/ProfilePage';
import ResetPasswordPage from 'src/components/shared/ResetPasswordPage/ResetPasswordPage';
import TermsAndConditionsPage from 'src/components/TermsAndConditionsPage/TermsAndConditionsPage';
import PrivacyPolicyPage from 'src/components/PrivacyPolicyPage/PrivacyPolicyPage';
import EmbedPage from 'src/components/EmbedPage/EmbedPage';
import DeclarationPage from 'src/components/DeclarationPage/DeclarationPage';
import ConfirmRegistrationPage from 'src/components/ConfirmRegistrationPage/ConfirmRegistrationPage';
import DeclarationDetails from 'src/components/DeclarationDetails';

const Routes = () => (
  <Switch>
    <PrivateRoute exact path={urls.dashboard()} component={DashboardPage} />
    <PrivateRoute exact path={urls.declarations()} component={ConsentDeclarationsPage} />
    <PrivateRoute exact path={urls.declarations('new')} component={ConsentDeclarationsPage} />
    <PrivateRoute exact path={urls.declarations(':id')} component={ConsentDeclarationDetailsPage} />
    <PrivateRoute exact path={urls.consents()} component={ConsentsPage} />
    <PrivateRoute exact path={urls.consents(':id')} component={ConsentDetailsPage} />
    <PrivateRoute exact path={urls.contractDeclarations()} component={ContractDeclarationsPage} />
    <PrivateRoute exact path={urls.contractDeclarations('new')} component={ContractDeclarationsPage} />
    <PrivateRoute exact path={urls.contractDeclarations(':id')} component={ContractDeclarationDetailsPage} />
    <PrivateRoute exact path={urls.contracts()} component={ContractsPage} />
    <PrivateRoute exact path={urls.contracts(':id')} component={ContractDetailsPage} />
    <PrivateRoute exact path={urls.users()} component={UsersPage} />
    <PrivateRoute exact path={urls.users('new')} component={UsersPage} />
    <PrivateRoute exact path={urls.users(':id')} component={UserDetailsPage} />
    <PrivateRoute exact path={urls.processors()} component={ProcessorsPage} />
    <PrivateRoute exact path={urls.processors('new')} component={ProcessorsPage} />
    <PrivateRoute exact path={urls.processors(':id')} component={ProcessorDetailsPage} />
    <PrivateRoute exact path={urls.processorRecords(':controller_id', ':id')} component={ProcessorRecordDetailsPage} />
    <PrivateRoute path={urls.settings()} component={ProfilePage} />
    <PrivateRoute path={urls.embed(':id')} component={EmbedPage} />
    <PublicRoute path={urls.passwordReset()} component={ResetPasswordPage} />
    <Route exact path={urls.signIn()} component={SignIn} />
    <Route exact path={urls.signUp()} render={SignUp} />
    <PublicRoute path={urls.landing()} component={LandingPage} />
    <PublicRoute path={urls.confirmRegistration()} component={ConfirmRegistrationPage} />
    <PublicRoute path={urls.declarationDetails()} component={DeclarationDetails} />
    <Route path={urls.termsAndConditions()} component={TermsAndConditionsPage} />
    <Route path={urls.privacyPolicy()} component={PrivacyPolicyPage} />
    <Route path={urls.declaration(':id')} component={DeclarationPage} />
    <Route exact path="/" render={() => <Redirect to={urls.signIn()} />} />
    <Route render={() => <Redirect to={urls.dashboard()} />} />
  </Switch>
);

export default Routes;

import React from 'react';

import Trans from 'src/components/shared/Trans/Trans';

import urls from 'src/constants/urls';
import NoDeclarationsImage from 'src/components/image/NoDeclarationsImage';

import styles from './NoDeclarations.module.scss';

const NoDeclarations = () => (
  <div className={styles.noDeclarations}>
    <NoDeclarationsImage />

    <h3 className={styles.prompt}>
      <Trans>consent_declarations_page.no_declarations.description</Trans>
    </h3>

    <div className={styles.protip}>
      <Trans vars={{ settings_href: urls.settings() }}>
        consent_declarations_page.no_declarations.protip
      </Trans>
    </div>
  </div>
);

export default NoDeclarations;

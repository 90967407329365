import React from 'react';
import PropTypes from 'prop-types';

import getInitials from 'src/utils/getInitials';

import styles from './InitialsAvatar.module.scss';

const InitialsAvatar = ({ children }) => {
  const initials = getInitials(children);

  return <div className={styles.initials}>{initials}</div>;
};

InitialsAvatar.propTypes = {
  children: PropTypes.string.isRequired,
};

export default InitialsAvatar;

import React from 'react';
import source from 'images/checkmark.svg';

import Icon from './Icon';

const Checkmark = ({ ...props }) => (
  <Icon alt="checkmark icon" height="12" width="16" src={source} {...props} />
);

export default Checkmark;

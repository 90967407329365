import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { Field } from 'react-final-form';

import EditIcon from 'src/components/image/EditIcon';

import styles from './DeclarationEditableText.module.scss';

const onTextareaChange = (onChange, e) => {
  e.target.value = e.target.value.replace(/\n/g, '');
  onChange(e);
};

const DeclarationEditableText = ({
  name,
  initialValue,
  isEditable,
  className,
  iconClassName,
  placeholder,
}) =>
  isEditable ? (
    <Field name={name}>
      {({ input: { value, onChange, ...input } }) => (
        <div className={styles.headerContainer}>
          {/* eslint-disable react/jsx-no-bind */}
          <TextareaAutosize
            id={name}
            name={name}
            className={className}
            value={value}
            onChange={onTextareaChange.bind(null, onChange)}
            onKeyDown={onTextareaChange.bind(null, onChange)}
            {...input}
          />
          {/* eslint-enable react/jsx-no-bind */}

          {value.trim() === '' && (
            <span className={cn(styles.shadow, className)}>{placeholder}</span>
          )}
          <EditIcon className={cn(styles.editIcon, iconClassName)} />
        </div>
      )}
    </Field>
  ) : (
    <div className={className}>{initialValue}</div>
  );

DeclarationEditableText.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
};

DeclarationEditableText.defaultProps = {
  className: '',
  iconClassName: '',
};

export default DeclarationEditableText;

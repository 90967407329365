import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { withNamespaces } from 'react-i18next';

import { thirdPartyListPropTypes } from 'src/prop_types/declaration_prop_types';

import ThirdPartyListItem from './ThirdPartyListItem/ThirdPartyListItem';

import styles from './ThirdPartiesList.module.scss';

const ThirdPartiesList = ({ thirdParties, onDelete, onEdit, t, isBeingEdited }) => (
  <ul className={styles.list}>
    {isEmpty(thirdParties) ? (
      <span className={styles.empty}>{t('third_parties.no_targeted_third_parties')}</span>
    ) : (
      thirdParties.map(thirdParty => (
        <ThirdPartyListItem
          key={thirdParty.companyName}
          {...{ thirdParty, onDelete, onEdit, isBeingEdited }}
        />
      ))
    )}
  </ul>
);

ThirdPartiesList.defaultProps = {
  thirdParties: [], // eslint-disable-line
};

ThirdPartiesList.propTypes = {
  ...thirdPartyListPropTypes,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default withNamespaces()(ThirdPartiesList);

import React from 'react';
import source from 'images/group.svg';

import Icon from './Icon';

const Group = ({ ...props }) => (
  <Icon alt="Group" src={source} width="312" height="253" {...props} />
);

export default Group;

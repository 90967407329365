import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import DropdownArrow from 'src/components/image/DropdownArrow';

import Trans from 'src/components/shared/Trans/Trans';
import { eventPropTypes, versionPropTypes } from 'src/prop_types/declaration_prop_types';

import styles from './VersionsTableRow.module.scss';

const VersionsTableRow = ({
  event: { event, remoteIp, timestamp, version },
  onClick,
  isVersion,
  isOpen,
}) => (
  <tr
    className={cn(styles.versionsTableRow, {
      [styles.group]: isVersion,
    })}
    onClick={onClick}
  >
    <td
      className={cn(styles.iconColumn, {
        [styles.flipped]: isOpen,
      })}
    >
      {isVersion && (
        <div className={styles.iconContainer}>
          <DropdownArrow />
        </div>
      )}
    </td>
    <td className={cn(styles.versionColumn, styles.narrow)}>{isVersion ? version : event}</td>
    <td className={styles.narrow}>{isVersion && remoteIp}</td>
    <td className={styles.narrow}>{isVersion && <Trans date="ll">{timestamp}</Trans>}</td>
  </tr>
);

VersionsTableRow.propTypes = {
  isVersion: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  event: (props, ...args) =>
    props.isVersion
      ? versionPropTypes.isRequired(props, ...args)
      : eventPropTypes.isRequired(props, ...args),
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

VersionsTableRow.defaultProps = {
  isVersion: false,
  isOpen: false,
  onClick: () => {},
};

export default VersionsTableRow;

import React from 'react';
import source from 'images/how_third.svg';

import Icon from './Icon';

const HowThird = ({ ...props }) => (
  <Icon alt="How third" src={source} width="161" height="143" {...props} />
);

export default HowThird;

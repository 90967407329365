import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import withToast from 'src/components/Toast/withToast/withToast';

import Trans from 'src/components/shared/Trans/Trans';

import {
  getPersonalDataCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from 'src/api/personal_data_categories';

import { checkPDC } from 'src/utils/checkPDC';

import Button from 'src/components/shared/Button/Button';

import PersonalDataCategory from './PersonalDataCategory/PersonalDataCategory';

import styles from './PersonalDataCategories.module.scss';

class PersonalDataCategories extends Component {
  static propTypes = {
    openToast: PropTypes.func.isRequired,
    toggleHint: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    categories: [],
    formOpen: false,
    addedCategories: [],
    isEditing: false,
  };

  componentDidMount() {
    const { toggleHint } = this.props;
    toggleHint(true);
    this.fetchCategories();
  }

  onEditStart = () => {
    const { isEditing } = this.state;
    const { openToast, t } = this.props;
    if (isEditing) {
      openToast({
        message: t('profile_page.personal_data_categories.toasts.on_edit_blocked'),
        type: 'danger',
      });
      return false;
    }

    this.setState({ isEditing: true });
    return true;
  };

  checkPDCValidity = (...attrs) => {
    const { categories } = this.state;
    const { t } = this.props;
    return checkPDC({ categories, t })(...attrs);
  };

  onEditEnd = () => this.setState({ isEditing: false });

  onCreate = ({ name }) =>
    new Promise(resolve => {
      const { openToast } = this.props;
      const { closeForm, checkPDCValidity, onEditEnd } = this;
      const isPDCValid = checkPDCValidity({ name });

      if (isPDCValid.message) {
        openToast({
          message: isPDCValid.message,
          type: 'danger',
        });

        return resolve({
          name: isPDCValid.message,
        });
      }

      closeForm();

      this.setState(prevState => ({
        addedCategories: [...prevState.addedCategories, name],
        categories: [...prevState.categories, { name, id: name }],
      }));

      resolve();
      onEditEnd();
      return createCategory({ name });
    });

  onDelete = id => {
    this.setState(({ categories }) => ({
      categories: categories.filter(pdc => pdc.id !== id),
    }));

    this.onEditEnd();
    return deleteCategory(id);
  };

  onUpdate = ({ id, name }) =>
    new Promise(resolve => {
      const { openToast } = this.props;
      const { checkPDCValidity } = this;
      const isPDCValid = checkPDCValidity({ id, name });

      if (isPDCValid.message) {
        openToast({
          message: isPDCValid.message,
          type: 'danger',
        });

        return resolve({
          name: isPDCValid.message,
        });
      }

      resolve();

      this.setState(({ categories }) => ({
        categories: categories.map(pdc => {
          if (pdc.id !== id) {
            return pdc;
          }

          return {
            ...pdc,
            name,
          };
        }),
      }));

      this.onEditEnd();
      return updateCategory(id, { name });
    });

  fetchCategories = () => {
    getPersonalDataCategories().then(categories => this.setState({ categories, formOpen: false }));
  };

  openForm = () => {
    const { t, openToast } = this.props;
    if (!this.onEditStart()) {
      return openToast({
        message: t('profile_page.personal_data_categories.toasts.on_create_blocked'),
        type: 'danger',
      });
    }

    return this.setState({ formOpen: true });
  };

  closeForm = () => this.setState({ formOpen: false, isEditing: false });

  render() {
    const { categories, formOpen, addedCategories } = this.state;
    const { onDelete, onUpdate, onCreate, openForm, closeForm, onEditStart } = this;

    const categoriesElements = categories.map(({ id, name, dependants }) => (
      <PersonalDataCategory
        wasJustCreated={addedCategories.includes(name)}
        name={name}
        id={id}
        onCancel={closeForm}
        key={id}
        onDelete={() => onDelete(id)}
        onSubmit={onUpdate}
        onEditStart={onEditStart}
        dependants={dependants}
      />
    ));

    return (
      <div className={styles.preferences}>
        <h3 className={styles.title}>
          <Trans>shared.personal_data_categories</Trans>
        </h3>
        {formOpen && (
          <PersonalDataCategory
            id="create"
            onSubmit={onCreate}
            onCancel={closeForm}
            isCreating
            onEditStart={onEditStart}
          />
        )}
        {categoriesElements}
        <Button kind="outline" className={styles.row} onClick={openForm} type="button">
          <Trans>profile_page.personal_data_categories.new_pdc_button</Trans>
        </Button>
      </div>
    );
  }
}

export default withNamespaces()(withToast(PersonalDataCategories));

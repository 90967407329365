import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withNamespaces } from 'react-i18next';

import Button from 'src/components/shared/Button/Button';
import { thirdPartyPropTypes } from 'src/prop_types/declaration_prop_types';

import styles from './ThirdPartyListItem.module.scss';

const ThirdPartyListItem = ({ className, thirdParty, onDelete, onEdit, isBeingEdited, t }) => (
  <li className={cn(styles.listItem, className)}>
    <span>{thirdParty.companyName}</span>
    <div className={styles.buttons}>
      {!isBeingEdited && (
        <Button
          kind="ghost"
          type="button"
          onClick={() => onEdit(thirdParty)}
          disabled={isBeingEdited}
        >
          {t('shared.edit')}
        </Button>
      )}
      <Button kind="ghostDanger" onClick={() => onDelete(thirdParty)} type="button">
        {t('shared.delete')}
      </Button>
    </div>
  </li>
);

ThirdPartyListItem.defaultProps = {
  className: null,
  isBeingEdited: false,
};

ThirdPartyListItem.propTypes = {
  ...thirdPartyPropTypes,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isBeingEdited: PropTypes.bool,
  className: PropTypes.string,
};

export default withNamespaces()(ThirdPartyListItem);

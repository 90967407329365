import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import pseudoLocalize from 'src/utils/pseudoLocalize';
import mapDeepObject from 'src/utils/mapDeepObject';

import 'moment/locale/nb';

const parseLanguages = langs =>
  Object.entries(langs).reduce((prev, [key, value]) => {
    const [langCode, langSubCode] = key.split('_');
    return {
      ...prev,
      [langSubCode ? `${langCode}-${langSubCode.toUpperCase()}` : langCode]: {
        translation: value,
      },
    };
  }, {});

const parsedLanguages = parseLanguages(gon.messages_all); // eslint-disable-line no-undef

const hideDevEnglish = process.env.HIDE_DEV_ENGLISH === 'true';

i18next.use(LngDetector).init({
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: hideDevEnglish ? ['en', 'dev'] : ['en-DEV', 'dev'],
  resources: {
    ...parsedLanguages,
    ...(hideDevEnglish
      ? {}
      : {
          'en-DEV': {
            translation: {
              moment_date_key: 'en',
              ...mapDeepObject(parsedLanguages.en, pseudoLocalize).translation,
            },
          },
        }),
  },
  detection: {
    order: ['querystring', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],

    caches: ['cookie'],
    lookupQuerystring: 'locale',
    lookupCookie: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    cookieDomain: window.location.hostname.replace(/^[^.]+\./, ''),
  },
});

export default i18next;

import { cardsPageSize } from 'src/constants/page_sizes';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';

import { toCamelCase, toSnakeCase } from 'src/utils/caseMappers';

import apiRequest from './apiRequest';
import pagingParams from './pagingParams';

import EP from './endpoints';

const pascalCase = s => s.charAt(0).toUpperCase() + camelCase(s.substring(1));

const formatProcessor = processor => ({
  data: {
    type: 'processor',
    attributes: {
      ...toSnakeCase(processor),
    },
  },
});

const parseWebhook = ({ events, ...webhook }) =>
  toCamelCase({
    events: events.map(event => snakeCase(event.replace('Event::', ''))),
    ...webhook,
  });

const formatWebhook = ({ events, ...webhook }) => ({
  data: {
    type: 'webhook',
    attributes: toSnakeCase({
      events: events.map(event => `Event::${pascalCase(event)}`),
      ...webhook,
    }),
  },
});

const parseProcessor = ({ id, attributes: { events, webhookEndpoints, ...attributes } }) => ({
  id,
  ...attributes,
  webhooks: webhookEndpoints.map(parseWebhook),
});

export const getProcessors = page =>
  apiRequest(EP.processors, {
    query: pagingParams(page, cardsPageSize),
  }).then(({ data, meta }) => ({
    processors: data.map(datum => parseProcessor(toCamelCase(datum))),
    meta: toCamelCase(meta),
  }));

export const getAllProcessors = () =>
  apiRequest(`${EP.processors}/list`).then(({ data }) => ({
    processors: data.map(datum => parseProcessor(toCamelCase(datum))),
  }));

export const createProcessor = processor =>
  apiRequest(EP.processors, {
    body: formatProcessor(processor),
  });

export const showProcessor = id =>
  apiRequest(`${EP.processors}/${id}`).then(({ data }) => parseProcessor(toCamelCase(data)));

export const updateProcessor = processor =>
  apiRequest(`${EP.processors}/${processor.id}`, {
    body: formatProcessor(processor),
    method: 'PATCH',
  });

export const updateWebhook = (processorId, webhook) =>
  apiRequest(`${EP.processors}/${processorId}/webhooks/${webhook.id}`, {
    body: formatWebhook(webhook),
    method: 'PATCH',
  }).then(({ data: { attributes, id } }) => parseWebhook({ ...attributes, id }));

export const createWebhook = (processorId, webhook) =>
  apiRequest(`${EP.processors}/${processorId}/webhooks`, {
    body: formatWebhook(webhook),
    method: 'POST',
  }).then(({ data: { attributes, id } }) => parseWebhook({ ...attributes, id }));

export const deleteWebhook = (processorId, webhook) =>
  apiRequest(`${EP.processors}/${processorId}/webhooks/${webhook.id}`, {
    method: 'DELETE',
  });

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';

import ErrorMessage from 'src/components/shared/ErrorMessage/ErrorMessage';
import Trans from 'src/components/shared/Trans/Trans';

import { reduceValidators, isRequired } from 'src/utils/validators';
import { shouldDisplayError } from 'src/utils/shouldDisplayError';

import styles from './Checkbox.module.scss';

const Checkbox = ({ label, name, id, className, required, validators, type, disabled, value, ...props }) => {
  const prefilledValidators = required ? [isRequired, ...validators] : validators;
  const validate = reduceValidators(prefilledValidators);
  const disabledClass = disabled ? styles.disabled : ''

  return (
    <Field {...{ name, validate }} type={type} value={value}>
      {({
        input: { ...input },
        meta: { valid, error, touched, dirty, submitError, dirtySinceLastSubmit },
      }) => (
        <div className={cn(className, styles.field)}>
          <input {...input} {...props} type={type} disabled={disabled} id={id} />

          <label className={styles.label} htmlFor={id} disabled={disabled}>
            <div className={`${styles[type]} ${disabledClass}`}/>
            {label && <span className={styles.labelText}>{label}</span>}
          </label>

          {shouldDisplayError({ valid, touched, dirty, dirtySinceLastSubmit, submitError }) && (
            <ErrorMessage>
              <Trans noMd>{error || submitError}</Trans>
            </ErrorMessage>
          )}
        </div>
      )}
    </Field>
  );
};

Checkbox.defaultProps = {
  required: false,
  label: null,
  className: '',
  id: '',
  disabled: false,
  type: 'checkbox',
  validators: [],
};

Checkbox.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  validators: PropTypes.arrayOf(PropTypes.func),
};

export default Checkbox;

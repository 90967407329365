import React from 'react';
import cn from 'classnames';

import Button from 'src/components/shared/Button/Button';

import styles from './ButtonWithInfo.module.scss';

const ButtonWithInfo = ({
  containerClassName,
  infoClassName,
  infoText,
  className,
  ...buttonProps
}) => (
  <div className={cn(styles.container, containerClassName)}>
    <div className={cn(styles.info, infoClassName)}>{infoText}</div>

    <Button {...buttonProps} className={cn(styles.button, className)} />
  </div>
);

export default ButtonWithInfo;

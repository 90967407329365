import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Label from 'src/components/shared/Label/Label';
import cn from 'classnames';

import styles from './SearchField.module.scss';

const SearchField = ({ label, name, hideOptional, onChange, currentValue, placeholder }) => {
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <Fragment>
      <Label htmlFor={name} hideOptional={hideOptional}>
        {label}
      </Label>
      <input
        id={name}
        type="text"
        value={currentValue}
        className={cn(styles.inputControl)}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </Fragment>
  );
};

SearchField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  hideOptional: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchField.defaultProps = {
  label: 'Search',
  hideOptional: true,
  currentValue: '',
  placeholder: '',
};

export default SearchField;

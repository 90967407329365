export const shouldDisplayCheckmark = ({
  required,
  valid,
  value,
  dirtySinceLastSubmit,
  submitError,
}) => {
  if (required && valid) {
    return true;
  }

  if (!required && valid && value) {
    return true;
  }

  if (submitError && dirtySinceLastSubmit) {
    return true;
  }

  return false;
};

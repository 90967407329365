import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Card from 'src/components/shared/Card/Card';
import Trans from 'src/components/shared/Trans/Trans';

import getInitials from 'src/utils/getInitials';

import styles from './DateCard.module.scss';

const DateCard = ({
  cardComponent,
  cardProps,
  title,
  subtitle,
  date,
  dateTitle,
  pillVisible,
  pillText,
  logoClassName,
  dateFallback,
  pillClassName,
}) => (
  <Card component={cardComponent} {...cardProps}>
    <div className={cn(styles.logo, logoClassName)}>{getInitials(title)}</div>
    {title && <span className={styles.title}>{title}</span>}
    {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    {pillVisible && <span className={cn(styles.pill, pillClassName)}>{pillText}</span>}
    <div className={styles.date}>
      <div className={styles.dateLabel}>{dateTitle}</div>
      <div>
        <Trans date fallback={dateFallback}>
          {date}
        </Trans>
      </div>
    </div>
  </Card>
);

DateCard.propTypes = {
  cardComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  // eslint-disable-next-line react/forbid-prop-types
  cardProps: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  dateTitle: PropTypes.string,
  pillVisible: PropTypes.bool,
  pillText: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
  logoClassName: PropTypes.string,
  pillClassName: PropTypes.string,
  dateFallback: PropTypes.string,
};

DateCard.defaultProps = {
  cardComponent: 'div',
  cardProps: {},
  title: '',
  subtitle: '',
  date: null,
  dateTitle: '',
  pillVisible: false,
  pillText: '',
  logoClassName: '',
  pillClassName: '',
  dateFallback: '',
};

export default DateCard;

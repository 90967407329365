import { toSnakeCase } from 'src/utils/caseMappers';

import replaceIfExists from 'src/utils/replaceIfExists';
import apiRequest from './apiRequest';

import EP from './endpoints';

const formatProfileDetails = ({
  current_password: currentPassword,
  new_password: newPassword,
  email,
  ...profile
}) =>
  toSnakeCase({
    data: {
      type: 'profile',
      attributes: {
        ...profile,
        ...(currentPassword && newPassword ? { currentPassword, newPassword } : {}),
        ...(currentPassword && email ? { currentPassword, email } : {}),
        language: replaceIfExists(profile.language, '-', '_').toLowerCase(),
      },
    },
  });

const formatProfileDeletion = password => ({
  data: {
    type: 'profile',
    attributes: {
      password_confirmation: password,
    },
  },
});

export const getProfileDetails = () =>
  apiRequest(EP.profile).then(({ data }) => ({
    ...data,
    attributes: {
      ...data.attributes,
      language: replaceIfExists(data.attributes.language, '_', '-'),
    },
  }));

export const updateProfileDetails = profile =>
  apiRequest(EP.profile, {
    method: 'PATCH',
    body: formatProfileDetails(profile),
  });

export const deleteProfile = password =>
  apiRequest(EP.profile, {
    method: 'DELETE',
    body: formatProfileDeletion(password),
  });

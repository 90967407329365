import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './List.module.scss';

const ListItem = ({ children, tight }) => {
  const startsWithLetter = /^\([a-z]+\)/.test(children[0]);

  if (startsWithLetter) {
    return (
      <li className={cn(styles.startsWithLetter, { [styles.tight]: tight })}>
        {children[0].replace(/^\([a-z]+\)/, '')}
      </li>
    );
  }

  return <li className={cn({ [styles.tight]: tight })}>{children}</li>;
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  tight: PropTypes.bool.isRequired,
};

export default ListItem;

import decode from 'jwt-decode';

import { toCamelCase } from 'src/utils/caseMappers';
import replaceIfExists from 'src/utils/replaceIfExists';

const ACCESS_TOKEN_KEY = 'access_token';

const getTokenFromSessionData = ({ attributes: { jwtToken } }) => jwtToken;
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY);

const isTokenExpired = token => {
  try {
    const decoded = decode(token);

    if (decoded.exp < Date.now() / 1000) {
      removeAccessToken();
      return true;
    }

    return false;
  } catch (err) {
    return false;
  }
};

export const setAccessToken = sessionData => {
  const token = getTokenFromSessionData(sessionData);

  localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const isLoggedIn = () => {
  const token = getAccessToken();

  return !!token && !isTokenExpired(token);
};

export const getUserData = () => {
  const token = getAccessToken();

  if (token) {
    const { email, fullName, sub: id, language } = toCamelCase(decode(token));

    return {
      email,
      fullName,
      id,
      language: replaceIfExists(language, '_', '-'),
    };
  }

  return {};
};

export const getCurrentOrganization = () => {
  const token = getAccessToken();

  if (token) {
    const {
      currentOrganization: { id },
    } = toCamelCase(decode(token));

    return id;
  }

  return null;
};

import React from 'react';

import styles from './LandingPage.module.scss';
import Content from './Content/Content';

const LandingPage = () => (
  <main className={styles.page}>
    <section>
      <Content />
    </section>
  </main>
);

export default LandingPage;

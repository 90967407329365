import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import filter from 'lodash/filter';

import Trans from 'src/components/shared/Trans/Trans';

import Button from 'src/components/shared/Button/Button';

import styles from './SelectedContractsInfo.module.scss';

const SelectedContractsInfo = ({ selected, onRevoke, className }) => {
  const count = selected.length;
  const anyValidSelected = filter([...selected], e => e.status).length;

  return (
    <div className={cn(styles.selectedContractsInfo, className)}>
      <span className={styles.info}>
        <Trans vars={{ count }}>contracts_page.selected_contracts_info.info</Trans>
      </span>
      <Button
        kind="outlineDanger"
        onClick={() => onRevoke(selected)}
        disabled={!anyValidSelected}
        type="submit"
      >
        <Trans noMd>contracts_page.selected_contracts_info.button</Trans>
      </Button>
    </div>
  );
};

SelectedContractsInfo.defaultProps = {
  selected: [],
  onRevoke: null,
  className: '',
};

SelectedContractsInfo.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object),
  onRevoke: PropTypes.func,
  className: PropTypes.string,
};

export default SelectedContractsInfo;

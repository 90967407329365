import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';

import AcceptanceBox from 'src/components/shared/AcceptanceBox/AcceptanceBox';
import AutoSave from 'src/components/shared/AutoSave/AutoSave';
import Trans from 'src/components/shared/Trans/Trans';

import urls from 'src/constants/urls';

import styles from '../YourProfile.module.scss';

export default class PrivacySettings extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      terms: PropTypes.bool.isRequired,
      mailing: PropTypes.bool.isRequired,
      privacy_policy: PropTypes.bool.isRequired,
    }).isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const { initialValues: oldInitialValues } = this.props;
    const { initialValues: newInitialValues } = nextProps;

    return (
      newInitialValues.terms !== oldInitialValues.terms ||
      newInitialValues.mailing !== oldInitialValues.mailing ||
      newInitialValues.privacy_policy !== oldInitialValues.privacy_policy
    );
  }

  render() {
    const { onSubmit, initialValues } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, invalid }) => (
          <Fragment>
            <h3 className={styles.title}>
              <Trans>profile_page.your_profile.form.privacy_settings</Trans>
            </h3>
            <section className={styles.section}>
              <AutoSave
                debounce={0}
                save={invalid ? () => Promise.resolve() : handleSubmit}
                saveOn={['privacy_policy', 'terms']}
              />
              <AcceptanceBox required name="terms">
                <Trans vars={{ terms_and_conditions_href: urls.termsAndConditions() }}>
                  shared.tac_agreement
                </Trans>
              </AcceptanceBox>
              <AcceptanceBox required name="privacy_policy">
                <Trans vars={{ privacy_policy_href: urls.privacyPolicy() }}>
                  shared.pp_agreement
                </Trans>
              </AcceptanceBox>
            </section>
          </Fragment>
        )}
      />
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import diff from 'object-diff';

import { FormSpy } from 'react-final-form';

class AutoSave extends React.Component {
  static propTypes = {
    values: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    debounce: PropTypes.number,
    save: PropTypes.func.isRequired,
    saveOn: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    debounce: 1000,
    saveOn: null,
  };

  state = { values: this.props.values }; // eslint-disable-line react/destructuring-assignment

  componentWillReceiveProps() {
    const { debounce } = this.props;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(this.save, debounce);
  }

  shouldSave = () => {
    const { values: newValues, saveOn } = this.props;
    const { values: oldValues } = this.state;

    const whitelistValues = (obj, whitelist) =>
      Object.entries(obj).reduce((prev, [key, value]) => {
        if (whitelist.includes(key)) {
          return {
            ...prev,
            [key]: value,
          };
        }

        return prev;
      }, {});

    const areObjectsDifferent = (obj1, obj2) => Object.keys(diff(obj1, obj2)).length > 0;

    if (whitelistValues !== null) {
      return areObjectsDifferent(
        whitelistValues(newValues, saveOn),
        whitelistValues(oldValues, saveOn),
      );
    }

    return areObjectsDifferent(newValues, oldValues);
  };

  save = () =>
    new Promise(resolve => {
      (this.promise || Promise.resolve()).then(() => {
        const { values: newValues, save } = this.props;

        if (!this.shouldSave()) {
          return resolve();
        }

        const s = save(newValues);

        this.setState({ values: newValues });

        this.promise = s.then(() => {
          this.promise = null;

          resolve(newValues);
        });

        return this.promise;
      });
    });

  render() {
    return null;
  }
}

const AutoSaveWrapper = props => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
);

export default AutoSaveWrapper;

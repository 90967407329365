import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import last from 'lodash/last';
import { withNamespaces } from 'react-i18next';

import Trans from 'src/components/shared/Trans/Trans';

import { getProcessorRecords, createProcessor } from 'src/api/processor_records';

import Button from 'src/components/shared/Button/Button';
import Loading from 'src/components/shared/Loading/Loading';
import ProcessorFormModal from 'src/components/shared/ProcessorRecordFormModal/ProcessorFormModal';
import Title from 'src/components/shared/Title/Title';
import NoProcessors from './NoProcessors/NoProcessors';
import ProcessorsList from './ProcessorsList/ProcessorsList';

import styles from './ProcessorRecordsPage.module.scss';

class ProcessorRecordsPage extends Component {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    controller: PropTypes.object,
    t: PropTypes.func.isRequired,
  };

  state = {
    processors: [],
    isLoading: true,
    openModal: false,
    lastSubmittedProcessorName: '',
  };


  componentDidMount() {
    this.getPageOfProcessors();
  }

  onSubmit = processor =>
    new Promise(resolve => {
      const { t, controller } = this.props;

      createProcessor(controller, processor)
        .then(() => {
          this.setState({
            lastSubmittedProcessorName: processor.name,
          });

          this.getPageOfProcessors()
          this.closeModal();
          
          return resolve(undefined);
        })
        .catch((error) => {
          resolve({
            name: t('error_messages.processor_name_already_exists'),
          });
        });
    });

  getPageOfProcessors = () => {
    const { controller } = this.props;

    getProcessorRecords(controller).then((processors) =>
      this.setState({ processors, isLoading: false }),
    );
  }

  closeModal = () => {
    const { controller, history } = this.props;
    this.setState({ openModal: false })
  };

  handleOpenModal = () => {
    const { controller, history } = this.props;
    this.setState({ openModal: true })
  };

  render() {
    const {
      isLoading,
      openModal,
      processors,
      lastSubmittedProcessorName,
    } = this.state;

    const { t, controller } = this.props;
    
    return (
      <main className={styles.processorsPage}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Title title={t('shared.processor_records')}>
              <Button kind="primary" onClick={this.handleOpenModal} type="button">
                <Trans>shared.new_processor_record</Trans>
              </Button>
            </Title>

            <section className={styles.content}>
              {isEmpty(processors) ? (
                <NoProcessors />
              ) : (
                <Fragment>
                  <ProcessorsList {...{ controller, processors }} />
                </Fragment>
              )}
            </section>

            <ProcessorFormModal
              open={openModal}
              onSubmit={this.onSubmit}
              onClose={this.closeModal}
              lastSubmittedProcessorName={lastSubmittedProcessorName}
            />
          </Fragment>
        )}
      </main>
    );
  }
}

export default withRouter(withNamespaces()(ProcessorRecordsPage));

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { parse } from 'query-string';

import withToast from 'src/components/Toast/withToast/withToast';
import toastPropTypes from 'src/prop_types/toastPropTypes';

import { getDeclarationWithWidgetToken } from 'src/api/consent_declarations';
import ReactRouterPropTypes from 'react-router-prop-types';

import FooterLanguagePicker from 'src/components/shared/FooterLanguagePicker/FooterLanguagePicker';
import Loading from 'src/components/shared/Loading/Loading';
import DeclarationDetailsView from 'src/components/shared/DeclarationDetailsView/DeclarationDetailsView';

import styles from './DeclarationPage.module.scss';

class DeclarationPage extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    t: PropTypes.func.isRequired,
    openToast: toastPropTypes.openToast.isRequired,
  };

  state = {
    declaration: {},
    isLoading: true,
    error: false,
  };

  componentDidMount() {
    const { location, match } = this.props;
    const { id } = match.params;
    const { widget_token: widgetToken, widget_id: widgetId } = parse(location.search);

    getDeclarationWithWidgetToken({ id, widgetToken, widgetId })
      .then(declarationData => {
        const { ...declaration } = declarationData;

        this.setState({
          declaration,
          isLoading: false,
        });
      })
      .catch(({ status }) => {
        const { t, openToast } = this.props;

        this.setState({
          isLoading: false,
          error: true,
        });

        if (status === 404) {
          return openToast({
            type: 'danger',
            message: t('consent_declaration_page.errors.declaration_not_found'),
          });
        }

        return openToast({
          message: t('consent_declaration_page.errors.general'),
          type: 'danger',
        });
      });
  }

  render() {
    const { isLoading, declaration, error } = this.state;

    const content = () => {
      if (error) {
        return null;
      }

      if (isLoading) {
        return <Loading />;
      }

      return <DeclarationDetailsView folded={false} declaration={declaration} />;
    };

    return (
      <div className={styles.page}>
        <div className={styles.pageMargin}>{content()}</div>

        <FooterLanguagePicker />
      </div>
    );
  }
}

export default withRouter(withNamespaces()(withToast(DeclarationPage)));

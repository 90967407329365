import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import isEmpty from 'lodash/isEmpty';

import { withNamespaces } from 'react-i18next';

import Button from 'src/components/shared/Button/Button';
import Columns from 'src/components/shared/Columns/Columns';
import InputField from 'src/components/shared/InputField/InputField';
import CountriesField from 'src/components/shared/CountriesField/CountriesField';
import { thirdPartyListPropTypes } from 'src/prop_types/declaration_prop_types';
import { isEmail, isPhone } from 'src/utils/validators';

import ThirdPartiesList from './ThirdPartiesList/ThirdPartiesList';

import styles from './ThirdParty.module.scss';

class ThirdParty extends PureComponent {
  state = {
    toEdit: {},
    // eslint-disable-next-line react/destructuring-assignment
    thirdParties: this.props.thirdParties,
  };

  isPartyAlreadyBeingUsed = companyName => {
    const { t } = this.props;
    const { thirdParties, toEdit } = this.state;

    if (
      thirdParties
        .filter(thirdParty => toEdit.companyName !== thirdParty.companyName)
        .find(thirdParty => companyName === thirdParty.companyName) !== undefined
    ) {
      return t('third_parties.errors.company_name_already_used');
    }

    return undefined;
  };

  onEdit = thirdParty => {
    this.setState({
      toEdit: thirdParty,
    });
  };

  onDelete = thirdParty =>
    this.setState(({ thirdParties }) => {
      const { onChange } = this.props;
      const thirdPartiesWithoutEdited = thirdParties.filter(
        ({ companyName }) => companyName !== thirdParty.companyName,
      );

      const newPartiesList = [...thirdPartiesWithoutEdited];

      onChange(newPartiesList);

      return {
        thirdParties: newPartiesList,
      };
    });

  onSubmit = (thirdParty, { reset }) =>
    this.setState(({ thirdParties, toEdit }) => {
      const { onChange } = this.props;
      const thirdPartiesWithoutEdited = thirdParties.filter(
        ({ companyName }) => companyName !== toEdit.companyName,
      );

      const newPartiesList = [...thirdPartiesWithoutEdited, thirdParty];

      onChange(newPartiesList);

      reset();

      return {
        toEdit: {},
        thirdParties: newPartiesList,
      };
    });

  render() {
    const { t } = this.props;
    const { toEdit, thirdParties } = this.state;
    const { onSubmit, onEdit, onDelete, isPartyAlreadyBeingUsed } = this;

    const sortedThirdParties = thirdParties
      .filter(({ companyName }) => companyName !== toEdit.companyName)
      .sort((a, b) => a.companyName.localeCompare(b.companyName));

    return (
      <Columns className={styles.thirdParty}>
        {{
          left: (
            <Fragment>
              <h3 className={styles.header}>
                {isEmpty(toEdit) ? t('third_parties.create_title') : t('third_parties.edit_title')}
              </h3>

              <Form {...{ onSubmit }} initialValues={toEdit}>
                {({ handleSubmit }) => (
                  <Fragment>
                    <InputField
                      className={styles.row}
                      label={t('shared.company_name')}
                      name="companyName"
                      validators={[isPartyAlreadyBeingUsed]}
                      required
                    />

                    <InputField
                      className={styles.row}
                      label={t('shared.company_email')}
                      name="email"
                      validators={[isEmail]}
                      required
                    />

                    <InputField
                      className={styles.row}
                      label={t('shared.phone_number')}
                      name="phone"
                      validators={[isPhone]}
                    />

                    <div className={styles.buttonRow}>
                      <CountriesField name="country" required />
                      <Button
                        className={styles.saveButton}
                        kind="primary"
                        type="button"
                        onClick={handleSubmit}
                      >
                        {isEmpty(toEdit)
                          ? t('third_parties.create_new_button')
                          : t('third_parties.save_changes_button')}
                      </Button>
                    </div>
                  </Fragment>
                )}
              </Form>
            </Fragment>
          ),
          right: (
            <Fragment>
              <h3 className={styles.header}>{t('third_parties.targeted_third_parties')}:</h3>

              <ThirdPartiesList
                onEdit={onEdit}
                onDelete={onDelete}
                thirdParties={sortedThirdParties}
                isBeingEdited={!isEmpty(toEdit)}
              />
            </Fragment>
          ),
        }}
      </Columns>
    );
  }
}

ThirdParty.defaultProps = {
  thirdParties: [], // eslint-disable-line
};

ThirdParty.propTypes = {
  ...thirdPartyListPropTypes,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(ThirdParty);

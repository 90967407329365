const LogoutEventDispatcher = () => {
  const state = {
    fns: [],
    on(fn) {
      this.fns.push(fn);
    },
    dispatch() {
      this.fns.forEach(fn => fn());
    },
  };

  return state;
};

const logoutEventDispatcher = LogoutEventDispatcher();

export default logoutEventDispatcher;

import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import withSession from 'src/components/AuthContext/withSession/withSession';
import { authPropTypes } from 'src/prop_types/auth_prop_types';

class PublicRoute extends React.PureComponent {
  componentDidMount() {
    const {
      auth: { handleLogout, isAuthenticated },
    } = this.props;

    if (isAuthenticated) {
      handleLogout();
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <Route {...rest} render={props => <Component {...props} />} />;
  }
}

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  auth: authPropTypes.auth.isRequired,
};

export default withSession(PublicRoute);

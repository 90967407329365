import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import cn from 'classnames';

import Trans from 'src/components/shared/Trans/Trans';
import TRAQLogo from 'src/components/image/TRAQLogo';
import NavButton from 'src/components/shared/NavButton/NavButton';
import withSession from 'src/components/AuthContext/withSession/withSession';
import { authPropTypes } from 'src/prop_types/auth_prop_types';
import { topbarUrlsPropTypes } from 'src/prop_types/topbarUrlsPropTypes';

import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import MobileMenu from './MobileMenu/MobileMenu';
import styles from './Topbar.module.scss';
import { isLanding } from '../../../utils/router';

import urls from '../../../constants/urls';

class TopBar extends Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState(({ mobileMenuOpen }) => ({
      mobileMenuOpen: !mobileMenuOpen,
    }));
  };

  render() {
    const {
      auth: { isAuthenticated },
      topbarUrls,
      superadmin,
      location,
    } = this.props;

    const { mobileMenuOpen } = this.state;
    const { toggleMobileMenu } = this;

    const landing = isLanding(location);

    return (
      <div className={styles.topbar}>
        <div className={cn(styles.content, { [styles.contentLanding]: landing })}>
          <NavLink to={urls.dashboard()}>
            <TRAQLogo className={styles.logo} />
          </NavLink>
          {!landing && (
            <Fragment>
              {isAuthenticated && (
                <Fragment>
                  <div className={styles.hamburgerContainer}>
                    <button
                      type="button"
                      onClick={toggleMobileMenu}
                      className={styles.hamburger}
                      aria-expanded={mobileMenuOpen}
                    >
                      Menu
                    </button>
                  </div>
                  <nav className={styles.navbar}>
                    {topbarUrls.map(({ to, text }) => (
                      <NavButton key={to} to={to}>
                        <Trans>{text}</Trans>
                      </NavButton>
                    ))}
                  </nav>
                  <ProfileDropdown superadmin={superadmin} />
                </Fragment>
              )}
            </Fragment>
          )}
        </div>

        {isAuthenticated && (
          <MobileMenu
            open={mobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
            topbarUrls={topbarUrls}
          />
        )}
      </div>
    );
  }
}

TopBar.propTypes = {
  auth: authPropTypes.auth,
  topbarUrls: topbarUrlsPropTypes,
  superadmin: PropTypes.bool,
  location: ReactRouterPropTypes.location.isRequired,
};

TopBar.defaultProps = {
  superadmin: false,
  auth: { isAuthenticated: false, handleLogin: () => {}, handleLogout: () => {} },
  topbarUrls: [],
};

export default withSession(withRouter(TopBar));

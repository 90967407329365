import apiRequest from './apiRequest';
import EP from './endpoints';

const parseDataCategories = ({
  id,
  attributes: { name, consent_declarations: consentDeclarations },
}) => ({
  id,
  name,
  dependants: consentDeclarations,
});

const formatDataCategories = ({ name }) => ({
  data: {
    type: 'personal_data_category',
    attributes: { name },
  },
});

export const getPersonalDataCategories = () =>
  apiRequest(EP.dataCategories).then(({ data }) => data.map(parseDataCategories));

export const createCategory = category =>
  apiRequest(EP.dataCategories, {
    body: formatDataCategories(category),
  });

export const updateCategory = (id, category) =>
  apiRequest(`${EP.dataCategories}/${id}`, {
    method: 'PATCH',
    body: formatDataCategories(category),
  });

export const deleteCategory = id =>
  apiRequest(`${EP.dataCategories}/${id}`, {
    method: 'DELETE',
  });

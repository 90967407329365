import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import filter from 'lodash/filter';

import Trans from 'src/components/shared/Trans/Trans';

import Button from 'src/components/shared/Button/Button';

import styles from './SelectedConsentsInfo.module.scss';

const SelectedConsentsInfo = ({ selected, onRevoke, className }) => {
  const count = selected.length;
  const anyValidSelected = filter([...selected], e => e.status).length;

  return (
    <div className={cn(styles.selectedConsentsInfo, className)}>
      <span className={styles.info}>
        <Trans vars={{ count }}>consents_page.selected_consents_info.info</Trans>
      </span>
      <Button
        kind="outlineDanger"
        onClick={() => onRevoke(selected)}
        disabled={!anyValidSelected}
        type="submit"
      >
        <Trans noMd>consents_page.selected_consents_info.button</Trans>
      </Button>
    </div>
  );
};

SelectedConsentsInfo.defaultProps = {
  selected: [],
  onRevoke: null,
  className: '',
};

SelectedConsentsInfo.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object),
  onRevoke: PropTypes.func,
  className: PropTypes.string,
};

export default SelectedConsentsInfo;

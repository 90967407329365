import React, { Fragment } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import HeroBg from 'src/components/image/HeroBg';
import Group from 'src/components/image/Group';
import HowFirst from 'src/components/image/HowFirst';
import HowSecond from 'src/components/image/HowSecond';
import HowThird from 'src/components/image/HowThird';
import HowForth from 'src/components/image/HowForth';
import LandingSection from 'src/components/image/LandingSection';
import LandingSectionSecond from 'src/components/image/LandingSectionSecond';
import LandingSectionThird from 'src/components/image/LandingSectionThird';
import SolutionPublic from 'src/components/image/SolutionPublic';
import SolutionPrivate from 'src/components/image/SolutionPrivate';
import TryTraq from 'src/components/shared/TryTraq';
import TRAQLogo from 'src/components/image/TRAQLogo';
import urls from 'src/constants/urls';

import styles from './Content.module.scss';
import Text from '../Text/Text';
import Title from '../Title/Title';
import HowWorksStep from '../HowWorksStep/HowWorksStep';
import Separator from '../HowWorksStep/Separator';
import Usage from '../Usage/Usage';
import Benefits from './Benefits';

const howDoesItWorkSteps = [
  {
    stepNumber: '1',
    description: 'DPO creates and publishes consent <br/> declaration in TRAQ',
    component: <HowFirst />,
    separator: true,
  },
  {
    stepNumber: '2',
    description:
      'Consent declaration is presented on <br/> external website. Data subject gives <br/> the consent.',
    component: <HowSecond />,
    separator: true,
  },
  {
    stepNumber: '3',
    description: 'Consent is stored in TRAQ and is an <br /> archived as a document.',
    component: <HowThird />,
    separator: true,
  },
  {
    stepNumber: '4',
    description: 'If consent expires or changes DPO <br /> can see that and take actions.',
    component: <HowForth />,
    separator: false,
  },
];

const Content = () => (
  <div className={styles.wrapper}>
    <div className={styles.rowReverseWrapper}>
      <div className={styles.hero}>
        <HeroBg />
        <div className={styles.heroGroup}>
          <Group />
        </div>
      </div>
      <div className={cn(styles.content, styles.firstSubscription)}>
        <Title tag="1" className={styles.mainTitle}>
          Consent management can get complex, Traq keeps it simple.
        </Title>
        <Text className={styles.textMain}>
          Manage consents efficiently with a flexible, safe, fully GDPR-compliant solution for
          modern enterprises and public insitutions. Everything in one place.
        </Text>
        <TryTraq />
      </div>
    </div>
    <div className={styles.promos}>
      <div className={styles.rowWrapper}>
        <div className={styles.landingSection}>
          <LandingSection />
        </div>
        <div className={styles.content}>
          <Title tag="2" className={styles.secondaryTitle}>
            Ensure GDPR compliance and avoid fines
          </Title>
          <Text className={cn(styles.textSecondary, styles.avoidFines)}>
            Any company worldwide that collects or processes personal data of an individual in the
            EU, are subject to GDPR. Fines for data breaches, or non-compliance are up to €20
            million or 4% of annual worldwide revenue. You can minimize that risk by choosing a
            fully compliant tool.
          </Text>
        </div>
      </div>
      <div className={styles.rowReverseWrapper}>
        <div className={styles.landingSectionSecond}>
          <LandingSectionSecond />
        </div>

        <div className={styles.content}>
          <Title tag="2" className={styles.secondaryTitle}>
            Save time with ready to use solution
          </Title>
          <Text className={styles.textSecondary}>
            Using Traq will allow you to manage all consents from one place without a need to log n
            to several systems. Cloud based tool also limits the time spent on research and studying
            new regulations- everything is reflected in the app.
          </Text>
        </div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.landingSectionThird}>
          <LandingSectionThird />
        </div>
        <div className={cn(styles.content, styles.landingSectionThirdText)}>
          <Title tag="2" className={styles.secondaryTitle}>
            Give your users the right to manage their personal data
          </Title>
          <Text className={styles.textSecondary}>
            Give your users an ability to manage their consents and decide on data processed from
            anywhere in the world. Gain trust by being transparent.
          </Text>
        </div>
      </div>
    </div>

    <div className={cn(styles.content, styles.howDoesItWork)}>
      <section className={styles.howWorks}>
        <Title tag="2" className={cn(styles.secondaryTitle, styles.textCenter)}>
          How does it work?
        </Title>
        <Text className={cn(styles.textSecondary, styles.howDesc)}>
          Traq’s data flow explained in four simple steps.
        </Text>
        <div className={cn(styles.rowWrapper, styles.howWorksWrapper)}>
          {howDoesItWorkSteps.map(({ stepNumber, description, separator, component }) => (
            <Fragment key={stepNumber}>
              <HowWorksStep stepNumber={stepNumber} description={description}>
                {component}
              </HowWorksStep>
              {!!separator && <Separator />}
            </Fragment>
          ))}
        </div>
      </section>

      <section className={styles.usage}>
        <Title tag="2" className={cn(styles.secondaryTitle, styles.textCenter)}>
          A versatile solution for your workplace
        </Title>
        <Text className={cn(styles.textSecondary, styles.howDesc)}>
          Regardless of the background - Traq can <br /> help you and your co-workers focus on
          what’s <br />
          most important.
        </Text>
        <div className={cn(styles.rowWrapper, styles.centerWrapper)}>
          <Usage
            title="For public institutions"
            description="Limit your administrative work and backlog of unnecessary data more efficiently. Mitigate risks taking an advantage of Traq’s superb security."
          >
            <SolutionPublic />
          </Usage>

          <Usage
            title="For private companies"
            description="Document consents for data collected for eg. cookie processing, or newsletter subscriptions. Build trust and retain customers by showing you value their privacy."
          >
            <SolutionPrivate />
          </Usage>
        </div>
      </section>

      <section className={cn(styles.benefitsWrapper, styles.spaceBetweenWrapper)}>
        <Title tag="2" className={cn(styles.secondaryTitle, styles.textCenter)}>
          One tool, many benefits
        </Title>
        <Text className={cn(styles.textSecondary, styles.howDesc)}>
          More regulations doesn’t have to mean more work.
        </Text>
        <Benefits />
      </section>
    </div>
    <footer className={styles.footer}>
      <div className={cn(styles.content, styles.footerWrapper)}>
        <div className={styles.footerTop}>
          <Title tag="2" className={cn(styles.secondaryTitle, styles.textCenter)}>
            Get your consents on track
          </Title>
          <Text className={cn(styles.textSecondary, styles.tryDesc)}>
            Try Traq now, absolutely for free.
          </Text>
          <TryTraq />
        </div>
        <div className={styles.footerNav}>
          <div className={styles.footerLogo}>
            <TRAQLogo className={styles.logo} />
          </div>
          <Text className={styles.copyright}>Copyright © 2019 Traq</Text>
          <Link to={urls.privacyPolicy()} className={styles.policy}>
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  </div>
);

export default Content;

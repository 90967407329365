import React from 'react';
import PropTypes from 'prop-types';

import Trans from 'src/components/shared/Trans/Trans';

import Button from 'src/components/shared/Button/Button';
import Modal from 'src/components/shared/Modal/Modal';
import ModalFooter from 'src/components/shared/ModalFooter/ModalFooter';

import styles from './WarningModal.module.scss';

const WarningModal = ({ open, onClose, title, acceptLabel, onAccept, children }) => (
  <Modal {...{ open, onClose, title }}>
    <div className={styles.warning}>{children}</div>

    <ModalFooter>
      <Button kind="outline" onClick={onClose} type="button">
        <Trans noMd>shared.cancel</Trans>
      </Button>
      <Button kind="danger" onClick={onAccept} type="submit">
        {acceptLabel}
      </Button>
    </ModalFooter>
  </Modal>
);

WarningModal.defaultProps = {
  acceptLabel: 'Proceed',
  title: 'Warning!',
};

WarningModal.propTypes = {
  acceptLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default WarningModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';

import ErrorMessage from 'src/components/shared/ErrorMessage/ErrorMessage';
import Label from 'src/components/shared/Label/Label';
import Trans from 'src/components/shared/Trans/Trans';

import { reduceValidators, isRequired } from 'src/utils/validators';
import { shouldDisplayError } from 'src/utils/shouldDisplayError';
import { shouldDisplayCheckmark } from 'src/utils/shouldDisplayCheckmark';

import styles from './InputField.module.scss';

const InputField = ({ label, name, className, required, type, placeholder, hideOptional, validators, ...props }) => {
  const prefilledValidators = required ? [isRequired, ...validators] : validators;
  const validate = reduceValidators(prefilledValidators);

  return (
    <Field {...{ name, validate }}>
      {({ input, meta: { valid, error, touched, dirty, submitError, dirtySinceLastSubmit } }) => (
        <div className={cn(className, styles.field)}>
          <Label
            htmlFor={name}
            hideOptional={hideOptional}
            required={required}
            shouldDisplayCheckmark={shouldDisplayCheckmark({
              required,
              valid,
              value: input.value,
              submitError,
              dirtySinceLastSubmit,
            })}
          >
            {label}
          </Label>

          <input
            {...props}
            {...input}
            {...{ type, placeholder }}
            className={cn(styles.inputControl, {
              [styles.error]: shouldDisplayError({
                valid,
                touched,
                dirty,
                dirtySinceLastSubmit,
                submitError,
              }),
            })}
            id={name}
          />

          {shouldDisplayError({ valid, touched, dirty, dirtySinceLastSubmit, submitError }) && (
            <ErrorMessage>
              <Trans noMd>{error || submitError}</Trans>
            </ErrorMessage>
          )}
        </div>
      )}
    </Field>
  );
};

InputField.defaultProps = {
  required: false,
  hideOptional: false,
  label: 'Label',
  placeholder: '',
  className: '',
  type: 'text',
  validators: [],
};

InputField.propTypes = {
  required: PropTypes.bool,
  hideOptional: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
};

export default InputField;

import { toCamelCase, toSnakeCase } from 'src/utils/caseMappers';
import apiRequest from './apiRequest';
import EP from './endpoints';

const formatCredentialsFor = type => credentials => ({
  data: {
    type,
    attributes: {
      ...toSnakeCase(credentials),
    },
  },
});

const formatSessionCredentials = formatCredentialsFor('session');
const formatPasswordCredentials = formatCredentialsFor('password');

export const createSession = credentials =>
  apiRequest(EP.session, {
    body: formatSessionCredentials(credentials),
  }).then(({ data }) => toCamelCase(data));

export const destroySession = () =>
  apiRequest(EP.session, {
    method: 'DELETE',
    isLogout: true,
  });

export const setNewPassword = credentials =>
  apiRequest(EP.password, {
    method: 'POST',
    withAuth: false,
    body: formatPasswordCredentials(credentials),
  });

export const resetPassword = email =>
  apiRequest(EP.password, {
    query: { email },
    withAuth: false,
    method: 'DELETE',
  });

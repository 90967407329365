import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';
import urls from 'src/constants/urls';

import PersonalDataCategories from './PersonalDataCategories/PersonalDataCategories';
import Organization from './Organization/Organization';
import YourProfile from './YourProfile/YourProfile';

const ProfilePageRouter = ({ toggleHint }) => (
  <Fragment>
    <Route exact path={urls.settings()} render={() => <YourProfile toggleHint={toggleHint} />} />

    <Route
      exact
      path={urls.organizations()}
      render={() => <Organization toggleHint={toggleHint} />}
    />

    <Route
      exact
      path={urls.personalDataCategories()}
      render={() => <PersonalDataCategories toggleHint={toggleHint} />}
    />
  </Fragment>
);

ProfilePageRouter.propTypes = {
  toggleHint: PropTypes.func.isRequired,
};

export default ProfilePageRouter;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import DoubleArrow from 'src/components/image/DoubleArrow';
import DropdownArrow from 'src/components/image/DropdownArrow';

import styles from './SortArrows.module.scss';

const SortArrows = ({ sortBy, sortMode, sortHandle }) =>
  sortBy === sortHandle ? (
    <DropdownArrow
      className={cn(styles.icon, styles.single, {
        [styles.flipped]: sortMode === 'asc',
      })}
    />
  ) : (
    <DoubleArrow className={styles.icon} />
  );

SortArrows.propTypes = {
  sortBy: PropTypes.string.isRequired,
  sortMode: PropTypes.string.isRequired,
  sortHandle: PropTypes.string.isRequired,
};

export default SortArrows;

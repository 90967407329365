import React from 'react';
import PropTypes from 'prop-types';
import Button from 'src/components/shared/Button/Button';
import ModalFooter from 'src/components/shared/ModalFooter/ModalFooter';
import { withNamespaces } from 'react-i18next';

import styles from '../DeclarationForm.module.scss';

const Footer = ({ goToPreviousPage, currentPage, totalPages, t }) => (
  <ModalFooter>
    {currentPage > 1 && (
      <Button kind="outline" type="button" onClick={goToPreviousPage} disabled={currentPage === 0}>
        {t('shared.previous_step')}
      </Button>
    )}
    <span className={styles.stepCounter}>
      {currentPage} / {totalPages}
    </span>
    {currentPage < totalPages && (
      <Button kind="primary" type="submit">
        {t('shared.next_step')}
      </Button>
    )}
    {currentPage === totalPages && (
      <Button kind="primary" type="submit">
        {t('shared.publish')}
      </Button>
    )}
  </ModalFooter>
);

Footer.propTypes = {
  goToPreviousPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  values: PropTypes.shape({
    dataTransfer: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    thirdParties: PropTypes.array,
  }).isRequired,
};

export default withNamespaces()(Footer);

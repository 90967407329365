import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { personalDataCategoriesPropTypes } from 'src/prop_types/declaration_prop_types';

import Tag from './Tag/Tag';

import styles from './TagsList.module.scss';

const TagsList = ({ tags, onChange, onTagRemove }) => {
  if (isEmpty(tags)) return null;

  const removeTag = tagToRemove => {
    onChange(tags.filter(listTag => listTag !== tagToRemove));
    onTagRemove(tagToRemove);
  };

  return (
    <ul className={styles.selectedList}>
      {tags.map(tag => (
        <li key={tag.id}>
          <Tag onClick={() => removeTag(tag)}>{tag.name}</Tag>
        </li>
      ))}
    </ul>
  );
};

TagsList.defaultProps = {
  tags: [],
  onChange: null,
};

TagsList.propTypes = {
  tags: personalDataCategoriesPropTypes,
  onChange: PropTypes.func,
  onTagRemove: PropTypes.func.isRequired,
};

export default TagsList;

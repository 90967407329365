/* eslint-disable import/no-extraneous-dependencies */
import '@babel/polyfill';
import 'core-js/modules/es7.promise.finally';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'src/components/Root/Root';

import 'styles/app.scss';

ReactDOM.render(<Root />, document.getElementById('app'));

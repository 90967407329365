import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Button from 'src/components/shared/Button/Button';
import Tooltip from 'src/components/shared/Tooltip/Tooltip';

import { webhookPropTypes } from 'src/prop_types/processor_prop_types';

import styles from './WebhooksList.module.scss';

const WebhooksList = ({ webhooks, t, currentlyEdited, startEditing, deleteWebhook }) =>
  webhooks.length > 0 ? (
    <div>
      {webhooks
        .sort(({ targetUrl: nameA }, { targetUrl: nameB }) =>
          nameA.replace(/^https?:/, '').localeCompare(nameB.replace(/^https?:/, '')),
        )
        .filter(({ id }) => currentlyEdited !== id)
        .map(webhook => (
          <Tooltip key={webhook.id} text={webhook.targetUrl}>
            <div className={styles.listItem}>
              <span>{webhook.targetUrl}</span>
              <div className={styles.buttons}>
                {!currentlyEdited && (
                  <Button kind="ghost" type="button" onClick={startEditing(webhook.id)}>
                    {t('shared.edit')}
                  </Button>
                )}
                <Button kind="ghostDanger" onClick={deleteWebhook(webhook)} type="button">
                  {t('shared.delete')}
                </Button>
              </div>
            </div>
          </Tooltip>
        ))}
    </div>
  ) : (
    <div className={styles.emptyState}>
      {t('processor_details_page.webhooks_modal.no_webhooks')}
    </div>
  );
WebhooksList.propTypes = {
  t: PropTypes.func.isRequired,
  startEditing: PropTypes.func.isRequired,
  deleteWebhook: PropTypes.func.isRequired,
  currentlyEdited: PropTypes.string.isRequired,
  webhooks: PropTypes.arrayOf(webhookPropTypes).isRequired,
};

export default withNamespaces()(WebhooksList);

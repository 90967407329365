import PropTypes from 'prop-types';
import { webhookEvents } from 'src/constants/webhookEvents';

export const webhookPropTypes = PropTypes.shape({
  id: PropTypes.string,
  target_url: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.oneOf(webhookEvents)),
});

const processor = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  token: PropTypes.string,
  contactName: PropTypes.string,
  companyName: PropTypes.string,
  contactEmail: PropTypes.string,
  contactPhone: PropTypes.string,
  additionalDetails: PropTypes.string,
  lastApiCallAt: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  webhooks: PropTypes.arrayOf(webhookPropTypes),
});

export const processorPropTypes = { processor };

export const processorListPropTypes = {
  processors: PropTypes.arrayOf(processor),
};

export const processorFormPropTypes = {
  processor: PropTypes.shape({
    name: PropTypes.string,
    contactName: PropTypes.string,
    companyName: PropTypes.string,
    contactEmail: PropTypes.string,
    contactPhone: PropTypes.string,
    additionalDetails: PropTypes.string,
  }),
};

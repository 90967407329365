import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withNamespaces } from 'react-i18next';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';

import urls from 'src/constants/urls';

import Trans from 'src/components/shared/Trans/Trans';
import Button from 'src/components/shared/Button/Button';
import PasswordField from 'src/components/shared/PasswordField/PasswordField';
import AcceptanceBox from 'src/components/shared/AcceptanceBox/AcceptanceBox';

import styles from './ResetPasswordPasswordForm.module.scss';

const ResetPasswordPasswordForm = ({ onSubmit, t, isInvite, onTaCClick, onPPClick }) => {
  const CustomTaCLink = ({ href, children: linkChildren }) => (
    <Link to={href} onClick={onTaCClick}>
      {linkChildren}
    </Link>
  );

  CustomTaCLink.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  const CustomPPLink = ({ href, children: linkChildren }) => (
    <Link to={href} onClick={onPPClick}>
      {linkChildren}
    </Link>
  );

  CustomPPLink.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  return (
    <div>
      <h3 className={styles.title}>{t('reset_password_page.password_form.set_password')}</h3>

      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <PasswordField label={t('shared.new_password')} name="password" required />

            {isInvite && (
              <Fragment>
                <AcceptanceBox required name="terms">
                  <div className={styles.acceptanceBoxDesktop}>
                    <Trans
                      vars={{ terms_and_conditions_href: urls.termsAndConditions() }}
                      renderers={{
                        link: CustomTaCLink,
                      }}
                    >
                      shared.tac_agreement
                    </Trans>
                  </div>
                  <div className={styles.acceptanceBoxMobile}>
                    <Trans
                      vars={{ terms_and_conditions_href: urls.termsAndConditions() }}
                      renderers={{
                        link: CustomTaCLink,
                      }}
                    >
                      shared.tac_agreement
                    </Trans>
                  </div>
                </AcceptanceBox>
                <AcceptanceBox required name="privacy_policy">
                  <div className={styles.acceptanceBoxDesktop}>
                    <Trans
                      vars={{ privacy_policy_href: urls.privacyPolicy() }}
                      renderers={{
                        link: CustomPPLink,
                      }}
                    >
                      shared.pp_agreement
                    </Trans>
                  </div>
                  <div className={styles.acceptanceBoxMobile}>
                    <Trans vars={{ privacy_policy_href: urls.privacyPolicy() }}>
                      shared.pp_agreement
                    </Trans>
                  </div>
                </AcceptanceBox>
              </Fragment>
            )}

            <Button className={styles.submitButton} kind="primary" type="submit">
              <Trans>reset_password_page.password_form.set_password</Trans>
            </Button>
          </form>
        )}
      </Form>
    </div>
  );
};

ResetPasswordPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  t: PropTypes.func.isRequired,
  onTaCClick: PropTypes.func.isRequired,
  onPPClick: PropTypes.func.isRequired,
  isInvite: PropTypes.bool,
};

ResetPasswordPasswordForm.defaultProps = {
  onSubmit: () => {},
  isInvite: false,
};

export default withNamespaces()(ResetPasswordPasswordForm);

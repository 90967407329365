import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Trans from 'src/components/shared/Trans/Trans';

import CopyButton from 'src/components/shared/CopyButton/CopyButton';
import ValidityStatus from 'src/components/shared/ValidityStatus/ValidityStatus';
import urls from 'src/constants/urls';
import { contractPropTypes } from 'src/prop_types/contract_prop_types';

import styles from './ContractRow.module.scss';

const ContractRow = ({
  contract: { id, identifier, processor, contractDeclarationName, date, validUntil, status, test },
  selected,
  onSelect,
}) => (
  <tr className={styles.contractRow}>
    <td>
      <input
        type="checkbox"
        checked={selected}
        onChange={e => onSelect(e.target.checked, id)}
        name={`contract_${id}`}
      />
    </td>
    <td data-identifier={identifier} className={styles.longText}>
      <CopyButton>{identifier}</CopyButton>
    </td>
    <td>{processor}</td>
    <td>{contractDeclarationName}</td>
    <td>
      <Trans date>{date}</Trans>
    </td>
    <td>
      <Trans date>{validUntil}</Trans>
    </td>
    <td>
      <ValidityStatus valid={status} />
    </td>

    <td>{ test ? "Test" : "Standard" }</td>
    <td>
      <Link
        className={styles.viewButton}
        to={{
          pathname: urls.contracts(id),
          state: {
            contract: {
              id,
              processor,
              date,
              validUntil,
              status,
            },
          },
        }}
      >
        <Trans noMd>shared.view</Trans>
      </Link>
    </td>
  </tr>
);

ContractRow.defaultProps = {
  selected: false,
  onSelect: null,
};

ContractRow.propTypes = {
  contract: contractPropTypes.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default ContractRow;

import React from 'react';
import source from 'images/double_arrow.svg';

import Icon from './Icon';

const DoubleArrow = ({ ...props }) => (
  <Icon alt="vertical double arrow" src={source} width="8" height="16" {...props} />
);

export default DoubleArrow;

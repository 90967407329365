import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';

import Trans from 'src/components/shared/Trans/Trans';
import { shouldDisplayError } from 'src/utils/shouldDisplayError';
import { shouldDisplayCheckmark } from 'src/utils/shouldDisplayCheckmark';

import ErrorMessage from 'src/components/shared/ErrorMessage/ErrorMessage';
import Label from 'src/components/shared/Label/Label';
import { isRequired } from 'src/utils/validators';

import styles from './TextareaField.module.scss';

const TextareaField = ({ label, name, className, required, ...props }) => (
  <div className={cn(className, styles.field)}>
    <Field {...{ name }} validate={required ? isRequired : null}>
      {({ input, meta: { valid, error, touched, dirty, submitError, dirtySinceLastSubmit } }) => (
        <Fragment>
          <Label
            htmlFor={name}
            required={required}
            shouldDisplayCheckmark={shouldDisplayCheckmark({
              required,
              valid,
              value: input.value,
              submitError,
              dirtySinceLastSubmit,
            })}
          >
            {label}
          </Label>

          <textarea
            {...props}
            {...input}
            className={cn(styles.textareaControl, {
              [styles.error]: shouldDisplayError({
                valid,
                touched,
                dirty,
                dirtySinceLastSubmit,
                submitError,
              }),
            })}
            id={name}
          />

          {shouldDisplayError({ valid, touched, dirty, dirtySinceLastSubmit, submitError }) && (
            <ErrorMessage>
              <Trans noMd>{error || submitError}</Trans>
            </ErrorMessage>
          )}
        </Fragment>
      )}
    </Field>
  </div>
);

TextareaField.defaultProps = {
  required: false,
  label: 'Label',
  className: '',
};

TextareaField.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TextareaField;

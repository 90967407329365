import React from 'react';

import styles from './index.module.scss';

const DeclarationDetails = () => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <div className={styles.tacContent}>
        <h1 className={styles.h2}>Declaration details</h1>

        <h3>Consent purpose</h3>
        <p>
          We will use the information about you to keep you posted on news and updates regarding the
          Traq service. Your email will not be sold to any third parties
        </p>

        <h3>Personal data</h3>
        <p>Email address</p>

        <h3>Controller</h3>
        <p>Skalar AS, Kongens gate 11, N-0153 Oslo, Norway (post@skalar.no)</p>

        <h3>Subcontrollers</h3>
        <p>
          <ul className={styles.tacList}>
            <li>Netguru, Wojskowa 6, 60-792 Poznań, Poland</li>
            <li>
              Mailchimp, The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000
              Atlanta, GA 30308, USA (www.mailchimp.com)
            </li>
          </ul>
        </p>
      </div>
    </div>
  </div>
);

export default DeclarationDetails;

import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import styles from './Card.module.scss';

const Card = ({ children, component: Component, className, ...props }) => (
  <Component className={cn(styles.card, className)} {...props}>
    {children}
  </Component>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
};

Card.defaultProps = {
  component: 'div',
  className: '',
};

export default Card;

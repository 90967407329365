import React from 'react';
import PropTypes from 'prop-types';

import AlertIcon from 'src/components/image/AlertIcon';

import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({ children }) => (
  <span className={styles.errorMessage}>
    <AlertIcon className={styles.iconMargin} />
    {children}
  </span>
);

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorMessage;

import React, { Component, Fragment } from 'react';
import { withNamespaces } from 'react-i18next';

import Trans from 'src/components/shared/Trans/Trans';

import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from 'src/components/shared/Tooltip/Tooltip';
import Button from 'src/components/shared/Button/Button';
import LockIcon from 'src/components/image/LockIcon';

import PdcInlineForm from './PdcInlineForm/PdcInlineForm';

import styles from './PersonalDataCategory.module.scss';

class PersonalDataCategory extends Component {
  /* eslint-disable react/destructuring-assignment */
  state = {
    isEdit: this.props.isCreating,
    justEdited: this.props.wasJustCreated,
    focused: false,
  };
  /* eslint-enable react/destructuring-assignment */

  componentDidMount() {
    this.finishJustEdited();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onCancel = (...attrs) => {
    const { onCancel } = this.props;

    onCancel(...attrs);
    this.toggleMode();
  };

  onSave = category =>
    new Promise(resolve => {
      const { onSubmit } = this.props;

      return onSubmit(category).then(err => {
        if (err) {
          return resolve(err);
        }

        this.toggleMode();

        this.setState({ justEdited: true });
        return this.finishJustEdited();
      });
    });

  onDelete = (...attrs) => {
    const { onDelete } = this.props;

    onDelete(...attrs);
  };

  onEdit = (...attrs) => {
    const { onEditStart } = this.props;

    if (onEditStart(...attrs)) {
      this.toggleMode();
    }
  };

  finishJustEdited = () => {
    this.timeout = setTimeout(() => {
      this.setState({ justEdited: false });
    }, 2000);
  };

  toggleMode = () => this.setState(prevState => ({ isEdit: !prevState.isEdit }));

  enableFocus = () => this.setState({ focused: true });

  disableFocus = () => this.setState({ focused: false });

  render() {
    const { id, name, onDelete, dependants, t } = this.props;
    const { isEdit, justEdited, focused } = this.state;
    const { onSave, onCancel, enableFocus, disableFocus, onEdit } = this;

    const dependantsLength = dependants.length;
    const undeletable = dependantsLength > 0;
    const dependantsTitles = dependants.slice(0, 2).join(', ');
    const additionalTitlesCount = dependantsLength - 2;

    return (
      <div
        data-testid={`PDC-${id}`}
        className={cn(
          styles.personalDataCategory,
          { [styles.isEditing]: isEdit },
          { [styles.wasJustEdited]: justEdited },
          { [styles.isFocused]: focused },
          { [styles.undeletable]: undeletable },
        )}
      >
        {isEdit ? (
          <PdcInlineForm
            {...{ id, name }}
            onSubmit={onSave}
            onCancel={onCancel}
            onFocus={enableFocus}
            onBlur={disableFocus}
          />
        ) : (
          <Fragment>
            <span className={styles.label}>{name}</span>

            {!undeletable ? (
              <span className={styles.buttons}>
                <Button kind="ghost" onClick={onEdit} type="button">
                  <Trans>shared.edit</Trans>
                </Button>
                <Button kind="ghostDanger" onClick={onDelete} type="submit">
                  <Trans>shared.delete</Trans>
                </Button>
              </span>
            ) : (
              <Tooltip
                text={
                  additionalTitlesCount <= 0
                    ? t('profile_page.personal_data_categories.tooltips.locked_pdc', {
                        dependants_titles: dependantsTitles,
                      })
                    : t('profile_page.personal_data_categories.tooltips.locked_pdc_more', {
                        dependants_titles: dependantsTitles,
                        count: additionalTitlesCount,
                      })
                }
              >
                <span className={styles.lockIconContainer}>
                  <LockIcon />
                </span>
              </Tooltip>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

PersonalDataCategory.defaultProps = {
  className: '',
  name: '',
  isCreating: false,
  wasJustCreated: false,
  undeletable: false,
  onCancel: () => {},
  onDelete: () => {},
  onEditStart: () => {},
  dependants: [],
};

PersonalDataCategory.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isCreating: PropTypes.bool,
  className: PropTypes.string,
  wasJustCreated: PropTypes.bool,
  onEditStart: PropTypes.func,
  undeletable: PropTypes.bool,
  dependants: PropTypes.arrayOf(PropTypes.string),
};

export default withNamespaces()(PersonalDataCategory);

import React from 'react';
import Trans from 'src/components/shared/Trans/Trans';

import styles from './NoDeclarationPlaceholder.module.scss';

const NoDeclarationPlaceholder = () => (
  <div className={styles.placeholder}>
    <span className={styles.info}>
      <Trans>contract_details_page.no_declaration.description</Trans>
    </span>
  </div>
);

export default NoDeclarationPlaceholder;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import snakeCase from 'lodash/snakeCase';

import { eventListPropTypes } from 'src/prop_types/declaration_prop_types';
import Trans from 'src/components/shared/Trans/Trans';

import { withNamespaces } from 'react-i18next';
import Version from './Version/Version';

import styles from './VersionsTable.module.scss';

class VersionsTable extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    events: eventListPropTypes,
  };

  static defaultProps = {
    events: [],
  };

  state = {
    open: null,
  };

  openVersion = version => () => {
    this.setState(
      prevState =>
        prevState.open === version
          ? {
              open: null,
            }
          : {
              open: version,
            },
    );
  };

  render() {
    const { events, t } = this.props;
    const { open: currentOpenVersion } = this.state;

    const versions = events.reduce((acc, curr) => {
      const versionsList = acc.map(evnt => evnt.version);

      if (!versionsList.includes(curr.version)) {
        return [
          ...acc,
          {
            remoteIp: curr.remoteIp,
            timestamp: curr.timestamp,
            version: curr.version,
            event: '',
          },
        ];
      }

      return acc;
    }, []);

    const translatedEvents = events.map(e => ({
      ...e,
      event: t(
        `contract_declaration_details_page.versions_table.events_names.${snakeCase(e.event)}`,
        e.event,
      ),
    }));

    return (
      <div className={styles.tableContainer}>
        <table className={styles.versionsTable}>
          <thead>
            <tr className={styles.header}>
              <th className={cn(styles.iconColumn)} />
              <th className={styles.versionColumn}>
                <Trans>contract_declaration_details_page.versions_table.version</Trans>
              </th>
              <th className={styles.narrow}>
                <Trans>contract_declaration_details_page.versions_table.remote_ip</Trans>
              </th>
              <th className={styles.narrow}>
                <Trans>shared.updated_at</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {versions.map(version => (
              <Version
                events={translatedEvents.filter(e => e.version === version.version)}
                key={version.version}
                version={version}
                isOpen={version.version === currentOpenVersion}
                onClick={this.openVersion(version.version)}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withNamespaces()(VersionsTable);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Form from 'src/components/shared/OnBlurForm/OnBlurForm';

import { withNamespaces } from 'react-i18next';
import Trans from 'src/components/shared/Trans/Trans';

import { getOrganizationDetails, updateOrganizationDetails } from 'src/api/organization';

import withToast from 'src/components/Toast/withToast/withToast';

import Button from 'src/components/shared/Button/Button';
import Loading from 'src/components/shared/Loading/Loading';

import OrganizationFields from 'src/components/shared/OrganizationFields/OrganizationFields';

import styles from './Organization.module.scss';

class Organization extends Component {
  static propTypes = {
    openToast: PropTypes.func.isRequired,
    toggleHint: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    organization: null,
  };

  componentDidMount() {
    const { openToast, t, toggleHint } = this.props;
    toggleHint(false);

    getOrganizationDetails()
      .then(organization => {
        const { attributes } = organization;

        this.setState({
          organization: {
            ...attributes,
          },
        });
      })
      .catch(() => {
        openToast({
          message: t('profile_page.organization.updated_err'),
          type: 'danger',
        });
      });
  }

  onSubmit = (organization, form) => {
    const { openToast, t } = this.props;

    updateOrganizationDetails(organization)
      .then(() => {
        openToast({ message: t('profile_page.organization.toasts.update_successful') });
        this.setState(
          {
            organization,
          },
          form.reset,
        );
      })
      .catch(() => {
        openToast({
          message: t('profile_page.organization.errors.general'),
          type: 'danger',
        });
      });
  };

  render() {
    const { onSubmit } = this;
    const { organization } = this.state;

    if (!organization) {
      return <Loading />;
    }

    return (
      <Fragment>
        <Form
          onSubmit={onSubmit}
          initialValues={organization}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <h3 className={styles.title}>
                <Trans>profile_page.organization.title</Trans>
                <Button kind="primary" type="submit">
                  <Trans>profile_page.organization.update_organization_button</Trans>
                </Button>
              </h3>

              <OrganizationFields />
            </form>
          )}
        />
      </Fragment>
    );
  }
}

export default withNamespaces()(withToast(Organization));

import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const DefaultFooter = ({ goToPreviousPage, currentPage, totalPages, t }) => (
  <div>
    {currentPage > 1 && (
      <button type="button" onClick={goToPreviousPage}>
        {t('shared.previous_step')}
      </button>
    )}
    {currentPage === totalPages ? (
      <button type="submit">{t('shared.publish')}</button>
    ) : (
      <button type="submit">{t('shared.next_step')}</button>
    )}
  </div>
);

DefaultFooter.propTypes = {
  goToPreviousPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(DefaultFooter);

import React from 'react';
import PropTypes from 'prop-types';

import styles from './Strong.module.scss';

const Strong = ({ children }) => <strong className={styles.strong}>{children}</strong>;

Strong.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Strong;

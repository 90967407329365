import React from 'react';
import PropTypes from 'prop-types';
import Trans from 'src/components/shared/Trans/Trans';

import { consentPropTypes } from 'src/prop_types/consent_prop_types';

import SortArrows from 'src/components/shared/SortArrows/SortArrows';

import ConsentRow from './ConsentRow/ConsentRow';

import styles from './ConsentsList.module.scss';

const ConsentsList = ({
  sortBy,
  sortMode,
  consents,
  onSelectAll,
  onSelect,
  allSelected,
  changeSort,
}) => (
  <table className={styles.consentsList}>
    <thead>
      <tr className={styles.titleRow}>
        <th>
          <input type="checkbox" name="checkAll" checked={allSelected} onChange={onSelectAll} />
        </th>
        <th>
          <Trans>consents_page.consents_list.user_id</Trans>
        </th>
        <th>
          <button onClick={changeSort('processor')} type="button">
            <Trans>consents_page.consents_list.processor</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="processor" />
          </button>
        </th>
        <th>
          <Trans>consents_page.consents_list.consent_declaration</Trans>
        </th>
        <th>
          <button onClick={changeSort('date')} type="button">
            <Trans>shared.given_at</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="date" />
          </button>
        </th>
        <th>
          <button onClick={changeSort('valid_until')} type="button">
            <Trans>consents_page.consents_list.expiry_date</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="valid_until" />
          </button>
        </th>
        <th>
          <button onClick={changeSort('type')} type="button">
            <Trans>shared.status</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="type" />
          </button>
        </th>

        <th>
          <Trans>shared.sandbox_header</Trans>
        </th>

        <th className={styles.action}/>
      </tr>
    </thead>
    <tbody>
      {Object.values(consents).map(({ selected, consent }) => (
        <ConsentRow key={consent.id} onSelect={onSelect} selected={selected} consent={consent} />
      ))}
    </tbody>
  </table>
);

ConsentsList.defaultProps = {
  consents: {},
  onSelectAll: () => {},
  onSelect: () => {},
  allSelected: false,
  changeSort: () => {},
};

ConsentsList.propTypes = {
  consents: PropTypes.objectOf(
    PropTypes.shape({
      selected: PropTypes.bool,
      consent: consentPropTypes.isRequired,
    }),
  ),
  onSelectAll: PropTypes.func,
  onSelect: PropTypes.func,
  changeSort: PropTypes.func,
  allSelected: PropTypes.bool,
  sortBy: PropTypes.string.isRequired,
  sortMode: PropTypes.oneOf(['asc', 'desc']).isRequired,
};

export default ConsentsList;

import React from 'react';
import PropTypes from 'prop-types';

import Trans from 'src/components/shared/Trans/Trans';

import { withNamespaces } from 'react-i18next';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';

import Button from 'src/components/shared/Button/Button';
import InputField from 'src/components/shared/InputField/InputField';
import { isEmail } from 'src/utils/validators';

import styles from './ResetPasswordEmailForm.module.scss';

const ResetPasswordEmailForm = ({ onSubmit, t }) => (
  <div>
    <h3 className={styles.title}>
      <Trans>reset_password_page.email_form.title</Trans>
    </h3>

    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <InputField label={t('shared.your_email')} name="email" validators={[isEmail]} required />

          <Button className={styles.submitButton} kind="primary" type="submit">
            <Trans>reset_password_page.email_form.send_reset_email_button</Trans>
          </Button>
        </form>
      )}
    </Form>
  </div>
);

ResetPasswordEmailForm.propTypes = {
  onSubmit: PropTypes.func,
  t: PropTypes.func.isRequired,
};

ResetPasswordEmailForm.defaultProps = {
  onSubmit: () => {},
};

export default withNamespaces()(ResetPasswordEmailForm);

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { withNamespaces } from 'react-i18next';

import withToast from 'src/components/Toast/withToast/withToast';

import {EditorState} from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import MultiPageForm from 'src/components/shared/MultiPageForm/MultiPageForm';

import ContractDeclarationDetails from 'src/components/shared/ContractDeclarationDetails/ContractDeclarationDetails';
import InputField from 'src/components/shared/InputField/InputField';
import Checkbox from 'src/components/shared/Checkbox/Checkbox';
import TextEditor from 'src/components/shared/TextEditorField/TextEditorField';
import { declarationFormPropTypes } from 'src/prop_types/declaration_prop_types';

import { getOrganizationDetails } from 'src/api/organization';
import { toCamelCase } from 'src/utils/caseMappers';

import Footer from './Footer/Footer';
import ProcessorStep from './ProcessorStep/ProcessorStep';
import ValidityField from './ValidityField/ValidityField';

import styles from './DeclarationForm.module.scss';

const Page = props => <MultiPageForm.Page className={styles.declarationForm} {...props} />;

class DeclarationForm extends PureComponent {
  state = {
    organization: {},
    editorState: EditorState.createEmpty(),
  };

  componentDidMount() {
    const { declaration: { organization } = {}, openToast, t } = this.props;

    if (!organization) {
      getOrganizationDetails()
        .then(({ attributes }) => {
          this.setState({
            organization: toCamelCase(attributes),
          });
        })
        .catch(() => {
          openToast({
            message: t('declaration_form.details_step.errors.fetch_organization'),
            type: 'danger',
            inModal: true,
          });
        });
    }
  }

  onSubmit = declaration => {
    const { onSubmit } = this.props;
    onSubmit(this.appendValidityDate(declaration));
  };

  appendValidityDate = declaration => {
    const validUntil = moment().add(declaration.validity, declaration.validityUnit);

    return {
      ...declaration,
      validUntil: declaration.validity && declaration.validityUnit ? validUntil : null,
    };
  };

  checkForThirdParties = ({ thirdParties, dataTransfer, ...declaration }) => ({
    ...declaration,
    thirdParties: dataTransfer ? thirdParties : [],
  });

  prepareInitialValues = declaration => {
    const {
      thirdParties = [],
      automatedDecisionMakingDescription,
      personalDataCategories = [],
    } = declaration;

    const { organization } = this.state;

    const { t } = this.props;

    return {
      thirdParties,
      purposeTitle: t('consent_declaration.purpose_title'),
      personalDataCategoriesTitle: t('consent_declaration.personal_data_categories_title'),
      consentValidTitle: t('consent_declaration.consent_valid_title'),
      processingOfDataTitle: t('consent_declaration.processing_of_data_title'),
      processorTitle: t('consent_declaration.processor_title'),
      companyDetailsTitle: t('consent_declaration.company_details_title'),
      withdrawalTitle: t('consent_declaration.withdrawal_title'),
      automatedDecisionMakingTitle: t('consent_declaration.automated_decision_making_title'),
      thirdPartiesTitle: t('consent_declaration.third_parties_title'),
      validityIndefiniteTitle: t('shared.indefinite'),
      personalDataCategoriesDescription: t(
        'consent_declaration.personal_data_categories_description',
      ),
      processorDescription: t('consent_declaration.processor_description'),
      organization,
      ...declaration,
      dataTransfer: thirdParties.length > 0,
      automatedDecisionMakingEnabled: !isEmpty(automatedDecisionMakingDescription),
      personalDataCategories,
    };
  };

  render() {
    const { t, declaration } = this.props;
    const { appendValidityDate, onSubmit, checkForThirdParties } = this;
    const initialValues = this.prepareInitialValues(declaration);

    return (
      <MultiPageForm
        initialValues={initialValues}
        footer={Footer}
        onSubmit={onSubmit}
        mutators={{
          setProcessorField: ([processor], state, { changeValue }) => {
            changeValue(state, 'processor', () => processor);
          },
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          }
        }}
      >
        <Page>
          <Fragment>
            <InputField
              className={styles.row}
              label={t('declaration_form.general_info.title')}
              name="title"
              required
              autoFocus
            />

            <Checkbox
              className={styles.row}
              id="test"
              name="test"
              disabled={declaration.contractsCount > 0}
              label={t('declaration_form.general_info.contract.test')}
            />

            <Checkbox
              className={styles.row}
              id="active"
              name="active"
              label="Active"
            />

            <Checkbox
              className={styles.row}
              id="required"
              name="required"
              label="Required"
            />

            <InputField
              className={styles.row}
              label="Version"
              name="version"
            />

            <FormSpy>
              {({ form: { mutators } }) => (
                <TextEditor
                className={styles.row}
                label={t('declaration_form.general_info.purpose')}
                name="purpose"
                required
                setFormValue={mutators.setValue}
                />
              )}
            </FormSpy>
          </Fragment>
        </Page>

        <Page>
          <Fragment>
            <FormSpy>
              {({ form: { mutators } }) => (
                <TextEditor
                className={styles.row}
                label={t('declaration_form.general_info.acceptance_text')}
                name="body"
                required
                setFormValue={mutators.setValue}
                />
              )}
            </FormSpy>
            <FormSpy>
              {({ form: { mutators } }) => (
                <TextEditor
                className={styles.row}
                label={t('declaration_form.general_info.withdrawal')}
                name="withdrawDetails"
                required
                setFormValue={mutators.setValue}
                />
              )}
            </FormSpy>
          </Fragment>
        </Page>
        <Page>
          <FormSpy>
            {({ form: { mutators } }) => (
              <ProcessorStep setProcessorField={mutators.setProcessorField} processorId={initialValues.processorId}/>
            )}
          </FormSpy>
        </Page>
        <Page>
          <Fragment>
            <FormSpy>
              {({ values }) => <ValidityField autoFocus unit={values.validityUnit} />}
            </FormSpy>
          </Fragment>
        </Page>
        <Page>
          <FormSpy>
            {({ values }) => (
              <div className={styles.scrollBox}>
                <ContractDeclarationDetails
                  declaration={checkForThirdParties(appendValidityDate(values))}
                  isEditable
                />
              </div>
            )}
          </FormSpy>
        </Page>
      </MultiPageForm>
    );
  }
}

DeclarationForm.defaultProps = {
  declaration: {}, // eslint-disable-line react/default-props-match-prop-types
  onSubmit: null,
};

DeclarationForm.propTypes = {
  ...declarationFormPropTypes,

  onSubmit: PropTypes.func,
};

export default withNamespaces()(withToast(DeclarationForm));

import React from 'react';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';

import Strong from 'src/components/shared/Strong/Strong';
import Code from 'src/components/shared/Code/Code';
import List from 'src/components/shared/List/List';
import ListItem from 'src/components/shared/List/ListItem';
import Heading from 'src/components/shared/Heading/Heading';

const Trans = withNamespaces()(
  ({ i18nKey, children, noMd, vars, renderers, parent, t, date, fallback, lng, ...props }) => {
    if (date) {
      const momentDate = moment(children).locale(t('moment_date_key'));

      if (momentDate.isValid()) {
        return momentDate.format(date === true ? 'L' : date);
      }

      if (fallback) {
        return t(fallback);
      }
    }

    const text = t(i18nKey || children, vars);

    if (noMd) {
      return text;
    }

    const LinkComp = ({ href, children: linkChildren }) => {
      if (/^https?:\/\//.test(href)) {
        return (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {linkChildren}
          </a>
        );
      }

      return <Link to={href}>{linkChildren}</Link>;
    };

    LinkComp.propTypes = {
      href: PropTypes.string.isRequired,
      children: PropTypes.node.isRequired,
    };

    return (
      <Markdown
        source={text}
        renderers={{
          link: LinkComp,
          root: parent,
          strong: Strong,
          inlineCode: Code,
          code: Code,
          list: List,
          listItem: ListItem,
          heading: Heading,
          ...renderers,
        }}
        {...props}
      />
    );
  },
);

Trans.propTypes = {
  parent: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
  i18nKey: PropTypes.string,
  children: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fallback: PropTypes.string,
  noMd: PropTypes.bool,
  vars: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  renderers: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Trans.defaultProps = {
  parent: 'span',
  i18nKey: null,
  children: null,
  noMd: false,
  vars: {},
  renderers: {},
  date: null,
  fallback: null,
};

export default Trans;

import React from 'react';
import PropTypes from 'prop-types';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';

import Trans from 'src/components/shared/Trans/Trans';
import { withNamespaces } from 'react-i18next';

import Modal from 'src/components/shared/Modal/Modal';
import Button from 'src/components/shared/Button/Button';
import ModalFooter from 'src/components/shared/ModalFooter/ModalFooter';
import ProcessorFormFields from 'src/components/shared/ProcessorFormFields/ProcessorFormFields';

import { processorFormPropTypes } from 'src/prop_types/processor_prop_types';

const ProcessorFormModal = ({ open, onClose, processor, isEdit, onSubmit, t }) => (
  <Modal
    {...{ open, onClose }}
    title={isEdit ? t('processor_details_page.details_title') : t('shared.new_processor')}
  >
    <Form onSubmit={onSubmit} initialValues={processor}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ProcessorFormFields />

          <ModalFooter>
            <Button kind="outline" onClick={onClose} type="button">
              <Trans noMd>shared.cancel</Trans>
            </Button>

            <Button type="submit" kind="primary">
              <Trans noMd>shared.save</Trans>
            </Button>
          </ModalFooter>
        </form>
      )}
    </Form>
  </Modal>
);

ProcessorFormModal.defaultProps = {
  isEdit: false,
  lastSubmittedProcessorName: '',
  processor: {}, // eslint-disable-line react/default-props-match-prop-types
};

ProcessorFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  lastSubmittedProcessorName: PropTypes.string,
  ...processorFormPropTypes,
};

export default withNamespaces()(ProcessorFormModal);

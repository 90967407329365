import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { withNamespaces } from 'react-i18next';

import styles from './FooterLanguagePicker.module.scss';

const FooterLanguagePicker = ({ t, i18n }) => {
  const onClick = lng => e => {
    e.preventDefault();
    i18n.changeLanguage(lng);
  };

  const availableLanguages = Object.keys(i18n.store.data);

  const currentLanguageCodes = i18n.languages;
  const currentLanguageIndex = currentLanguageCodes.reduce(
    (prev, curr) => (prev === -1 ? availableLanguages.findIndex(value => value === curr) : prev),
    -1,
  );

  const currentLanguage = availableLanguages[currentLanguageIndex];

  return (
    <footer>
      <ul className={styles.languagesList}>
        {availableLanguages.map(lng => (
          <li key={lng} className={cn({ [styles.currentLanguage]: lng === currentLanguage })}>
            <a onClick={onClick(lng)} href={`?locale=${lng}`}>
              {t(`shared.languages.${lng}`, { lng })}
            </a>
          </li>
        ))}
      </ul>
    </footer>
  );
};

FooterLanguagePicker.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func.isRequired,
    store: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      data: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withNamespaces()(FooterLanguagePicker);

import React from 'react';
import PropTypes from 'prop-types';

import { withNamespaces } from 'react-i18next';

import SelectField from 'src/components/shared/SelectField/SelectField';

const ProcessorField = ({ name, required, options, t, findProcessorById, ...props }) => {
  const format = (val = {}) => options.filter(({ value }) => value === val.id)[0];

  const parse = ({ value }) => findProcessorById(value);

  return (
    <SelectField
      {...props}
      {...{ name, required, format, parse }}
      options={options}
      label={t('declaration_form.processor_step.processor')}
      placeholder={t('declaration_form.processor_step.processor_placeholder')}
      showSelected
    />
  );
};

ProcessorField.defaultProps = {
  required: false,
};

ProcessorField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  required: PropTypes.bool,
};

export default withNamespaces()(ProcessorField);

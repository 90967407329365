import React from 'react';
import PropTypes from 'prop-types';

import { withNamespaces } from 'react-i18next';

import SelectField from 'src/components/shared/SelectField/SelectField';

import countries from './countries';

const CountriesField = ({ name, required, t }) => {
  const countriesOptions = countries.map(countryCode => ({
    value: countryCode,
    label: t(`countries.${countryCode}`),
  }));
  const format = id => countriesOptions.filter(({ value }) => value === id)[0];

  const parse = ({ value }) => value;

  return (
    <SelectField
      {...{ name, required, parse, format }}
      options={countriesOptions}
      label={t('shared.registration_country')}
      placeholder={t('shared.select_country')}
      showSelected
    />
  );
};

CountriesField.defaultProps = {
  required: false,
};

CountriesField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default withNamespaces()(CountriesField);

import React from 'react';
import Trans from 'src/components/shared/Trans/Trans';

import NoConsentsImage from 'src/components/image/NoConsentsImage';
import urls from 'src/constants/urls';

import styles from './NoConsents.module.scss';

const NoConsents = ({ fromConsentDeclaration = false }) => (
  <div className={styles.noConsents}>
    <NoConsentsImage />

    {!fromConsentDeclaration && (
      <>
        <h3>
          <Trans>consents_page.no_consents.title</Trans>
        </h3>
        <Trans vars={{ consent_declarations_href: urls.declarations() }}>
          consents_page.no_consents.description
        </Trans>
      </>
    )}
  </div>
);

export default NoConsents;

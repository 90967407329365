import React from 'react';
import source from 'images/show_password.svg';

import Icon from './Icon';

const ShowPasswordIcon = ({ ...props }) => (
  <Icon alt="show password icon" src={source} {...props} />
);

export default ShowPasswordIcon;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import elements from 'src/widget/elements';

import withToast from 'src/components/Toast/withToast/withToast';
import toastPropTypes from 'src/prop_types/toastPropTypes';

import getServerSideRenderingCodeSnippet from 'src/constants/widgetSnippet';

import { showDeclaration } from 'src/api/consent_declarations';

import Code from 'src/components/shared/Code/Code';
import Loading from 'src/components/shared/Loading/Loading';
import Title from 'src/components/shared/Title/Title';
import Trans from 'src/components/shared/Trans/Trans';
import Heading from 'src/components/shared/Heading/Heading';

import styles from './EmbedPage.module.scss';

const TransWithHeadings = ({ children, ...rest }) => (
  <Trans
    renderers={{
      heading: Heading,
    }}
    {...rest}
  >
    {children}
  </Trans>
);

class EmbedPage extends PureComponent {
  widgetElement = React.createRef();

  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    t: PropTypes.func.isRequired,
    openToast: toastPropTypes.openToast.isRequired,
  };

  state = {
    isLoading: true,
    declaration: {},
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    showDeclaration(id).then(declarationData => {
      this.setState({
        declaration: declarationData,
        isLoading: false,
      });

      const traqWidgetApiMock = {
        getConsentDeclarationUrl: () => `/declaration/${id}?token=${declarationData.widgetId}`,
        getConsentDeclaration: () => Promise.resolve(declarationData),
      };

      const customWidgetParameters = {
        customForm: document.createElement('form'),
        customElement: this.widgetElement.current,
      };

      elements({ ...traqWidgetApiMock, ...customWidgetParameters });
    });
  }

  onCopySuccess = () => {
    const { t, openToast } = this.props;

    openToast({
      message: t('embed_page.toasts.copy_success'),
    });
  };

  onCopyFail = () => {
    const { t, openToast } = this.props;

    openToast({
      message: t('embed_page.errors.copy_fail'),
      type: 'danger',
    });
  };

  render() {
    const {
      declaration: { processor: { name: processorName } = {}, id: declarationId, widgetId },
      isLoading,
    } = this.state;

    const { onCopySuccess, onCopyFail } = this;
    const { t } = this.props;
    const base = window.location.origin;
    const scriptUrl = `${base}/packs/widget.js`;
    const cssUrl = `${base}/packs/widget.css`;

    const serverSideRenderingCodeSnippet = getServerSideRenderingCodeSnippet(
      scriptUrl,
      cssUrl,
      base,
      // eslint-disable-next-line
      (processorName || '').replace(new RegExp(/"/g), "\\\""),
      declarationId,
      widgetId,
    );

    return (
      <main className={styles.page}>
        <Title title={t('embed_page.title')} />

        <div className={styles.content}>
          <div className={styles.description}>
            <TransWithHeadings>embed_page.description.general_info</TransWithHeadings>
          </div>

          <div className={styles.description}>
            <TransWithHeadings>embed_page.description.how_to</TransWithHeadings>
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <div className={styles.description}>
              <Code language="html" onCopySuccess={onCopySuccess} onCopyFail={onCopyFail}>
                {serverSideRenderingCodeSnippet}
              </Code>
            </div>
          )}

          <div className={styles.description}>
            <TransWithHeadings>embed_page.description.code_guide</TransWithHeadings>
          </div>
        </div>
      </main>
    );
  }
}

TransWithHeadings.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(withNamespaces()(withToast(EmbedPage)));

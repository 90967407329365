import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Form from 'src/components/shared/OnBlurForm/OnBlurForm';

import { withNamespaces } from 'react-i18next';
import Trans from 'src/components/shared/Trans/Trans';

import { getRoles } from 'src/api/roles';
import { getProfileDetails, updateProfileDetails, deleteProfile } from 'src/api/profile';

import withSession from 'src/components/AuthContext/withSession/withSession';
import { authPropTypes } from 'src/prop_types/auth_prop_types';

import withToast from 'src/components/Toast/withToast/withToast';

import Button from 'src/components/shared/Button/Button';
import Columns from 'src/components/shared/Columns/Columns';
import Loading from 'src/components/shared/Loading/Loading';
import InputField from 'src/components/shared/InputField/InputField';
import PasswordField from 'src/components/shared/PasswordField/PasswordField';
import SelectField from 'src/components/shared/SelectField/SelectField';
import ConfirmPasswordModal from 'src/components/shared/ConfirmPasswordModal/ConfirmPasswordModal';
import { isEmail } from 'src/utils/validators';
import PrivacySettings from './PrivacySettings/PrivacySettings';

import styles from './YourProfile.module.scss';

class YourProfile extends Component {
  state = {
    roles: [],
    details: null,
    oldDetails: null,
    deleteModal: false,
    language: {},
    confirmModal: false,
    emailConfirmationPassword: null,
    usersOrganizations: [],
  };

  openDeleteModal = this.openDeleteModal.bind(this);

  static propTypes = {
    auth: authPropTypes.auth.isRequired,
    i18n: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    t: PropTypes.func.isRequired,
    toggleHint: PropTypes.func.isRequired,
    openToast: PropTypes.func.isRequired,
  };

  onConfirmModalAcceptResolve = null;

  componentDidMount() {
    const { openToast, t, toggleHint } = this.props;
    toggleHint(false);

    getRoles()
      .then(roles => this.setState({ roles }))
      .then(() => {
        getProfileDetails()
          .then(details =>
            this.setState(({ roles }) => ({
              usersOrganizations: details.attributes.organizations,
              details: {
                full_name: details.attributes.full_name,
                email: details.attributes.email,
                role: roles.find(({ value }) => value === details.attributes.role),
                terms: details.attributes.terms,
                mailing: details.attributes.mailing,
              },
              oldDetails: {
                full_name: details.attributes.full_name,
                email: details.attributes.email,
                role: roles.find(({ value }) => value === details.attributes.role),
                terms: details.attributes.terms,
                mailing: details.attributes.mailing,
              },
            })),
          )
          .catch(() => {
            openToast({ message: t('profile_page.your_profile.errors.general'), type: 'danger' });
          });
      })
      .catch(() => {
        openToast({ message: t('profile_page.your_profile.errors.general'), type: 'danger' });
      });
  }

  onSubmit = ({ email, language, ...details }, form) => {
    const {
      i18n,
      t,
      openToast,
      auth: { handleUpdateUserDetails },
    } = this.props;
    const {
      oldDetails: { email: currentEmail },
      language: oldLanguage,
      emailConfirmationPassword,
    } = this.state;

    const currentPassword = details.current_password;

    this.setState({
      details: { ...details, email, language },
    });

    return new Promise(resolve => {
      const hasEmailChanged = email !== currentEmail;
      if (hasEmailChanged && !emailConfirmationPassword) {
        this.setState({ confirmModal: true });
        return resolve({ reject: true });
      }
      return updateProfileDetails({
        ...details,
        ...(emailConfirmationPassword ? { email } : {}),
        current_password: emailConfirmationPassword || currentPassword,
        role: details.role.value,
        language: language.value,
      })
        .then(() => {
          handleUpdateUserDetails({ email });

          this.setState(
            {
              oldDetails: { ...details, email, language },
              emailConfirmationPassword: null,
            },
            () => {
              setTimeout(form.reset);

              if (oldLanguage.value !== language.value) {
                i18n.changeLanguage(language.value);
              }

              openToast({ message: t('profile_page.your_profile.toasts.updated_successfully') });
            },
          );

          if (this.onConfirmModalAcceptResolve) {
            this.onConfirmModalAcceptResolve();
          }

          this.onConfirmModalAcceptResolve = null;
          return resolve();
        })
        .catch(({ status }) => {
          if (status === 422) {
            if (this.onConfirmModalAcceptResolve) {
              return this.onConfirmModalAcceptResolve({
                passwordConfirmation: t('profile_page.your_profile.errors.incorrect_password'),
              });
            }

            return resolve({
              current_password: t('profile_page.your_profile.errors.incorrect_password'),
            });
          }

          openToast({
            message: t('profile_page.your_profile.errors.general_edit'),
            type: 'danger',
          });

          return resolve({});
        });
    });
  };

  onDelete = ({ passwordConfirmation }) =>
    new Promise(resolve => {
      const {
        auth: { handleLogout },
        t,
        openToast,
      } = this.props;

      deleteProfile(passwordConfirmation)
        .then(() => {
          handleLogout();
          openToast({
            message: t('profile_page.your_profile.toasts.removed_successfully'),
          });

          return resolve(undefined);
        })
        .catch(({ status }) => {
          if (status === 422) {
            return resolve({
              passwordConfirmation: t('profile_page.your_profile.errors.incorrect_password'),
            });
          }

          return undefined;
        });
    });

  closeDeleteModal = () => {
    this.setState({ deleteModal: false });
  };

  onConfirmModalClose = () => {
    this.setState(prevState => ({
      confirmModal: false,
      details: {
        ...prevState.details,
        email: prevState.oldDetails.email,
      },
    }));
  };

  onConfirmModalAccept = () => {
    this.setState(prevState => ({
      confirmModal: false,
      details: {
        ...prevState.details,
        email: prevState.oldDetails.email,
      },
    }));
  };

  onConfirmModalAccept = handleSubmit => ({ passwordConfirmation }) =>
    new Promise(resolve => {
      this.onConfirmModalAcceptResolve = resolve;
      this.setState({ emailConfirmationPassword: passwordConfirmation }, handleSubmit);
    }).then(err => {
      if (!err) {
        this.setState({ confirmModal: false });
      }
      return err;
    });

  openDeleteModal() {
    this.setState({ deleteModal: true });
  }

  render() {
    const {
      onSubmit,
      onDelete,
      closeDeleteModal,
      openDeleteModal,
      onConfirmModalClose,
      onConfirmModalAccept,
    } = this;
    const { roles, deleteModal, details, confirmModal, usersOrganizations } = this.state;

    const {
      t,
      i18n,
      auth: { currentOrganization },
    } = this.props;

    const translatedRoles = roles.map(({ value }) => ({
      value,
      label: t(`shared.roles.${value}`),
    }));

    const availableLanguages = Object.keys(i18n.store.data);
    const availableLanguagesOptions = availableLanguages.map(lng => ({
      value: lng,
      label: t(`shared.languages.${lng}`),
    }));

    const currentLanguageCodes = i18n.languages;
    const currentLanguageIndex = currentLanguageCodes.reduce((prev, curr) => {
      if (prev === -1) {
        return availableLanguagesOptions.findIndex(({ value }) => value === curr);
      }

      return prev;
    }, -1);

    const currentLanguage = availableLanguagesOptions[currentLanguageIndex];

    if (details === null) {
      return <Loading />;
    }

    const organizations = usersOrganizations
      .filter(({ id }) => id !== currentOrganization)
      .map(({ company_name: companyName }) => companyName);
    const organizationsFormatted = organizations.join(', ');

    const initialValues = {
      ...details,
      language: currentLanguage,
      privacy_policy: true,
    };

    return (
      <div>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <h3 className={styles.title}>
                {t('profile_page.your_profile.form.title')}
                <Button kind="primary" type="submit">
                  {t('profile_page.your_profile.form.update_profile_button')}
                </Button>
              </h3>
              <section className={styles.section}>
                <Columns className={styles.gap}>
                  {{
                    left: <InputField label={t('shared.full_name')} name="full_name" />,
                    right: (
                      <InputField
                        label={t('shared.your_email')}
                        name="email"
                        validators={[isEmail]}
                      />
                    ),
                  }}
                </Columns>
                <Columns className={styles.gap}>
                  {{
                    left: (
                      <SelectField
                        label={t('shared.role')}
                        name="role"
                        options={translatedRoles}
                        showSelected
                        required
                        placeholder=""
                      />
                    ),
                    right: (
                      <SelectField
                        label={t('profile_page.your_profile.form.preferred_language')}
                        name="language"
                        options={availableLanguagesOptions}
                        showSelected
                        placeholder=""
                      />
                    ),
                  }}
                </Columns>
              </section>
              <h3 className={styles.title}>
                <Trans>profile_page.your_profile.form.change_password</Trans>
              </h3>
              <section className={styles.section}>
                <Columns>
                  {{
                    left: (
                      <PasswordField
                        label={t('shared.current_password')}
                        name="current_password"
                        required={values.new_password !== undefined}
                        hideOptional
                      />
                    ),
                    right: (
                      <PasswordField
                        label={t('shared.new_password')}
                        name="new_password"
                        hideOptional
                      />
                    ),
                  }}
                </Columns>
              </section>
              <ConfirmPasswordModal
                open={confirmModal}
                onClose={onConfirmModalClose}
                onAccept={onConfirmModalAccept(handleSubmit)}
                title={t('profile_page.your_profile.confirm_email_modal.title')}
                acceptLabel={t('profile_page.your_profile.confirm_email_modal.accept_label')}
              >
                <Trans
                  vars={{
                    count: organizations.length,
                    organizations: organizationsFormatted,
                  }}
                >
                  {organizations.length === 0
                    ? 'profile_page.your_profile.confirm_email_modal.description_no_organizations'
                    : 'profile_page.your_profile.confirm_email_modal.description'}
                </Trans>
              </ConfirmPasswordModal>
            </form>
          )}
        </Form>
        <PrivacySettings onSubmit={onSubmit} initialValues={initialValues} />
        <h3 className={styles.title}>
          <Trans>shared.delete_account</Trans>
        </h3>
        <div className={styles.row}>
          <p className={styles.deleteInfo}>{t('shared.delete_user_account_modal.content')}</p>
          <Button kind="outlineDanger" onClick={openDeleteModal} type="submit">
            {t('shared.delete_account')}
          </Button>
        </div>

        <ConfirmPasswordModal
          onClose={closeDeleteModal}
          onAccept={onDelete}
          open={deleteModal}
          title={t('profile_page.your_profile.delete_user_modal.title')}
          acceptLabel={t('shared.delete_account')}
        >
          <Trans>profile_page.your_profile.delete_user_modal.description</Trans>
        </ConfirmPasswordModal>
      </div>
    );
  }
}

export default withToast(withNamespaces()(withSession(YourProfile)));

import React, { Component, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import { withNamespaces } from 'react-i18next';

import Trans from 'src/components/shared/Trans/Trans';

import {
  showProcessor,
  updateProcessor,
  updateWebhook,
  createWebhook,
  deleteWebhook,
} from 'src/api/processors';
import withToast from 'src/components/Toast/withToast/withToast';
import Button from 'src/components/shared/Button/Button';
import BreadcrumbTitle from 'src/components/shared/BreadcrumbTitle/BreadcrumbTitle';
import CaptionedInfo from 'src/components/shared/CaptionedInfo/CaptionedInfo';
import Columns from 'src/components/shared/Columns/Columns';
import ProcessorFormModal from 'src/components/shared/ProcessorFormModal/ProcessorFormModal';
import Loading from 'src/components/shared/Loading/Loading';
import urls from 'src/constants/urls';
import { webhookEvents } from 'src/constants/webhookEvents';

import WebhooksModal from './WebhooksModal/WebhooksModal';
import CountPill from './CountPill/CountPill';
import ProcessorRecordsPage from '../ProcessorRecordsPage/ProcessorRecordsPage';

import styles from './ProcessorDetailsPage.module.scss';

class ProcessorDetailsPage extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
  };

  state = {
    processor: {},
    editModal: false,
    webhooksModal: false,
    isLoading: true,
    lastSubmittedProcessorName: '',
  };

  componentDidMount() {
    this.getProcessor();
  }

  onSubmit = processor =>
    new Promise(resolve => {
      const { history, t } = this.props;
      updateProcessor(processor)
        .then(result => {
          this.setState({
            lastSubmittedProcessorName: processor.name,
          });

          history.push(urls.processors());

          return resolve(result);
        })
        .catch(() => {
          resolve({
            name: t('error_messages.processor_name_already_exists'),
          });
        });
    });

  getProcessor = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    showProcessor(id).then(processor => this.setState({ processor, isLoading: false }));
  };

  saveWebhook = ({ events_choice: eventsChoice, events, id, targetUrl }) => {
    const isNewWebhook = !id;

    const newWebhook = {
      events:
        eventsChoice !== 'send_everything'
          ? Object.entries(events).reduce((acc, [key, value]) => (value ? [...acc, key] : acc), [])
          : webhookEvents,
      id: id || targetUrl,
      targetUrl,
    };

    this.setState(({ processor }) => ({
      processor: {
        ...processor,
        webhooks: [...processor.webhooks.filter(webhook => webhook.id !== id), newWebhook],
      },
    }));

    if (!isNewWebhook) {
      return this.editLocalWebhook(newWebhook);
    }

    return this.createLocalWebhook(newWebhook);
  };

  createLocalWebhook = newWebhook => {
    const {
      match: {
        params: { id: processorId },
      },
      openToast,
      t,
    } = this.props;

    return createWebhook(processorId, newWebhook)
      .then(createdWebhook => {
        this.setState(({ processor }) => ({
          processor: {
            ...processor,
            webhooks: [
              ...processor.webhooks.filter(webhook => webhook.id !== newWebhook.targetUrl),
              createdWebhook,
            ],
          },
        }));
      })
      .catch(() => {
        openToast({
          type: 'danger',
          message: t('processor_details_page.webhooks_modal.errors.creation'),
          inModal: true,
        });

        this.setState(({ processor }) => ({
          processor: {
            ...processor,
            webhooks: [
              ...processor.webhooks.filter(webhook => webhook.id !== newWebhook.targetUrl),
            ],
          },
        }));
      });
  };

  editLocalWebhook = newWebhook => {
    const {
      match: {
        params: { id: processorId },
      },
      openToast,
      t,
    } = this.props;

    const { processor: oldProcessor } = this.state;
    const oldWebhook = oldProcessor.webhooks.find(webhook => webhook.id === newWebhook.id);

    return updateWebhook(processorId, newWebhook)
      .then(updatedWebhook => {
        this.setState(({ processor }) => ({
          processor: {
            ...processor,
            webhooks: [
              ...processor.webhooks.filter(webhook => webhook.id !== newWebhook.id),
              updatedWebhook,
            ],
          },
        }));
      })
      .catch(() => {
        openToast({
          type: 'danger',
          message: t('processor_details_page.webhooks_modal.errors.edition'),
          inModal: true,
        });

        this.setState(({ processor }) => ({
          processor: {
            ...processor,
            webhooks: [
              ...processor.webhooks.filter(webhook => webhook.id !== newWebhook.id),
              oldWebhook,
            ],
          },
        }));
      });
  };

  deleteLocalWebhook = webhookToDelete => () => {
    const {
      match: {
        params: { id: processorId },
      },
      t,
      openToast,
    } = this.props;

    deleteWebhook(processorId, webhookToDelete).catch(() => {
      openToast({
        type: 'danger',
        message: t('processor_details_page.webhooks_modal.errors.deletion'),
        inModal: true,
      });

      this.setState(({ processor }) => ({
        processor: {
          ...processor,
          webhooks: [...processor.webhooks, webhookToDelete],
        },
      }));
    });

    this.setState(({ processor }) => ({
      processor: {
        ...processor,
        webhooks: [...processor.webhooks.filter(webhook => webhook.id !== webhookToDelete.id)],
      },
    }));
  };

  toggleEditModal = () => this.setState(prevState => ({ editModal: !prevState.editModal }));

  toggleWebhooksModal = () =>
    this.setState(prevState => ({ webhooksModal: !prevState.webhooksModal }));

  render() {
    const {
      processor: {
        name,
        token,
        contactName,
        companyName,
        contactEmail,
        contactPhone,
        additionalDetails,
        updatedAt,
        lastApiCallAt,
        updatedBy,
        createdAt,
        createdBy,
        webhooks,
      },
      processor,
      editModal,
      webhooksModal,
      isLoading,
      lastSubmittedProcessorName,
    } = this.state;

    const {
      onSubmit,
      toggleEditModal,
      toggleWebhooksModal,
      saveWebhook,
      deleteLocalWebhook,
    } = this;

    const { t } = this.props;

    return (
      <main className={styles.processorDetailsPage}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <BreadcrumbTitle
              path={[
                {
                  label: t('shared.processors'),
                  pathElement: urls.processors(),
                },
              ]}
              current={name}
            />

            <section className={styles.content}>
              <div className={styles.details}>
                <h3 className={styles.detailTitle}>
                  <Trans>processor_details_page.details_title</Trans>
                  <div>
                    <Button
                      kind="outline"
                      onClick={toggleWebhooksModal}
                      type="button"
                      className={styles.webhooksButton}
                    >
                      {t('processor_details_page.webhooks')}
                      {webhooks.length > 0 && (
                        <CountPill count={webhooks.length} className={styles.webhooksButtonPill} />
                      )}
                    </Button>
                    <Button kind="outline" onClick={toggleEditModal} type="button">
                      {t('shared.edit')}
                    </Button>
                  </div>
                </h3>

                <Columns className={styles.row}>
                  {{
                    left: (
                      <CaptionedInfo title={t('processor_details_page.captioned_info.api_key')}>
                        {token}
                      </CaptionedInfo>
                    ),
                    right: (
                      <CaptionedInfo title={t('shared.last_api_call')}>
                        <Trans date="lll" fallback="shared.never">
                          {lastApiCallAt}
                        </Trans>
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>

                <div className={styles.row}>
                  <CaptionedInfo title={t('processor_details_page.captioned_info.details')}>
                    {additionalDetails || t('processor_details_page.no_details')}
                  </CaptionedInfo>
                </div>
              </div>

              <div className={styles.organization}>
                <h3 className={styles.detailTitle}>
                  <Trans>processor_details_page.organization.detail_title</Trans>
                </h3>

                <Columns className={styles.row}>
                  {{
                    left: (
                      <CaptionedInfo title={t('shared.company_name')}>{companyName}</CaptionedInfo>
                    ),
                    right: (
                      <CaptionedInfo title={t('shared.company_email')}>
                        {contactEmail}
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>

                <Columns className={styles.row}>
                  {{
                    left: (
                      <CaptionedInfo title={t('shared.contact_person')}>
                        {contactName}
                      </CaptionedInfo>
                    ),
                    right: (
                      <CaptionedInfo title={t('shared.contact_phone')}>
                        {contactPhone}
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>
              </div>

              <div className={styles.history}>
                <h3 className={styles.detailTitle}>{t('shared.history')}</h3>

                <Columns className={styles.row}>
                  {{
                    left: (
                      <CaptionedInfo title={t('shared.updated_at')}>
                        <Trans date="ll">{updatedAt}</Trans>
                      </CaptionedInfo>
                    ),
                    right: (
                      <CaptionedInfo title={t('shared.updated_by')}>
                        { updatedBy == null ? 'API' : updatedBy.fullName}
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>

                <Columns className={styles.row}>
                  {{
                    left: (
                      <CaptionedInfo title={t('shared.created_at')}>
                        <Trans date="ll">{createdAt}</Trans>
                      </CaptionedInfo>
                    ),
                    right: (
                      <CaptionedInfo title={t('shared.created_by')}>
                        { createdBy == null ? 'API' : createdBy.fullName}
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>
              </div>
            </section>
            
            <div>
              <ProcessorRecordsPage controller={processor} />
            </div>

            <ProcessorFormModal
              open={editModal}
              onClose={toggleEditModal}
              {...{ onSubmit, processor }}
              isEdit
              lastSubmittedProcessorName={lastSubmittedProcessorName}
            />

            <WebhooksModal
              open={webhooksModal}
              webhooks={webhooks}
              onClose={toggleWebhooksModal}
              saveWebhook={saveWebhook}
              deleteWebhook={deleteLocalWebhook}
            />
          </Fragment>
        )}
      </main>
    );
  }
}

export default withToast(withNamespaces()(ProcessorDetailsPage));

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Heading.module.scss';

const Heading = ({ level, children }) => {
  const HeadingElement = `h${level}`;
  const headingClass = styles[`heading-${level}`];

  return (
    <HeadingElement className={cn({ [headingClass]: headingClass !== undefined })}>
      {children}
    </HeadingElement>
  );
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number.isRequired,
};

export default Heading;

import replaceIfExists from 'src/utils/replaceIfExists';
import apiRequest from './apiRequest';
import EP from './endpoints';

const formatOrganizationCreation = organization => ({
  data: {
    type: 'registration',
    attributes: {
      ...organization,
      language: replaceIfExists(organization.language, '-', '_').toLowerCase(),
    },
  },
});

const formatOrganizationUpdate = organization => ({
  data: {
    type: 'organization',
    attributes: {
      ...organization,
    },
  },
});

export const createOrganization = organization =>
  apiRequest(EP.registration, {
    method: 'POST',
    body: formatOrganizationCreation(organization),
  });

export const getOrganizationDetails = () => apiRequest(EP.organization).then(({ data }) => data);

export const updateOrganizationDetails = organization =>
  apiRequest(EP.organization, {
    method: 'PATCH',
    body: formatOrganizationUpdate(organization),
  });

export const confirmOrganization = organization =>
  apiRequest(`${EP.registration}/confirm`, {
    method: 'PUT',
    withAuthorization: false,
    body: formatOrganizationCreation(organization),
  });

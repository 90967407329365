import isObject from 'lodash/isObject';

const createQueryParams = params => {
  if (!isObject(params)) {
    throw new TypeError('Params must be an object');
  }

  const paramsArray = Object.entries(params).reduce(
    (prev, [key, value]) => [...prev, `${encodeURIComponent(key)}=${encodeURIComponent(value)}`],
    [],
  );

  return `?${paramsArray.join('&')}`;
};

export default createQueryParams;

import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';

import Trans from 'src/components/shared/Trans/Trans';
import ErrorMessage from 'src/components/shared/ErrorMessage/ErrorMessage';
import Label from 'src/components/shared/Label/Label';
import HidePassword from 'src/components/image/HidePassword';
import ShowPassword from 'src/components/image/ShowPassword';
import { isRequired } from 'src/utils/validators';
import { shouldDisplayError } from 'src/utils/shouldDisplayError';
import { shouldDisplayCheckmark } from 'src/utils/shouldDisplayCheckmark';

import styles from './PasswordField.module.scss';

class PasswordField extends Component {
  state = {
    type: 'password',
  };

  toggleField = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(prevState => ({ type: prevState.type === 'password' ? 'text' : 'password' }));
  };

  render() {
    const { label, name, className, required, hideOptional } = this.props;
    const { type } = this.state;

    return (
      <div className={cn(className, styles.field)}>
        <Field {...{ name }} validate={required ? isRequired : null}>
          {({
            input,
            meta: { valid, error, touched, dirty, submitError, dirtySinceLastSubmit },
          }) => (
            <Fragment>
              <Label
                htmlFor={name}
                required={required}
                hideOptional={hideOptional}
                shouldDisplayCheckmark={shouldDisplayCheckmark({
                  required,
                  valid,
                  value: input.value,
                  submitError,
                  dirtySinceLastSubmit,
                })}
              >
                {label}
              </Label>

              <input
                {...input}
                {...{ type }}
                className={cn(styles.inputControl, {
                  [styles.error]: shouldDisplayError({
                    valid,
                    touched,
                    dirty,
                    dirtySinceLastSubmit,
                    submitError,
                  }),
                })}
                id={name}
              />

              <span
                className={styles.inputToggle}
                role="button"
                onClick={this.toggleField}
                onKeyPress={this.toggleField}
              >
                {type === 'text' ? (
                  <HidePassword className={styles.icon} />
                ) : (
                  <ShowPassword className={styles.icon} />
                )}
              </span>
              {shouldDisplayError({ valid, touched, dirty, dirtySinceLastSubmit, submitError }) && (
                <ErrorMessage>
                  <Trans noMd>{error || submitError}</Trans>
                </ErrorMessage>
              )}
            </Fragment>
          )}
        </Field>
      </div>
    );
  }
}

PasswordField.defaultProps = {
  required: false,
  hideOptional: false,
  label: 'Label',
  className: '',
};

PasswordField.propTypes = {
  required: PropTypes.bool,
  hideOptional: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PasswordField;

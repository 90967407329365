import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import CloseIcon from 'src/components/image/CloseIcon';

import styles from './Modal.module.scss';

const Modal = ({ className, onClose, open, children, title }) => (
  <ReactModal
    overlayClassName={styles.overlay}
    className={`${styles.modal} ${className}`}
    isOpen={open}
    onRequestClose={onClose}
    ariaHideApp={false}
    shouldCloseOnOverlayClick={false}
  >
    <header className={styles.header}>
      <h3 className={styles.title}>{title}</h3>
      <button className={styles.close} onClick={onClose} type="button">
        <CloseIcon />
      </button>
    </header>

    {children}
  </ReactModal>
);

Modal.defaultProps = {
  className: '',
  title: 'Modal',
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default Modal;

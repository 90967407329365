import React, { PureComponent } from 'react';
import { Consumer } from '../context';

class ComponentWithToast extends PureComponent {
  render() {
    const { component: C, ...props } = this.props;
    return <C {...props} />;
  }
}

const withToast = Component => props => (
  <Consumer>
    {({ openToast, closeToast }) => (
      <ComponentWithToast
        {...props}
        openToast={openToast}
        closeToast={closeToast}
        component={Component}
      />
    )}
  </Consumer>
);

export default withToast;

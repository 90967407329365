import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Switch.module.scss';

const Switch = ({ className, onChange, onBlur, ...props }) => (
  <input
    type="checkbox"
    className={cn(styles.checkbox, className)}
    {...props}
    onChange={(...attrs) => {
      onChange(...attrs);
      onBlur(...attrs);
    }}
  />
);

Switch.defaultProps = {
  className: '',
  onChange: () => {},
  onBlur: () => {},
};

Switch.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Switch;

const urls = {
  settings: '/settings',
  organizations: '/settings/organizations',
  personalDataCategories: '/settings/personal_data_categories',
  processors: '/controllers',
  users: '/manage_users',
  declarations: '/consent_declarations',
  consents: '/consents',
  contractDeclarations: '/contract_declarations',
  contracts: '/contracts',
  landing: '/landing',
  signUp: '/landing/sign_up',
  signIn: '/landing/sign_in',
  passwordReset: '/reset_password',
  termsAndConditions: '/terms_and_conditions',
  privacyPolicy: '/privacy_policy',
  embed: '/embed',
  declaration: '/declaration',
  contractDeclaration: '/contract_declaration',
  confirmRegistration: '/confirm_registration',
  logout: '/logout',
  declarationDetails: '/declaration_details',
  contractDeclarationDetails: '/contract_declaration_details',
  dashboard: '/dashboard',
};

const buildUrl = (url, id) => url + (id ? `/${id}` : '');

const declaration = id => buildUrl(urls.declaration, id);
const contractDeclaration = id => buildUrl(urls.contractDeclaration, id);
const embed = id => buildUrl(urls.embed, id);
const consents = id => buildUrl(urls.consents, id);
const contracts = id => buildUrl(urls.contracts, id);
const declarations = id => buildUrl(urls.declarations, id);
const contractDeclarations = id => buildUrl(urls.contractDeclarations, id);
const users = id => buildUrl(urls.users, id);
const processors = id => buildUrl(urls.processors, id);
const processorRecords = (controller_id, id) => buildUrl(`${urls.processors}/${controller_id}/processors`, id);
// return functions for API consistent with other routes
const settings = () => urls.settings;
const organizations = () => urls.organizations;
const personalDataCategories = () => urls.personalDataCategories;
const landing = () => urls.landing;
const signIn = () => urls.signIn;
const signUp = () => urls.signUp;
const passwordReset = () => urls.passwordReset;
const termsAndConditions = () => urls.termsAndConditions;
const confirmRegistration = () => urls.confirmRegistration;
const privacyPolicy = () => urls.privacyPolicy;
const logout = () => urls.logout;
const declarationDetails = () => urls.declarationDetails;
const contractDeclarationDetails = () => urls.contractDeclarationDetails;
const dashboard = () => urls.dashboard;

export default {
  consents,
  declarations,
  contracts,
  contractDeclarations,
  users,
  processors,
  settings,
  organizations,
  personalDataCategories,
  landing,
  signIn,
  signUp,
  passwordReset,
  processorRecords,
  termsAndConditions,
  embed,
  declaration,
  contractDeclaration,
  confirmRegistration,
  privacyPolicy,
  logout,
  declarationDetails,
  contractDeclarationDetails,
  dashboard,
};

import PropTypes from 'prop-types';

const auth = PropTypes.shape({
  handleLogin: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userDetails: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
  }),
});

export const authPropTypes = { auth };

import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import { processorListPropTypes } from 'src/prop_types/processor_prop_types';

import urls from 'src/constants/urls';

import DateCard from 'src/components/shared/DateCard/DateCard';

import styles from './ProcessorsList.module.scss';

const ProcessorsList = ({ processors, t }) => (
  <ul className={styles.processorsList}>
    {processors.map(({ id, name, companyName, lastApiCallAt }) => (
      <li key={id}>
        <DateCard
          title={name}
          subtitle={companyName}
          date={lastApiCallAt}
          dateTitle={t('shared.last_api_call')}
          logoClassName={styles.processorCardLogo}
          cardComponent={Link}
          dateFallback={t('shared.never')}
          cardProps={{
            to: urls.processors(id),
          }}
        />
      </li>
    ))}
  </ul>
);

ProcessorsList.defaultProps = {
  processors: [], // eslint-disable-line
};

ProcessorsList.propTypes = {
  ...processorListPropTypes,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(ProcessorsList);

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './CountPill.module.scss';

const CountPill = ({ count, className }) => (
  <span className={cn(styles.countPill, className)}>{count}</span>
);

CountPill.propTypes = {
  count: PropTypes.number.isRequired,
  className: PropTypes.string,
};

CountPill.defaultProps = {
  className: '',
};

export default CountPill;

import React from 'react';
import PropTypes from 'prop-types';

import { withNamespaces } from 'react-i18next';

import Trans from 'src/components/shared/Trans/Trans';

import Button from 'src/components/shared/Button/Button';
import Modal from 'src/components/shared/Modal/Modal';
import ModalFooter from 'src/components/shared/ModalFooter/ModalFooter';
import { declarationFormPropTypes } from 'src/prop_types/declaration_prop_types';

import DeclarationForm from './DeclarationForm/DeclarationForm';

import styles from './DeclarationFormModal.module.scss';

const DeclarationFormModal = ({ open, onClose, onSubmit, isEdit, declaration, t }) => (
  <Modal
    {...{ open, onClose }}
    title={
      isEdit
        ? t('declaration_form.title.edit_declaration')
        : t('declaration_form.title.new_declaration')
    }
    className={styles.declarationModal}
  >
    <DeclarationForm onSubmit={onSubmit} declaration={declaration}>
      <ModalFooter>
        <Button kind="outline" onClick={onClose} type="button">
          <Trans noMd>shared.cancel</Trans>
        </Button>

        <Button kind="primary" type="submit">
          <Trans noMd>shared.publish</Trans>
        </Button>
      </ModalFooter>
    </DeclarationForm>
  </Modal>
);

DeclarationFormModal.defaultProps = {
  isEdit: false,
};

DeclarationFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  t: PropTypes.func.isRequired,
  ...declarationFormPropTypes,
};

export default withNamespaces()(DeclarationFormModal);

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { toCamelCase, toSnakeCase } from 'src/utils/caseMappers';
import stringify from 'src/utils/stringify';

import i18next from 'src/utils/i18n';
import { showDeclaration } from './consent_declarations';
import { paginate, sortConsents } from './utils';

import apiRequest from './apiRequest';
import pagingParams from './pagingParams';
import EP from './endpoints';

export const parseConsent = ({ id, attributes, relationships }) => {
  const { date, identifier, validUntil, type, test } = toCamelCase(attributes);
  const processor = get(relationships, 'processor.data.id', null);
  const declarationId = get(toCamelCase(relationships), 'consentDeclaration.data.id', null);

  return {
    id,
    identifier,
    date,
    test,
    processor,
    status: type === 'valid',
    validUntil,
    declarationId,
  };
};

export const parseIncludedProcessors = included =>
  included
    .filter(item => item.type === 'processor')
    .reduce((memo, { id, attributes: { name } }) => ({ ...memo, [id]: name }), {});

export const parseIncludedConsentDeclarations = included =>
  included.reduce(
    (memo, { id, attributes: { title }, type }) =>
      type === 'consent_declaration' ? { ...memo, [id]: title } : memo,
    {},
  );

export const parseConsentWithoutId = (data, included) => {
  const pdcs = data.attributes.personal_data_categories || [];
  const { attributes: processorAttributes } = included.find(
    item => item.type === 'processor' && item.id === data.relationships.processor.data.id,
  );

  return {
    purpose: data.attributes.subject,
    personalDataCategories: pdcs.map(pdc => ({ name: pdc, id: pdc })),
    validUntil: data.attributes.valid_until,
    processor: toCamelCase(processorAttributes),

    purposeTitle: i18next.t('consent_declaration.purpose_title'),
    personalDataCategoriesTitle: i18next.t('consent_declaration.personal_data_categories_title'),
    consentValidTitle: i18next.t('consent_declaration.consent_valid_title'),
    processingOfDataTitle: i18next.t('consent_declaration.processing_of_data_title'),
    processorTitle: i18next.t('consent_declaration.processor_title'),
    withdrawalTitle: i18next.t('consent_declaration.withdrawal_title'),
    companyDetailsTitle: i18next.t('consent_declaration.company_details_title'),
    automatedDecisionMakingTitle: i18next.t('consent_declaration.automated_decision_making_title'),
    third_parties_title: i18next.t('consent_declaration.third_parties_title'),
    personalDataCategoriesDescription: i18next.t(
      'consent_declaration.personal_data_categories_description',
    ),
    processorDescription: i18next.t('consent_declaration.processor_description'),
    validityIndefiniteTitle: i18next.t('shared.indefinite'),
  };
};

const revokeBody = id => ({
  data: {
    type: 'revocation',
    attributes: {
      reason: 'admin',
    },
    relationships: {
      consents: {
        data: [
          {
            type: 'consent',
            id,
          },
        ],
      },
    },
  },
});

export const getConsents = (page, type, validity, sort, identifier, perPage) => {
  const apiUrl = identifier != 'placeholder_identifier' ? `${EP.consents}/index_by_identifier` : EP.consents;
  const queryParams = {
    ...pagingParams(page, perPage),
    type: type,
    validity: validity,
    order: stringify(toSnakeCase(sort)),
  };
  if (identifier != 'placeholder_identifier') {
    queryParams.identifier = identifier;
  }

  return apiRequest(apiUrl, {
    query: queryParams,
  }).then(({ data, included, meta }) => {
    const processors = isEmpty(included) ? [] : parseIncludedProcessors(included);
    const consentDeclarations = isEmpty(included) ? [] : parseIncludedConsentDeclarations(included);

    const consents = data.map(parseConsent).map(consent => ({
      ...consent,
      processor: processors[consent.processor],
      consentDeclarationName: consentDeclarations[consent.declarationId],
    }));

    return { consents, meta: toCamelCase(meta) };
  });
};

export const getConsentsFnForDeclaration = async declarationId => {
  const {
    meta: { totalEntries: totalAmountOfConsents },
  } = await getConsents(1, 'all', 'all', { date: 'asc' }, null, 1);

  if (totalAmountOfConsents === 0) {
    return () => ({
      consents: [],
      meta: {
        currentPage: 1,
        perPage: 25,
        totalEntries: 0,
        totalPages: 1,
      },
    });
  }

  const allDeclarationConsents = await getConsents(
    1,
    'all',
    'all',
    { date: 'asc' },
    null,
    totalAmountOfConsents,
  ).then(data => data.consents.filter(c => c.declarationId === declarationId));

  return async (page, type, validity, sort, identifier) => {
    let consents = allDeclarationConsents;
    let totalEntries = consents.length;

    if (identifier) {
      consents = await getConsents(page, type, validity, sort, identifier, totalAmountOfConsents).then(data =>
        data.consents.filter(c => c.declarationId === declarationId),
      );
      totalEntries = consents.length;
    } else {
      consents = sortConsents(consents, sort);
    }

    return {
      consents: paginate(consents, 25)[page - 1] || [],
      meta: {
        currentPage: page,
        perPage: 25,
        totalEntries,
        totalPages: Math.ceil(totalEntries / 25),
      },
    };
  };
};

export const showConsent = id =>
  apiRequest(`${EP.consents}/${id}`)
    .then(({ data, included }) => {
      const parsedConsent = parseConsent(data);

      return {
        ...parsedConsent,
        declaration: parseConsentWithoutId(data, included),
      };
    })
    .then(consent => {
      const { declarationId } = consent;

      if (declarationId) {
        return showDeclaration(consent.declarationId).then(declaration => ({
          ...consent,
          declaration,
        }));
      }
      return consent;
    });

export const revokeConsent = id =>
  apiRequest(EP.revocations, {
    body: revokeBody(id),
  });

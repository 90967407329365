import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';

import Trans from 'src/components/shared/Trans/Trans';
import { shouldDisplayError } from 'src/utils/shouldDisplayError';
import { shouldDisplayCheckmark } from 'src/utils/shouldDisplayCheckmark';

import ErrorMessage from 'src/components/shared/ErrorMessage/ErrorMessage';
import Label from 'src/components/shared/Label/Label';
import { isRequired } from 'src/utils/validators';

import Select from './Select/Select';

import styles from './SelectField.module.scss';

const SelectField = ({
  name,
  label,
  placeholder,
  options,
  onChange,
  showSelected,
  className,
  required,
  format,
  parse,
  autoFocus,
  hideCheckmark,
}) => {
  const selectOnChange = changeCb => val => onChange(val, changeCb);

  return (
    <div className={cn(className, styles.field)} id={name}>
      <Field {...{ format, parse, name }} validate={required ? isRequired : null}>
        {({
          input: { onChange: onFieldChange },
          input,
          meta: { valid, error, touched, dirty, submitError, dirtySinceLastSubmit },
        }) => (
          <Fragment>
            {label && (
              <Label
                required={required}
                shouldDisplayCheckmark={shouldDisplayCheckmark({
                  required,
                  valid,
                  value: input.value,
                  submitError,
                  dirtySinceLastSubmit,
                })}
                hideCheckmark={hideCheckmark}
              >
                {label}
              </Label>
            )}

            <Select
              autoFocus={autoFocus}
              {...input}
              onChange={selectOnChange(onFieldChange)}
              className={cn({
                [styles.error]: shouldDisplayError({
                  valid,
                  touched,
                  dirty,
                  dirtySinceLastSubmit,
                  submitError,
                }),
              })}
              {...{ placeholder, options, showSelected }}
            />

            {shouldDisplayError({ valid, touched, dirty, dirtySinceLastSubmit, submitError }) && (
              <ErrorMessage>
                <Trans noMd>{error || submitError}</Trans>
              </ErrorMessage>
            )}
          </Fragment>
        )}
      </Field>
    </div>
  );
};

SelectField.defaultProps = {
  onChange: (val, changeCb) => changeCb(val),
  showSelected: false,
  label: null,
  placeholder: null,
  className: '',
  required: false,
  format: null,
  parse: null,
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  showSelected: PropTypes.bool,
  format: PropTypes.func,
  parse: PropTypes.func,
};

export default SelectField;

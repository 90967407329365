import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { eventListPropTypes, versionPropTypes } from 'src/prop_types/declaration_prop_types';
import VersionsTableRow from './VersionsTableRow/VersionsTableRow';

const Version = ({ version, events, isOpen, onClick }) => (
  <Fragment>
    <VersionsTableRow event={version} onClick={onClick} isVersion isOpen={isOpen} />
    {isOpen && events.map(e => <VersionsTableRow key={`${e.version}-${e.event}`} event={e} />)}
  </Fragment>
);

Version.propTypes = {
  events: eventListPropTypes.isRequired,
  version: versionPropTypes.isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Version.defaultProps = {
  isOpen: false,
};

export default Version;

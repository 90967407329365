import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Trans from 'src/components/shared/Trans/Trans';
import { withNamespaces } from 'react-i18next';

import { createNewDeclarationVersion, duplicateDeclaration } from 'src/api/contract_declarations';

import withToast from 'src/components/Toast/withToast/withToast';

import urls from 'src/constants/urls';
import Modal from 'src/components/shared/Modal/Modal';
import Button from 'src/components/shared/Button/Button';
import ModalFooter from 'src/components/shared/ModalFooter/ModalFooter';
import { declarationPropTypes } from 'src/prop_types/declaration_prop_types';

import styles from './VersionActionChoiceModal.module.scss';

const DUPLICATE_MODE = 'duplicate';
const NEW_VERSION_MODE = 'new version';

class VersionActionChoiceModal extends PureComponent {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    openToast: PropTypes.func.isRequired,
    declaration: declarationPropTypes.declaration, // eslint-disable-line react/require-default-props
    didProcessorChange: PropTypes.bool.isRequired,
  };

  state = {
    updateMode: null,
  };

  selectNewMode = () => this.setState({ updateMode: DUPLICATE_MODE });

  selectUpdateMode = () => this.setState({ updateMode: NEW_VERSION_MODE });

  duplicateDeclaration = () => {
    const {
      declaration: { processor, ...declaration },
      history,
      openToast,
      t,
      didProcessorChange,
    } = this.props;

    duplicateDeclaration({
      ...declaration,
      ...(didProcessorChange && { processor }),
    })
      .then(() => history.push(urls.contractDeclarations()))
      .catch(() => {
        openToast({
          message: t('error_messages.contract_declaration_submit'),
          type: 'danger',
          inModal: true,
        });
      });
  };

  newVersion = () => {
    const {
      declaration: { processor, ...declaration },
      history,
      openToast,
      t,
    } = this.props;

    return createNewDeclarationVersion(declaration)
      .then(() => history.push(urls.contractDeclarations()))
      .catch(() => {
        openToast({
          message: t('error_messages.contract_declaration_submit'),
          type: 'danger',
          inModal: true,
        });
      });
  };

  performUpdate = () => {
    const { updateMode } = this.state;

    if (updateMode === DUPLICATE_MODE) this.duplicateDeclaration();
    if (updateMode === NEW_VERSION_MODE) this.newVersion();
  };

  render() {
    const { open, onClose, t } = this.props;
    const { updateMode } = this.state;

    return (
      <Modal
        {...{ open, onClose }}
        title={t('contract_declaration_details_page.version_action_choice_modal.title')}
      >
        <div className={styles.explanation}>
          <Trans>contract_declaration_details_page.version_action_choice_modal.explanation</Trans>
        </div>

        <div className={styles.twoColumns}>
          <button
            className={cn(styles.column, {
              [styles.selected]: updateMode === DUPLICATE_MODE,
            })}
            type="button"
            onClick={this.selectNewMode}
            data-testid="DUPLICATE_MODE"
          >
            <span className={styles.label}>
              <Trans>
                contract_declaration_details_page.version_action_choice_modal.duplicate_mode_button.label
              </Trans>
            </span>
            <div className={styles.description}>
              <Trans>
                contract_declaration_details_page.version_action_choice_modal.duplicate_mode_button.description
              </Trans>
            </div>
          </button>

          <button
            className={cn(styles.column, {
              [styles.selected]: updateMode === NEW_VERSION_MODE,
            })}
            onClick={this.selectUpdateMode}
            type="button"
            data-testid="NEW_VERSION_MODE"
          >
            <span className={styles.label}>
              <Trans>
                contract_declaration_details_page.version_action_choice_modal.new_version_button.label
              </Trans>
            </span>
            <div className={styles.description}>
              <Trans>
                contract_declaration_details_page.version_action_choice_modal.new_version_button.description
              </Trans>
            </div>
          </button>
        </div>

        <ModalFooter>
          <Button kind="outline" onClick={onClose} type="button">
            <Trans>shared.cancel</Trans>
          </Button>

          <Button
            kind="primary"
            disabled={updateMode === null}
            onClick={this.performUpdate}
            type="submit"
          >
            <Trans>
              contract_declaration_details_page.version_action_choice_modal.footer.confirm_button
            </Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withRouter(withNamespaces()(withToast(VersionActionChoiceModal)));

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import withToast from 'src/components/Toast/withToast/withToast';
import toastPropTypes from 'src/prop_types/toastPropTypes';

import Trans from 'src/components/shared/Trans/Trans';
import { withNamespaces } from 'react-i18next';

import { showContract, revokeContract } from 'src/api/contracts';
import Button from 'src/components/shared/Button/Button';
import CaptionedInfo from 'src/components/shared/CaptionedInfo/CaptionedInfo';
import DeclarationDetailsView from 'src/components/shared/ContractDeclarationDetailsView/ContractDeclarationDetailsView';
import ValidityStatus from 'src/components/shared/ValidityStatus/ValidityStatus';
import BreadcrumbTitle from 'src/components/shared/BreadcrumbTitle/BreadcrumbTitle';
import WarningModal from 'src/components/shared/WarningModal/WarningModal';
import Loading from 'src/components/shared/Loading/Loading';
import urls from 'src/constants/urls';

import NoDeclarationPlaceholder from './NoDeclarationPlaceholder/NoDeclarationPlaceholder';

import styles from './ContractDetailsPage.module.scss';

class ContractDetailsPage extends Component {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    t: PropTypes.func.isRequired,
    openToast: toastPropTypes.openToast.isRequired,
  };

  state = {
    contract: {},
    declaration: {},
    revokeModal: false,
    isLoading: true,
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    showContract(id).then(({ declaration, ...contract }) =>
      this.setState({ contract, declaration, isLoading: false }),
    );
  }

  revoke = () => {
    const {
      contract: { id },
    } = this.state;
    const { t, openToast, history } = this.props;

    revokeContract(id)
      .then(() => {
        openToast({ message: t('shared.contract_has_been_revoked_toast') });
        history.push(urls.contracts());
      })
      .catch(({ status }) => {
        if (status === 422) {
          openToast({ message: t('shared.contract_already_revoked_toast') });
          return history.push(urls.contracts());
        }

        return openToast({ message: t('contract_details_page.errors.revoke'), type: 'danger' });
      });
  };

  toggleRevokeModal = () => this.setState(prevState => ({ revokeModal: !prevState.revokeModal }));

  render() {
    const { revokeModal, declaration, contract, isLoading } = this.state;
    const { id, status, date, validUntil } = contract;
    const { processor: { name: processor } = {} } = declaration;
    const { revoke, toggleRevokeModal } = this;
    const { t } = this.props;

    const revokeModalTitle = t('contract_details_page.revoke_modal.title');
    const revokeModalContent = <Trans>shared.revoke_all_contracts_modal.content</Trans>;

    return (
      <main className={styles.contractDetailsPage}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <BreadcrumbTitle
              path={[
                {
                  label: t('shared.contracts'),
                  pathElement: urls.contracts(),
                },
              ]}
              current={id}
            >
              <Button
                kind="outlineDanger"
                onClick={toggleRevokeModal}
                disabled={!status}
                type="submit"
              >
                {t('contract_details_page.revoke_contract_button')}
              </Button>
            </BreadcrumbTitle>

            <section className={styles.content}>
              <div className={styles.contractInfo}>
                <CaptionedInfo
                  className={styles.info}
                  title={t('contract_details_page.captioned_info.contract_id')}
                  underlined
                >
                  {id}
                </CaptionedInfo>
                <CaptionedInfo className={styles.info} title={t('shared.status')} underlined>
                  <ValidityStatus valid={status} />
                </CaptionedInfo>
                <CaptionedInfo className={styles.info} title={t('shared.given_at')} underlined>
                  <Trans date>{date}</Trans>
                </CaptionedInfo>
                <CaptionedInfo
                  className={styles.info}
                  title={t('contract_details_page.captioned_info.expiry_at')}
                  underlined
                >
                  <Trans date>{validUntil}</Trans>
                </CaptionedInfo>
                <CaptionedInfo
                  className={styles.info}
                  title={t('contract_details_page.captioned_info.processor')}
                  underlined
                >
                  {processor}
                </CaptionedInfo>
              </div>

              {declaration ? (
                <DeclarationDetailsView declaration={declaration} />
              ) : (
                <NoDeclarationPlaceholder />
              )}

              <WarningModal
                title={revokeModalTitle}
                open={revokeModal}
                onClose={toggleRevokeModal}
                onAccept={revoke}
                acceptLabel={t('shared.revoke')}
              >
                {revokeModalContent}
              </WarningModal>
            </section>
          </Fragment>
        )}
      </main>
    );
  }
}

export default withNamespaces()(withToast(ContractDetailsPage));

import React from 'react';
import source from 'images/no_declarations.svg';

import Icon from './Icon';

const NoDeclarationsImage = ({ ...props }) => (
  <Icon alt="create declarations" src={source} {...props} />
);

export default NoDeclarationsImage;

/* eslint-disable prettier/prettier */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import parse from 'html-react-parser'
import cn from 'classnames';

import isEmpty from 'lodash/isEmpty';

import Trans from 'src/components/shared/Trans/Trans';

import Columns from 'src/components/shared/Columns/Columns';
import { declarationPropTypes } from 'src/prop_types/declaration_prop_types';

// eslint-disable-next-line no-unused-vars
import parsedHtml from 'src/components/shared/ParseHtml.module.scss';
import CompanyCard from './CompanyCard/CompanyCard';
import ProcessorCard from './ProcessorCard/ProcessorCard';
import DeclarationEditableText from './DeclarationEditableText/DeclarationEditableText';
import DeclarationHeader from './DeclarationHeader/DeclarationHeader';

import styles from './DeclarationDetails.module.scss';


const parseThirdParty = tp => {
  const { companyName, country, email, phone } = tp;

  return {
    companyName,
    contactPhone: phone,
    contactEmail: email,
    address1: country,
  };
};

const DeclarationDetailsView = ({ declaration, isEditable, t }) => (
  <article className={styles.declarationDetailsView}>
    {declaration.id && <h2 className={styles.h2}>{declaration.title}</h2>}

    <section className={styles.purposeSection}>
      <DeclarationHeader
        isEditable={isEditable}
        name="purposeTitle"
        initialValue={declaration.purposeTitle}
        placeholder={t('consent_declaration.purpose_title')}
      />

      <div
        className={parsedHtml.parsedHtml}>
        {parse(declaration.purpose)}
      </div>

      <DeclarationHeader
        isEditable={false}
        name="type"
        initialValue={ t('shared.sandbox_header') }
      />

      <div>
        { declaration.test ? 'Test' : 'Standard' }
      </div>
    </section>
    <Columns className={styles.dataCategoriesSection}>
      {{
        left: (
          <Fragment>
            <DeclarationHeader
              isEditable={isEditable}
              name="personalDataCategoriesTitle"
              initialValue={declaration.personalDataCategoriesTitle}
              placeholder={t('consent_declaration.personal_data_categories_title')}
            />

            <DeclarationEditableText
              isEditable={isEditable}
              name="personalDataCategoriesDescription"
              initialValue={declaration.personalDataCategoriesDescription}
              placeholder={t('consent_declaration.personal_data_categories_description')}
              className={styles.paragraphHeader}
              iconClassName={styles.editIcon}
            />

            <ul>
              {(declaration.personalDataCategories || []).map(({ id, name }) => (
                <li key={id}>{name}</li>
              ))}
            </ul>
          </Fragment>
        ),
        right: (
          <Fragment>
            <DeclarationHeader
              isEditable={isEditable}
              name="consentValidTitle"
              initialValue={declaration.consentValidTitle}
              placeholder={t('consent_declaration.consent_valid_title')}
            />

            {declaration.validUntil !== null ? (
              <p>
                <Trans date>{declaration.validUntil}</Trans>
              </p>
            ) : (
              <DeclarationEditableText
                isEditable={isEditable}
                name="validityIndefiniteTitle"
                initialValue={declaration.validityIndefiniteTitle}
                placeholder={t('shared.indefinite')}
                className={styles.paragraphHeader}
                iconClassName={styles.editIcon}
              />
            )}
          </Fragment>
        ),
      }}
    </Columns>

    {declaration.id && (
      <section className={styles.processingSection}>
        <DeclarationHeader
          isEditable={isEditable}
          name="processingOfDataTitle"
          initialValue={declaration.processingOfDataTitle}
          placeholder={t('consent_declaration.processing_of_data_title')}
        />

        <div
          className={parsedHtml.parsedHtml}>
          {parse(declaration.processing)}
        </div>
      </section>
    )}

    {declaration.processor && (
      <section className={styles.processorsSection}>
        <DeclarationHeader
          isEditable={isEditable}
          name="processorTitle"
          initialValue={declaration.processorTitle}
          placeholder={t('consent_declaration.processor_title')}
        />

        <DeclarationEditableText
          isEditable={isEditable}
          name="processorDescription"
          initialValue={declaration.processorDescription}
          placeholder={t('consent_declaration.processor_description')}
          className={styles.paragraphHeader}
          iconClassName={styles.editIcon}
        />

        <div className={styles.processors}>
          <ProcessorCard number={1} processor={declaration.processor} />
        </div>
      </section>

    )}

    {
      declaration.processor && declaration.processor.processorRecords && (
        <section className={styles.processorsSection}>
          <DeclarationHeader
            isEditable={false}
            name="processorRecordsTitle"
            initialValue={ t('shared.processor_records') }
          />

          <ul>
            { declaration.processor.processorRecords.map((processor) => (
              <div className={styles.processors}>
                <ProcessorCard processor={processor} />
              </div>
            ))}
          </ul>
        </section>
      )
    }

    {declaration.automatedDecisionMakingEnabled && (
      <section className={styles.decisionMakingSection}>
        <DeclarationHeader
          isEditable={isEditable}
          name="automatedDecisionMakingTitle"
          initialValue={declaration.automatedDecisionMakingTitle}
          placeholder={t('consent_declaration.automated_decision_making_title')}
        />

        <div
          className={parsedHtml.parsedHtml}>
          {parse(declaration.automatedDecisionMakingDescription)}
        </div>
      </section>
    )}

    {declaration.id && (
      <div
        className={cn(styles.companySection, {
          [styles.noThirdParty]: isEmpty(declaration.thirdParties),
        })}
      >
        <div className={styles.detailsContainer}>
          <div className={styles.companyDetails}>
            <DeclarationHeader
              className={styles.heading}
              isEditable={isEditable}
              name="companyDetailsTitle"
              initialValue={declaration.companyDetailsTitle}
              placeholder={t('consent_declaration.company_details_title')}
            />
            <CompanyCard
              company={{
                ...declaration.organization,
                contactEmail: declaration.organization.companyEmail,
              }}
            />
          </div>
          {!isEmpty(declaration.thirdParties) && (
            <div className="thirdParties">
              <Fragment>
                <DeclarationHeader
                  className={styles.secondHeading}
                  isEditable={isEditable}
                  name="thirdPartiesTitle"
                  initialValue={declaration.thirdPartiesTitle}
                  placeholder={t('consent_declaration.third_parties_title')}
                />
                {declaration.thirdParties.map((thirdParty, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i}>
                    <CompanyCard company={parseThirdParty(thirdParty)} />
                  </div>
                ))}
              </Fragment>
            </div>
          )}
        </div>
      </div>
    )}

    {declaration.id && (
      <section className={styles.withdrawalSection}>
        <DeclarationHeader
          isEditable={isEditable}
          name="withdrawalTitle"
          initialValue={declaration.withdrawalTitle}
          placeholder={t('consent_declaration.withdrawal_title')}
        />

        <div
          className={parsedHtml.parsedHtml}>
          {parse(declaration.withdrawDetails)}
        </div>
      </section>
    )}
  </article>
);

DeclarationDetailsView.propTypes = {
  ...declarationPropTypes,
  isEditable: PropTypes.bool,
};

DeclarationDetailsView.defaultProps = {
  isEditable: false,
};

export default withNamespaces()(DeclarationDetailsView);

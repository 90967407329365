import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import Downshift from 'downshift';
import cn from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import Trans from 'src/components/shared/Trans/Trans';

import DropdownArrow from 'src/components/image/DropdownArrow';
import ProfileIcon from 'src/components/image/ProfileIcon';
import withSession from 'src/components/AuthContext/withSession/withSession';
import urls from 'src/constants/urls';
import { authPropTypes } from 'src/prop_types/auth_prop_types';

import styles from './ProfileDropdown.module.scss';

class ProfileDropdown extends Component {
  state = {};

  linksList = React.createRef();

  dropdownButton = React.createRef();

  static propTypes = {
    auth: authPropTypes.auth.isRequired,
    superadmin: PropTypes.bool,
    location: ReactRouterPropTypes.location.isRequired,
  };

  static defaultProps = {
    superadmin: false,
  };

  onBlur = (closeMenu, e) => {
    const contains =
      this.linksList.current !== null && this.linksList.current.contains(e.relatedTarget);

    if (!contains && e.relatedTarget !== this.dropdownButton.current) {
      closeMenu();
    }
  };

  render() {
    const {
      auth: { handleLogout, userDetails },
      superadmin,
      location: { pathname },
    } = this.props;
    /* eslint-disable react/jsx-no-bind */
    return (
      <Downshift>
        {({ isOpen, getToggleButtonProps, getItemProps, highlightedIndex, closeMenu }) => {
          const closeOnBlur = this.onBlur.bind(null, closeMenu);

          return (
            <div className={styles.profileDropdown}>
              <button
                type="button"
                className={cn(styles.dropdown, { [styles.open]: isOpen })}
                {...getToggleButtonProps()}
                onBlur={closeOnBlur}
                ref={this.dropdownButton}
                onKeyDown={() => {}}
              >
                <ProfileIcon className={styles.profile} />
                <span className={styles.email}>{userDetails.email}</span>
                <DropdownArrow className={styles.arrow} />
              </button>

              {isOpen && (
                <ul className={styles.options} ref={this.linksList}>
                  {!superadmin && (
                    <li
                      className={cn(
                        styles.item,
                        { [styles.selected]: highlightedIndex === 0 },
                        { [styles.current]: pathname === urls.settings() },
                      )}
                      {...getItemProps({ index: 0, item: 0 })}
                    >
                      <Link to={urls.settings()} onBlur={closeOnBlur} onClick={closeMenu}>
                        <Trans noMd>topbar.profile_dropdown.settings</Trans>
                      </Link>
                    </li>
                  )}

                  <li
                    className={cn(styles.item, { [styles.selected]: highlightedIndex === 2 })}
                    {...getItemProps({ index: 2, item: 2 })}
                  >
                    <a
                      href={urls.logout()}
                      onBlur={closeOnBlur}
                      onClick={e => {
                        closeMenu();
                        e.preventDefault();
                        handleLogout();
                      }}
                    >
                      <Trans noMd>topbar.profile_dropdown.logout</Trans>
                    </a>
                  </li>
                </ul>
              )}
            </div>
          );
        }}
      </Downshift>
    );
    /* eslint-enable react/jsx-no-bind */
  }
}

export default withRouter(withSession(ProfileDropdown));

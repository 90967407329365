import React, { Component } from 'react';

import styles from './index.module.scss';
import Envelope from '../../image/Envelope';

class FloatingEmail extends Component {
  constructor(props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
    this.state = {
      isShown: false,
    };
  }

  onToggle() {
    this.setState(({ isShown }) => ({ isShown: !isShown }));
  }

  render() {
    const { isShown } = this.state;

    return (
      <div>
        <button type="button" className={styles.float} onClick={this.onToggle}>
          <Envelope className={styles.myFloat} />
        </button>
        {isShown && (
          <div className={styles.labelContainer}>
            <p className={styles.labelText}>Thomas@traq.tech</p>
          </div>
        )}
      </div>
    );
  }
}

export default FloatingEmail;

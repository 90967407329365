import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { NavLink } from 'react-router-dom';

import styles from './NavButton.module.scss';

const NavButton = ({ children, to, className, activeClassName }) => (
  <NavLink
    className={cn(styles.navButton, className)}
    activeClassName={cn(styles.current, activeClassName)}
    to={to}
    exact
  >
    {children}
  </NavLink>
);

NavButton.defaultProps = {
  activeClassName: '',
  className: '',
};

NavButton.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
};

export default NavButton;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Button.module.scss';

const Button = ({ component: Component, children, className, kind, disabled, type, ...rest }) => {
  const buttonClass = styles[kind];

  /* eslint-disable react/button-has-type */
  return (
    <Component
      type={type}
      className={cn(buttonClass, className, { [styles.disabled]: disabled })}
      {...{ disabled, ...rest }}
    >
      {children}
    </Component>
  );
  /* eslint-enable react/button-has-type */
};

Button.defaultProps = {
  role: null,
  className: null,
  onClick: null,
  disabled: false,
  component: 'button',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.oneOf([
    'primary',
    'outline',
    'danger',
    'outlineDanger',
    'ghost',
    'ghostDanger',
    'ghostPrimary',
  ]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'button', 'reset']).isRequired,
  role: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
};

export default Button;

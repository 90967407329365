import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { withNamespaces } from 'react-i18next';
import cn from 'classnames';

import withToast from 'src/components/Toast/withToast/withToast';
import toastPropTypes from 'src/prop_types/toastPropTypes';
import { getConsents, revokeConsent } from 'src/api/consents';

import withSort from 'src/components/shared/withSort/withSort';
import Pagination from 'src/components/shared/Pagination/Pagination';
import WarningModal from 'src/components/shared/WarningModal/WarningModal';
import Loading from 'src/components/shared/Loading/Loading';
import Title from 'src/components/shared/Title/Title';

import SelectedConsentsInfo from './SelectedConsentsInfo/SelectedConsentsInfo';
import NoConsents from './NoConsents/NoConsents';
import ConsentsList from './ConsentsList/ConsentsList';
import ConsentSearcher from './ConsentSearcher/ConsentSearcher';

import styles from './ConsentsPage.module.scss';
import i18next from 'src/utils/i18n';

class ConsentsPage extends Component {
  debounceHandler = null;

  static propTypes = {
    openToast: toastPropTypes.openToast.isRequired,
    getConsents: PropTypes.func
  };

  static defaultProps = {
    getConsents
  };

  state = {
    allSelected: false,
    type: {
      value: "all",
      label: i18next.t("shared.type.all"),
    },
    validity: {
      value: "all",
      label: i18next.t("shared.validity.all"),
    },
    currentPage: 1,
    totalPages: 1,
    consents: {},
    modal: false,
    isLoading: true,
    searchValue: '',
  };

  componentDidMount() {
    const { currentPage } = this.state;

    this.getPageOfConsents(currentPage);
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const { currentPage } = this.state;

    if (props.sortBy !== prevProps.sortBy || props.sortMode !== prevProps.sortMode) {
      this.getPageOfConsents(currentPage);
    }
  }

  onNextClick = () => {
    const { currentPage, totalPages } = this.state;

    if (currentPage < totalPages) {
      this.setState({
        isLoading: true,
      });

      this.getPageOfConsents(currentPage + 1);
    }
  };

  onPrevClick = () => {
    const { currentPage } = this.state;

    if (currentPage > 1) {
      this.setState({
        isLoading: true,
      });

      this.getPageOfConsents(currentPage - 1);
    }
  };

  onSelect = (checked, id) => {
    const { consents } = this.state;
    const selectedConsent = { ...consents[id] };
    selectedConsent.selected = checked;

    consents[id] = selectedConsent;

    this.setState({ consents });
  };

  onSelectAll = () =>
    this.setState(prevState => ({
      allSelected: !prevState.allSelected,
      consents: Object.keys(prevState.consents).reduce(
        (mem, id) => ({
          ...mem,
          [id]: { ...prevState.consents[id], selected: !prevState.allSelected },
        }),
        {},
      ),
    }));

  getPageOfConsents = async (page) => {
    const { sortBy, sortMode } = this.props;
    const { searchValue, type, validity } = this.state;

    let identifier = searchValue;

    if (searchValue === undefined || searchValue === null || searchValue === '') {
      identifier = 'placeholder_identifier';
    }

    const data = await this.props.getConsents(
      page,
      type.value,
      validity.value,
      {
        [sortBy]: sortMode.toLowerCase(),
      },
      identifier
    );

    this.loadData(data);
  };

  loadData = ({ consents, meta: { currentPage, totalPages } }) => {
    const consentsObj = consents.reduce(
      (memo, consent) => ({ ...memo, [consent.id]: { selected: false, consent } }),
      {},
    );

    this.setState({ consents: consentsObj, currentPage, totalPages, isLoading: false });
  };

  handleSearcherChange = value => {
    const { currentPage } = this.state;
    this.setState({ searchValue: value }, () => {
      if (this.debounceHandler) {
        clearTimeout(this.debounceHandler);
      }
      this.debounceHandler = setTimeout(() => {
        this.getPageOfConsents(currentPage);
      }, 500);
    });
  };

  handleValidityOptionChange = validity => {
    this.setState({ currentPage: 1, validity }, () => {
      const { currentPage } = this.state;
      this.getPageOfConsents(currentPage);
    });
  };

  handleTypeOptionChange = type => {
    this.setState({ currentPage: 1, type }, () => {
      const { currentPage } = this.state;
      this.getPageOfConsents(currentPage);
    });
  };

  handleSearcherClear = () => {
    this.setState({ currentPage: 1, searchValue: '' }, () => {
      const { currentPage } = this.state;
      this.getPageOfConsents(currentPage);
    });
  };

  getSelected = consents => map(filter(consents, e => e.selected), e => e.consent);

  revokeSelected = () => {
    const { currentPage, consents } = this.state;
    const selected = this.getSelected(consents);
    const { t, openToast } = this.props;

    const consentsToRevokePromises = selected.map(
      c => (c.status ? revokeConsent(c.id) : Promise.reject()),
    );

    new Promise(resolve => {
      let total = 0;
      let successful = 0;
      const increment = () => {
        total += 1;

        if (total === consentsToRevokePromises.length) {
          resolve(successful);
        }
      };

      consentsToRevokePromises.forEach(P => {
        P.then(() => {
          successful += 1;
          increment();
        }).catch(increment);
      });
    }).then(revokesSuccessful => {
      if (consentsToRevokePromises.length > 0 && revokesSuccessful === 0) {
        openToast({
          message: t('shared.consent_already_revoked_toast', {
            count: consentsToRevokePromises.length,
          }),
          type: 'danger',
        });
      } else {
        openToast({
          message: t('consents_page.toasts.consents_revoked', { count: revokesSuccessful }),
          type: 'danger',
        });
      }

      this.getPageOfConsents(currentPage);
      this.toggleRevokeModal();
    });
  };

 
  toggleRevokeModal = () => this.setState(prevState => ({ modal: !prevState.modal }));

  render() {
    const {
      onPrevClick,
      onNextClick,
      revokeSelected,
      onSelectAll,
      onSelect,
      toggleRevokeModal,
      getSelected,
    } = this;
    const {
      consents,
      allSelected,
      modal,
      isLoading,
      totalPages,
      currentPage,
      type,
      validity,
      searchValue,
    } = this.state;

    const { t, changeSort, sortBy, sortMode, fromConsentDeclaration } = this.props;
    const selectedConsents = getSelected(consents);
    const selectedAmount = selectedConsents.length;
    const revokeModalTitle = t('consents_page.confirm_revoking_modal.title', {
      count: selectedAmount,
    });

    const revokeModalContent = t('shared.revoke_all_consents_modal.content');

    return (
      <main className={styles.consentsPage}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Title title={t('shared.consents')}>
              {!isEmpty(selectedConsents) && (
                <SelectedConsentsInfo
                  className={styles.revokeWrap}
                  selected={selectedConsents}
                  onRevoke={toggleRevokeModal}
                />
              )}
            </Title>
            <ConsentSearcher
              onChange={this.handleSearcherChange}
              onClear={this.handleSearcherClear}
              currentValue={searchValue}
              label={t('consents_page.consents_searcher.label')}
              placeholder={t('consents_page.consents_searcher.placeholder')}
              type={type}
              onChangeType={this.handleTypeOptionChange}
              validity={validity}
              onChangeValidity={this.handleValidityOptionChange}
            />

            <section
              className={cn(styles.content, {
                [styles.centered]: isEmpty(consents),
              })}
            >
              {isEmpty(consents) ? (
                <NoConsents fromConsentDeclaration={fromConsentDeclaration} />
              ) : (
                <Fragment>
                  <ConsentsList
                    {...{ consents, allSelected, onSelectAll, onSelect }}
                    changeSort={changeSort}
                    sortBy={sortBy}
                    sortMode={sortMode}
                  />

                  {!isEmpty(selectedConsents) && (
                    <SelectedConsentsInfo
                      className={styles.revokeMargin}
                      selected={selectedConsents}
                      onRevoke={toggleRevokeModal}
                    />
                  )}

                  <Pagination
                    onPrevClick={onPrevClick}
                    onNextClick={onNextClick}
                    totalPages={totalPages}
                    currentPage={currentPage}
                  />
                </Fragment>
              )}
            </section>

            <WarningModal
              title={revokeModalTitle}
              open={modal}
              onClose={toggleRevokeModal}
              onAccept={revokeSelected}
              acceptLabel={t('shared.revoke')}
            >
              {revokeModalContent}
            </WarningModal>
          </Fragment>
        )}
      </main>
    );
  }
}

export default withNamespaces()(
  withToast(
    withSort({
      sortBy: 'date',
      sortMode: 'desc',
      reversedDefaults: ['date'],
    })(ConsentsPage),
  ),
);

import React from 'react';
import source from 'images/how_first.svg';

import Icon from './Icon';

const HowFirst = ({ ...props }) => (
  <Icon alt="How first" src={source} width="127" height="132" {...props} />
);

export default HowFirst;

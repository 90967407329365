import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withNamespaces } from 'react-i18next';

import Columns from 'src/components/shared/Columns/Columns';
import InputField from 'src/components/shared/InputField/InputField';
import TextareaField from 'src/components/shared/TextareaField/TextareaField';
import { isEmail, isPhone } from 'src/utils/validators';

import styles from './ProcessorFormFields.module.scss';

const ProcessorFormFields = ({ t }) => (
  <Fragment>
    <Columns>
      {{
        left: <InputField label={t('processor_form_fields.processor_name')} name="name" required />,
      }}
    </Columns>

    <Columns className={styles.row}>
      {{
        left: (
          <InputField label={t('processor_form_fields.company_name')} name="companyName" required />
        ),
        right: <InputField label={t('shared.contact_person')} name="contactName" required />,
      }}
    </Columns>

    <Columns className={styles.row}>
      {{
        left: (
          <InputField
            label={t('processor_form_fields.contact_email')}
            name="contactEmail"
            validators={[isEmail]}
            required
          />
        ),
        right: (
          <InputField
            label={t('shared.contact_phone')}
            name="contactPhone"
            validators={[isPhone]}
            required
          />
        ),
      }}
    </Columns>

    <TextareaField
      className={styles.row}
      label={t('processor_form_fields.details')}
      name="additionalDetails"
    />
  </Fragment>
);

ProcessorFormFields.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(ProcessorFormFields);

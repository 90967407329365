import React from 'react';

import { AuthConsumer } from 'src/components/AuthContext/AuthContext';

const withSession = Component => {
  const ComponentWithSession = props => (
    <AuthConsumer>{auth => <Component auth={auth} {...props} />}</AuthConsumer>
  );

  return ComponentWithSession;
};

export default withSession;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import { withNamespaces } from 'react-i18next';

import { getRoles } from 'src/api/roles';
import Columns from 'src/components/shared/Columns/Columns';
import SelectField from 'src/components/shared/SelectField/SelectField';
import InputField from 'src/components/shared/InputField/InputField';
import { isEmail } from 'src/utils/validators';

import styles from './InviteUserForm.module.scss';

class InviteUserForm extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    roles: [],
  };

  componentDidMount() {
    getRoles().then(roles => this.setState({ roles }));
  }

  render() {
    const { children, onSubmit, t } = this.props;
    const { roles } = this.state;

    const translatedRoles = roles.map(({ value }) => ({
      value,
      label: t(`shared.roles.${value}`),
    }));

    return (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form className={styles.userForm} onSubmit={handleSubmit}>
            <div className={styles.formContent}>
              <Columns>
                {{
                  left: <InputField label={t('shared.full_name')} name="full_name" required />,
                  right: (
                    <InputField
                      label={t('shared.email')}
                      name="email"
                      validators={[isEmail]}
                      required
                    />
                  ),
                }}
              </Columns>

              <Columns className={styles.withTopGap}>
                {{
                  left: (
                    <SelectField
                      label={t('shared.role')}
                      name="role"
                      options={translatedRoles}
                      showSelected
                      required
                      placeholder={t('users_page.invite_user_modal.role_placeholder')}
                    />
                  ),
                }}
              </Columns>
            </div>
            {children}
          </form>
        )}
      />
    );
  }
}

export default withNamespaces()(InviteUserForm);

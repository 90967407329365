import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { toCamelCase, toSnakeCase } from 'src/utils/caseMappers';
import stringify from 'src/utils/stringify';

import i18next from 'src/utils/i18n';
import apiRequest from './apiRequest';
import pagingParams from './pagingParams';

import EP from './endpoints';

/* eslint-disable camelcase */
const formatDeclaration = ({
  title,
  body,
  purpose,
  processing,
  validity,
  validityUnit: validity_unit,
  withdrawDetails: withdraw_details,
  processor,
  purposeTitle,
  test,
  contractValidTitle,
  processingOfDataTitle,
  processorTitle,
  withdrawalTitle,
  companyDetailsTitle,
  validityIndefiniteTitle,
  processorDescription,
  processorRecordIds = [],
  invalidate = false,
  active,
  required,
  version,
}) => ({
  data: {
    type: 'contract_declaration',
    attributes: {
      title,
      body,
      purpose,
      active: active || false,
      required: required || false,
      version: version || '',
      processor_record_ids: processorRecordIds || [],
      processing,
      ...(processor && { processor_id: processor.id }),
      ...(validity &&
        validity_unit && {
          validity,
          validity_unit,
        }),
      withdraw_details,
      invalidate,
      test: test,
      purpose_title: purposeTitle,
      contract_valid_title:
        contractValidTitle || i18next.t('contract_declaration.contract_valid_title'),
      processing_of_data_title:
        processingOfDataTitle || i18next.t('contract_declaration.processing_of_data_title'),
      processor_title: processorTitle || i18next.t('contract_declaration.processor_title'),
      withdrawal_title: withdrawalTitle || i18next.t('contract_declaration.withdrawal_title'),
      company_details_title:
        companyDetailsTitle || i18next.t('contract_declaration.company_details_title'),
      processor_description:
        processorDescription || i18next.t('contract_declaration.processor_description'),
      validity_indefinite_title: validityIndefiniteTitle || i18next.t('shared.indefinite'),
    },
  },
});

const parseEvent = ({ data, event_type, metadata }) => {
  const { contractDeclarationId, declarationVersion } = toCamelCase(data);
  const event = toCamelCase(metadata);

  return {
    ...omit(event, ['updatedAt']),
    event: event_type.replace('Event::', ''),
    declarationId: contractDeclarationId,
    timestamp: event.timestamp,
    version: declarationVersion,
  };
};

const handleIncluded = (declaration, included) => {
  const { processorId, organizationId } = declaration;
  const reformatIncluded = type =>
    included
      .filter(obj => obj.type === type)
      .reduce((memo, obj) => ({ ...memo, [obj.id]: obj.attributes }), {});
  const processors = reformatIncluded('processor');
  const organizations = reformatIncluded('organization');

  const processorObj = processorId
    ? { processor: { ...processors[processorId], id: processorId } }
    : {};
  const organizationObj = organizationId ? { organization: organizations[organizationId] } : {};

  return {
    ...declaration,
    ...processorObj,
    ...organizationObj,
  };
};

export const parseDeclaration = ({ id, attributes, relationships }, included = null) => {
  const processorId = get(relationships, 'processor.data.id', null);
  const organizationId = get(relationships, 'organization.data.id', null);
  const events = get(attributes, 'events', []).map(parseEvent);

  const declaration = {
    ...attributes,
    id,
    processorId,
    organizationId,
    events,
  };

  if (!isEmpty(included)) {
    return toCamelCase(handleIncluded(declaration, included));
  }
  return toCamelCase(declaration);
};

export const getDeclarations = (page, type, sort) =>
  apiRequest(EP.contractDeclarations, {
    query: {
      ...pagingParams(page),
      type: type,
      order: stringify(toSnakeCase(sort)),
    },
  }).then(({ data, meta }) => ({
    declarations: data.map(datum => parseDeclaration(datum)),
    meta: toCamelCase(meta),
  }));

export const createDeclaration = declaration =>
  apiRequest(EP.contractDeclarations, {
    body: formatDeclaration(declaration),
  });

export const showDeclaration = id =>
  apiRequest(`${EP.contractDeclarations}/${id}`).then(({ data, included }) =>
    parseDeclaration(data, included),
  );

export const createNewDeclarationVersion = declaration =>
  apiRequest(`${EP.contractDeclarations}/${declaration.id}`, {
    method: 'PATCH',
    body: formatDeclaration(declaration),
  });

export const duplicateDeclaration = declaration =>
  apiRequest(`${EP.contractDeclarations}/${declaration.id}/duplicate`, {
    method: 'POST',
    body: formatDeclaration(declaration),
  });

export const revokeLinkedContracts = id =>
  apiRequest(`${EP.contractDeclarations}/${id}/revoke_linked_contracts`, {
    method: 'POST',
    body: {
      data: {
        type: 'contract_declaration',
        attributes: {
          reason: 'admin',
        },
      },
    },
  });

export const getDeclarationWithWidgetToken = ({ id, widgetToken, widgetId }) =>
  apiRequest(
    `${EP.contractDeclarationWithToken}/${id}/?widget_token=${widgetToken}&widget_id=${widgetId}`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    },
  ).then(({ data, included }) => parseDeclaration(data, included));

export const getContractDeclarationContractsCSV = ({ id }) =>
  apiRequest(`${EP.contractDeclarations}/${id}/contracts/index_csv`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  });

import React from 'react';
import WithSort from './WithSort/WithSort';

const withSort = ({ reversedDefaults, ...initialValues }) => Component => props => (
  <WithSort initialValues={initialValues} reversedDefaults={reversedDefaults}>
    {withSortProps => <Component {...withSortProps} {...props} />}
  </WithSort>
);

export default withSort;

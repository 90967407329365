import React from 'react';
import PropTypes from 'prop-types';
import SearchField from 'src/components/shared/SearchField/SearchField';
import cn from 'classnames';
import SelectField from 'src/components/shared/SelectField/SelectField';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import i18next from 'src/utils/i18n';
import styles from './ContractSearcher.module.scss';

const ContractSearcher = ({ onChange, onClear, currentValue, label, placeholder, onChangeType, type }) => {
  const handleInputChange = value => {
    if (value) {
      onChange(value);
    } else {
      onClear();
    }
  };

  const handleChangeType = (newType, changeCb) => {
    onChangeType(newType, changeCb)
  }

  const typeOptions = [{value: "all", label: i18next.t("shared.type.all") }, {value: "standard", label: i18next.t("shared.type.standard") }, {value: "test", label: i18next.t("shared.type.test")}]

  return (
    <div className={cn(styles.searchWrapper)} data-testid="contract-searcher">
      <section className={styles.searchSection}>
        <SearchField
          label={label}
          name="ssn"
          onChange={handleInputChange}
          currentValue={currentValue}
          placeholder={placeholder}
        />
      </section>

      <section className={styles.typeSection}>
        <Form onSubmit={()=>{}} initialValues={{ type: type }}>
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <SelectField
                label={i18next.t('shared.sandbox_header')}
                name="type"
                options={typeOptions}
                showSelected
                required=""
                onChange={handleChangeType}
                hideCheckmark
              />
            </form>
          )}
        </Form>
      </section>
    </div>
  );
};

ContractSearcher.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

ContractSearcher.defaultProps = {
  currentValue: '',
  label: 'Search',
  placeholder: '',
};

export default ContractSearcher;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import urls from 'src/constants/urls';
import withSession from 'src/components/AuthContext/withSession/withSession';
import { authPropTypes } from 'src/prop_types/auth_prop_types';

const PrivateRoute = ({ component: Component, auth: { isAuthenticated }, ...rest }) => (
  <Route
    {...rest}
    render={props => (isAuthenticated ? <Component {...props} /> : <Redirect to={urls.signIn()} />)}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  auth: authPropTypes.auth.isRequired,
};

export default withSession(PrivateRoute);

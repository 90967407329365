import React from 'react';
import { string, node } from 'prop-types';

import styles from './HowWorksStep.module.scss';

const HowWorksStep = ({ children, stepNumber, description }) => (
  <div className={styles.wrapper}>
    <div className={styles.image}>{children}</div>
    <div className={styles.stepNumberWrapper}>
      <div className={styles.stepNumber}>{stepNumber}</div>
    </div>
    <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
  </div>
);

HowWorksStep.propTypes = {
  stepNumber: string.isRequired,
  description: string.isRequired,
  children: node.isRequired,
};

export default HowWorksStep;

import urls from 'src/constants/urls';
import adminUrls from 'src/constants/admin_urls';

export const topbarUrls = [
  {
    text: 'shared.consent_declarations',
    to: urls.declarations(),
  },
  {
    text: 'shared.consents',
    to: urls.consents(), 
  },
  {
    text: 'shared.contract_declarations',
    to: urls.contractDeclarations(),
  },
  {
    text: 'shared.contracts',
    to: urls.contracts(),
  },
  {
    text: 'shared.manage_users',
    to: urls.users(),
  },
  {
    text: 'shared.processors',
    to: urls.processors(),
  },
];

export const topbarAdminUrls = [
  {
    text: 'shared.manage_users',
    to: adminUrls.users(),
  },
  {
    text: 'shared.organizations',
    to: adminUrls.organizations(),
  },
];

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cb from 'clipboard-polyfill';
import cn from 'classnames';
import { withNamespaces } from 'react-i18next';
import Trans from 'src/components/shared/Trans/Trans';

import Button from 'src/components/shared/Button/Button';

import styles from './IdPreview.module.scss';

class IdPreview extends Component {
  state = {
    isClicked: false,
  };

  onClicked = () => {
    const { children } = this.props;
    cb.writeText(children);
    this.setState({
      isClicked: true,
    });
  };

  disableClicked = () => {
    this.setState({
      isClicked: false,
    });
  };

  render() {
    const { isClicked } = this.state;
    const { children } = this.props;
    return (
      <Button
        kind="outline"
        className={cn(styles.idPreview, {
          [styles['id-preview--clicked']]: isClicked,
        })}
        onClick={this.onClicked}
        onAnimationEnd={this.disableClicked}
        type="button"
      >
        <span className={styles.normal}>{children}</span>
        <span className={styles.hover}>
          <Trans noMd>id_preview.hover_label</Trans>
        </span>
        <span className={styles.clicked}>
          <Trans noMd>id_preview.clicked_label</Trans>
        </span>
      </Button>
    );
  }
}

IdPreview.propTypes = {
  children: PropTypes.string.isRequired,
};

export default withNamespaces()(IdPreview);

import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const Alert = ({ text }) => (
  <div className={styles.alert} dangerouslySetInnerHTML={{ __html: text }} />
);

Alert.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Alert;

import omit from 'lodash/omit';

import { cardsPageSize } from 'src/constants/page_sizes';
import { toCamelCase, toSnakeCase } from 'src/utils/caseMappers';

import apiRequest from './apiRequest';
import pagingParams from './pagingParams';

import EP from './endpoints';

export const parseUser = ({ id, attributes }) => ({
  id,
  ...omit(toCamelCase(attributes), ['fullName']),
  name: attributes.full_name,
});

export const formatUserInvite = user => ({
  data: {
    type: 'invitation',
    attributes: toSnakeCase(user),
  },
});

const formatUserActivation = ({ password, token, terms, privacyPolicy }) => ({
  data: {
    type: 'invitation',
    attributes: {
      password,
      token,
      terms,
      privacy_policy: privacyPolicy,
    },
  },
});

export const createUser = user =>
  apiRequest(EP.invitations, {
    body: formatUserInvite(user),
  });

export const getUsers = page =>
  apiRequest(EP.users, {
    query: pagingParams(page, cardsPageSize),
  }).then(({ data, meta }) => ({ users: data.map(parseUser), meta: toCamelCase(meta) }));

export const showUser = id => apiRequest(`${EP.users}/${id}`).then(({ data }) => parseUser(data));

export const deleteUser = id =>
  apiRequest(`${EP.users}/${id}`, {
    method: 'DELETE',
  });

const deactivateBody = {
  data: {
    type: 'user',
    attributes: {
      status: 'deactivated',
    },
  },
};

export const deactivateUser = id =>
  apiRequest(`${EP.users}/${id}`, {
    method: 'PATCH',
    body: deactivateBody,
  });

export const activateUser = credentials =>
  apiRequest(`${EP.invitations}/accept`, {
    body: formatUserActivation(credentials),
  });

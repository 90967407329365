import React from 'react';
import Trans from 'src/components/shared/Trans/Trans';

import NoProcessorsImage from 'src/components/image/NoProcessorsImage';

import styles from './NoProcessors.module.scss';

const NoProcessors = () => (
  <div className={styles.noProcessors}>
    <NoProcessorsImage />
    <div>
      <Trans>processors_page.no_processors.description</Trans>
    </div>
  </div>
);

export default NoProcessors;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Columns from 'src/components/shared/Columns/Columns';
import Modal from 'src/components/shared/Modal/Modal';

import { webhookPropTypes } from 'src/prop_types/processor_prop_types';

import WebhookForm from './WebhookForm/WebhookForm';
import WebhooksList from './WebhooksList/WebhooksList';

import styles from './WebhooksModal.module.scss';

class WebhooksModal extends Component {
  state = {
    currentlyEdited: '',
  };

  static propTypes = {
    t: PropTypes.func.isRequired,
    saveWebhook: PropTypes.func.isRequired,
    deleteWebhook: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    webhooks: PropTypes.arrayOf(webhookPropTypes).isRequired,
  };

  startEditing = id => () => {
    this.setState({
      currentlyEdited: id,
    });
  };

  getCurrentlyEditedWebhook = () => {
    const { webhooks } = this.props;
    const { currentlyEdited } = this.state;

    return webhooks.find(({ id }) => id === currentlyEdited);
  };

  saveWebhook = webhook => {
    const { saveWebhook } = this.props;

    saveWebhook(webhook);

    this.setState({
      currentlyEdited: '',
    });
  };

  render() {
    const { t, open, onClose, webhooks, deleteWebhook } = this.props;
    const { currentlyEdited } = this.state;
    const { startEditing, getCurrentlyEditedWebhook, saveWebhook } = this;

    return (
      <Modal
        open={open}
        onClose={onClose}
        title={t('processor_details_page.webhooks_modal.title')}
        className={styles.processorModal}
      >
        <Columns>
          {{
            left: (
              <div>
                <h3 className={styles.header}>
                  {t('processor_details_page.webhooks_modal.create_webhook_title')}
                </h3>
                <WebhookForm
                  onSubmit={saveWebhook}
                  currentlyEditedWebhook={getCurrentlyEditedWebhook()}
                />
              </div>
            ),
            right: (
              <div className={styles.webhooksContainer}>
                <h3 className={styles.header}>
                  {t('processor_details_page.webhooks_modal.webhooks_title')}
                </h3>
                <WebhooksList
                  webhooks={webhooks}
                  currentlyEdited={currentlyEdited}
                  startEditing={startEditing}
                  deleteWebhook={deleteWebhook}
                />
              </div>
            ),
          }}
        </Columns>
      </Modal>
    );
  }
}

export default withNamespaces()(WebhooksModal);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { parse } from 'query-string';

import urls from 'src/constants/urls';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import withToast from 'src/components/Toast/withToast/withToast';
import Trans from 'src/components/shared/Trans/Trans';
import Button from 'src/components/shared/Button/Button';
import OrganizationFields from 'src/components/shared/OrganizationFields/OrganizationFields';
import FooterLanguagePicker from 'src/components/shared/FooterLanguagePicker/FooterLanguagePicker';
import { confirmOrganization } from 'src/api/organization';

import styles from './ConfirmRegistrationPage.module.scss';

class ConfirmInvitationPage extends PureComponent {
  static propTypes = {
    location: ReactRouterPropTypes.location.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    openToast: PropTypes.func.isRequired,
  };

  onSubmit = organization => {
    const { t, openToast, history } = this.props;
    const { token } = this.getQueryParams();

    confirmOrganization({
      ...organization,
      confirmation_token: token,
    })
      .then(() => {
        openToast({
          message: t('confirm_registration.toasts.account_created'),
        });

        history.push(urls.dashboard());
      })
      .catch(() => {
        openToast({
          message: t('confirm_registration.errors.general'),
          type: 'danger',
        });
      });
  };

  getQueryParams = () => {
    const {
      location: { search: queryString },
    } = this.props;
    return parse(queryString);
  };

  render() {
    const { t } = this.props;
    const { onSubmit } = this;
    const { token, organization: organizationString } = this.getQueryParams();
    let organization;

    if (!token || !organizationString) {
      return <Redirect to="/" />;
    }

    try {
      organization = JSON.parse(atob(decodeURIComponent(organizationString)));
    } catch (e) {
      return <Redirect to="/" />;
    }

    return (
      <main className={styles.page}>
        <section className={styles.content}>
          <div className={styles.contentBox}>
            <Trans
              className={styles.text}
              renderers={{
                heading: ({ children }) => <h3 className={styles.title}>{children}</h3>,
              }}
            >
              confirm_registration.one_last_step
            </Trans>
            <Form onSubmit={onSubmit} initialValues={organization}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <h3 className={styles.title}>{t('profile_page.organization.title')}</h3>

                  <OrganizationFields />

                  <Button kind="primary" type="submit" className={styles.submitButton}>
                    {t('confirm_registration.set_details')}
                  </Button>
                </form>
              )}
            </Form>
          </div>
        </section>
        <FooterLanguagePicker />
      </main>
    );
  }
}

export default withRouter(withNamespaces()(withToast(ConfirmInvitationPage)));

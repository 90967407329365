import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Status.module.scss';

const Status = ({ type, message }) => (
  <span className={cn(styles.status, styles[type])}>{message}</span>
);

Status.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Status;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import { Field } from 'react-final-form';
import { withNamespaces } from 'react-i18next';

import Trans from 'src/components/shared/Trans/Trans';

import { isEmail } from 'src/utils/validators';
import withSession from 'src/components/AuthContext/withSession/withSession';
import Button from 'src/components/shared/Button/Button';
import InputField from 'src/components/shared/InputField/InputField';
import PasswordField from 'src/components/shared/PasswordField/PasswordField';
import LandingLayout from 'src/components/shared/LandingLayout/LandingLayout';
import urls from 'src/constants/urls';

import { authPropTypes } from 'src/prop_types/auth_prop_types';

import styles from './SignIn.module.scss';

class SignIn extends Component {
  static propTypes = {
    auth: authPropTypes.auth.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
    };
  }

  onSubmit = credentials =>
    new Promise(resolve => {
      const {
        auth: { handleLogin },
        t,
      } = this.props;

      handleLogin(credentials)
        .then(data => {
          this.setState({ isAuthenticated: data.isAuthenticated });
          resolve();
        })
        .catch(({ status }) => {
          if (status === 422) {
            resolve({
              auth_error: t('landing_page.sign_in.errors.invalid_password'),
            });
          }
        });
    });

  render() {
    const { t } = this.props;
    const { isAuthenticated } = this.state;

    const { onSubmit } = this;

    if (isAuthenticated) {
      return <Redirect to={urls.dashboard()} />;
    }

    return (
      <main className={styles.page}>
        <section className={styles.content}>
          <LandingLayout
            left={
              <div>
                <h3 className={styles.title}>
                  <Trans>landing_page.sign_in.title</Trans>
                </h3>
                <h4 className={styles.subtitle}>
                  <Trans>landing_page.sign_in.subtitle</Trans>
                </h4>

                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <InputField
                        label={t('shared.your_email')}
                        name="email"
                        type="email"
                        className={styles.formField}
                        validators={[isEmail]}
                        required
                      />

                      <PasswordField
                        label={t('shared.password')}
                        name="password"
                        className={styles.formField}
                        required
                      />

                      <Link to="/reset_password" className={styles.forgotLink}>
                        <Trans>landing_page.sign_in.forgot_password_link</Trans>
                      </Link>

                      <Button kind="primary" type="submit" className={styles.submitButton}>
                        <Trans>landing_page.sign_in_button</Trans>
                      </Button>

                      <Field name="auth_error">
                        {({ meta: { submitError } }) =>
                          // note that we have to use ternary operator here since Field expects the child to return component or null
                          submitError ? <div className={styles.error}>{submitError}</div> : null
                        }
                      </Field>
                    </form>
                  )}
                />
              </div>
            }
          />
        </section>
      </main>
    );
  }
}

export default withSession(withNamespaces()(SignIn));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import CloseIcon from 'src/components/image/CloseIcon';

import styles from './ToastElement.module.scss';

class ToastElement extends Component {
  state = {
    stickToTop: window.scrollY > 60,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 60) {
      return this.setState({ stickToTop: true });
    }

    return this.setState({ stickToTop: false });
  };

  render() {
    const { onClick, message, type, inModal } = this.props;
    const { stickToTop } = this.state;

    return (
      <button
        className={cn(
          styles.toast,
          { [styles['toast--stick-to-top']]: stickToTop || inModal },
          styles[type],
        )}
        onClick={onClick}
        type="button"
      >
        <span className={styles.message}>{message}</span>
        <CloseIcon className={styles.close} />
      </button>
    );
  }
}

ToastElement.defaultProps = {
  message: '',
  type: 'info',
  onClick: null,
  inModal: false,
};

ToastElement.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['info', 'danger']),
  onClick: PropTypes.func,
  inModal: PropTypes.bool,
};

export default ToastElement;

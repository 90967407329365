import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './CaptionedInfo.module.scss';

const CaptionedInfo = ({ title, children, underlined, className, infoProps }) => (
  <div className={cn(styles.captions, className)}>
    <span className={cn(styles.title, { [styles.underline]: underlined })}>{title}</span>
    <span
      className={cn(styles.info, {
        [styles.empty]: !children,
      })}
      {...infoProps}
    >
      {children}
    </span>
  </div>
);

CaptionedInfo.defaultProps = {
  children: null,
  underlined: false,
  className: '',
  infoProps: {},
};

CaptionedInfo.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  underlined: PropTypes.bool,
  className: PropTypes.string,
  infoProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default CaptionedInfo;

import React from 'react';
import source from 'images/hide_password.svg';

import Icon from './Icon';

const HidePasswordIcon = ({ ...props }) => (
  <Icon alt="hide password icon" src={source} {...props} />
);

export default HidePasswordIcon;

import React from 'react';
import PropTypes from 'prop-types';

import styles from './Title.module.scss';

const Title = ({ title, children, ...props }) => (
  <section className={styles.header}>
    <h1 className={styles.title} {...props}>
      {title}
    </h1>

    {children}
  </section>
);

Title.defaultProps = {
  children: null,
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Title;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withNamespaces } from 'react-i18next';
import cn from 'classnames';

import Trans from 'src/components/shared/Trans/Trans';

import NavButton from 'src/components/shared/NavButton/NavButton';
import FormHint from 'src/components/shared/FormHint/FormHint';
import Title from 'src/components/shared/Title/Title';
import urls from 'src/constants/urls';

import ProfilePageRouter from './ProfilePageRouter/ProfilePageRouter';

import styles from './ProfilePage.module.scss';

class ProfilePage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  state = {
    hintOpen: false,
  };

  toggleHint = open => this.setState({ hintOpen: open });

  render() {
    const { toggleHint } = this;
    const { hintOpen } = this.state;
    const { t } = this.props;

    return (
      <main className={styles.page}>
        <Title title={t('shared.settings')} />

        <section className={styles.content}>
          <nav className={styles.profileNav}>
            <NavButton className={styles.nav} activeClassName={styles.current} to={urls.settings()}>
              <Trans>profile_page.nav.profile_button</Trans>
            </NavButton>

            <NavButton
              className={cn(styles.nav, styles.navPosition)}
              activeClassName={styles.current}
              to={urls.organizations()}
            >
              <Trans>profile_page.nav.organization_button</Trans>
            </NavButton>

            <NavButton
              className={styles.nav}
              activeClassName={styles.current}
              to={urls.personalDataCategories()}
            >
              <Trans>profile_page.nav.preferences_button</Trans>
            </NavButton>
          </nav>
          <div className={styles.profileContent}>
            <ProfilePageRouter toggleHint={toggleHint} />
          </div>

          {hintOpen && (
            <FormHint
              className={styles.hint}
              title={t('shared.personal_data_categories')}
              onClose={() => toggleHint(false)}
            >
              <Trans
                renderers={{
                  strong: ({ children, ...props }) => (
                    <span {...props} className={styles.bold}>
                      {children}
                    </span>
                  ),
                }}
              >
                profile_page.personal_data_categories.hint
              </Trans>
            </FormHint>
          )}
        </section>
      </main>
    );
  }
}

export default withNamespaces()(ProfilePage);

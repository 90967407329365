import React from 'react';
import source from 'images/how_second.svg';

import Icon from './Icon';

const HowForth = ({ ...props }) => (
  <Icon alt="How forth" src={source} width="135" height="122" {...props} />
);

export default HowForth;

import React, { Component, Fragment } from 'react';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import { withNamespaces } from 'react-i18next';

import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import withToast from 'src/components/Toast/withToast/withToast';
import toastPropTypes from 'src/prop_types/toastPropTypes';

import { Link, withRouter } from 'react-router-dom';

import Trans from 'src/components/shared/Trans/Trans';

import { isEmail, isOnlyLatinChars, maxLength } from 'src/utils/validators';

import { createOrganization } from 'src/api/organization';

import urls from 'src/constants/urls';

import Button from 'src/components/shared/Button/Button';
import InputField from 'src/components/shared/InputField/InputField';
import AcceptanceBox from 'src/components/shared/AcceptanceBox/AcceptanceBox';
import PasswordField from 'src/components/shared/PasswordField/PasswordField';
import SelectField from 'src/components/shared/SelectField/SelectField';

import LandingLayout from 'src/components/shared/LandingLayout/LandingLayout';
import TermsAndConditions from 'src/components/shared/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from 'src/components/shared/PrivacyPolicy/PrivacyPolicy';

import styles from '../SignIn/SignIn.module.scss';

const Right = ({ state }) => {
  switch (state) {
    case 'tac': {
      return <TermsAndConditions foldable />;
    }
    case 'pp': {
      return <PrivacyPolicy foldable />;
    }
    default: {
      return null;
    }
  }
};

class SignUp extends Component {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    openToast: toastPropTypes.openToast.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    right: null,
  };

  onTaCClick = e => {
    e.preventDefault();

    this.setState({
      right: 'tac',
    });
  };

  onPPClick = e => {
    e.preventDefault();

    this.setState({
      right: 'pp',
    });
  };

  handleSignUp = organization => {
    const { history, openToast, t } = this.props;
    const data = {
      ...organization,
      language: organization.language.value,
    };
    return new Promise(resolve => {
      createOrganization(data)
        .then(() => {
          history.push(urls.signIn());
          openToast({ message: t('landing_page.sign_up.toasts.confirmation_sent') });

          return resolve();
        })
        .catch(({ status }) => {
          if (status === 422) {
            return resolve({ organization_name: t('shared.error_organization_name_taken') });
          }

          return undefined;
        });
    });
  };

  render() {
    const { handleSignUp, onTaCClick, onPPClick } = this;
    const { t } = this.props;
    const { right } = this.state;

    const { i18n } = this.props;

    const availableLanguages = Object.keys(i18n.store.data);
    const availableLanguagesOptions = availableLanguages.map(lng => ({
      value: lng,
      label: t(`shared.languages.${lng}`),
    }));

    const currentLanguageCodes = i18n.languages;
    const currentLanguageIndex = currentLanguageCodes.reduce((prev, curr) => {
      if (prev === -1) {
        return availableLanguagesOptions.findIndex(({ value }) => value === curr);
      }

      return prev;
    }, -1);

    const currentLanguage = availableLanguagesOptions[currentLanguageIndex];

    return (
      <main className={styles.page}>
        <section className={styles.content}>
          <LandingLayout
            right={right && <Right state={right} />}
            left={
              <div>
                <h3 className={styles.title}>
                  <Trans>landing_page.sign_up.title</Trans>
                </h3>
                <h4 className={styles.subtitle}>
                  <Trans>landing_page.sign_up.subtitle</Trans>
                </h4>

                <Form
                  onSubmit={handleSignUp}
                  initialValues={{ language: currentLanguage }}
                  render={({ handleSubmit }) => (
                    <Fragment>
                      <form onSubmit={handleSubmit}>
                        <InputField
                          label={t('shared.organization_name')}
                          name="organization_name"
                          type="text"
                          className={styles.formField}
                          validators={[isOnlyLatinChars, maxLength(60)]}
                          required
                        />
                        <InputField
                          label={t('shared.full_name')}
                          name="full_name"
                          type="text"
                          className={styles.formField}
                          required
                        />
                        <InputField
                          label={t('shared.your_email')}
                          name="email"
                          type="email"
                          className={styles.formField}
                          validators={[isEmail]}
                          required
                        />
                        <PasswordField
                          label={t('shared.password')}
                          name="password"
                          className={styles.formField}
                          required
                        />
                        <SelectField
                          className={styles.formField}
                          label={t('profile_page.your_profile.form.preferred_language')}
                          name="language"
                          options={availableLanguagesOptions}
                          showSelected
                          placeholder=""
                        />
                        <AcceptanceBox required name="terms">
                          <div className={styles.acceptanceBoxDesktop}>
                            <Trans
                              vars={{ terms_and_conditions_href: urls.termsAndConditions() }}
                              renderers={{
                                link: ({ href, children: linkChildren }) => (
                                  <Link to={href} onClick={onTaCClick}>
                                    {linkChildren}
                                  </Link>
                                ),
                              }}
                            >
                              shared.tac_agreement
                            </Trans>
                          </div>
                          <div className={styles.acceptanceBoxMobile}>
                            <Trans vars={{ terms_and_conditions_href: urls.termsAndConditions() }}>
                              shared.tac_agreement
                            </Trans>
                          </div>
                        </AcceptanceBox>
                        <AcceptanceBox required name="privacy_policy">
                          <div className={styles.acceptanceBoxDesktop}>
                            <Trans
                              vars={{ privacy_policy_href: urls.privacyPolicy() }}
                              renderers={{
                                link: ({ href, children: linkChildren }) => (
                                  <Link to={href} onClick={onPPClick}>
                                    {linkChildren}
                                  </Link>
                                ),
                              }}
                            >
                              shared.pp_agreement
                            </Trans>
                          </div>
                          <div className={styles.acceptanceBoxMobile}>
                            <Trans vars={{ privacy_policy_href: urls.privacyPolicy() }}>
                              shared.pp_agreement
                            </Trans>
                          </div>
                        </AcceptanceBox>
                        <Button kind="primary" type="submit" className={styles.submitButton}>
                          {t('landing_page.sign_up_button')}
                        </Button>
                      </form>
                    </Fragment>
                  )}
                />
              </div>
            }
          />
        </section>
      </main>
    );
  }
}

export default withRouter(withNamespaces()(withToast(SignUp)));

import React from 'react';
import source from 'images/landing_section_3.svg';

import Icon from './Icon';

const LandingSectionThird = ({ ...props }) => (
  <Icon alt="Landing section third" src={source} width="360" height="467" {...props} />
);

export default LandingSectionThird;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import urls from 'src/constants/urls';
import Button from 'src/components/shared/Button/Button';

import styles from './index.module.scss';
import Alert from './Alert';

const GDPR_ACCEPT = 'Y';
const GDPR_DECLINE = 'N';

const getGdprValue = checkbox => (checkbox.current.checked ? GDPR_ACCEPT : GDPR_DECLINE);
const url =
  'https://netguru.us5.list-manage.com/subscribe/post?u=c8024d82c961bf64986ee67be&id=6e2659b1e5';

class TryTraq extends Component {
  constructor(props) {
    super(props);
    this.email = React.createRef();
    this.gdprEmail = React.createRef();

    this.state = {
      emailText: '',
      gdprChecked: false,
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.handleGdpr = this.handleGdpr.bind(this);
  }

  onSubmit(subscribe) {
    subscribe({
      EMAIL: this.email.current.value,
      'gdpr[13803]': getGdprValue(this.gdprEmail),
    });
  }

  handleEmail(event) {
    this.setState({ emailText: event.target.value });
  }

  handleGdpr() {
    const { gdprChecked } = this.state;
    this.setState({ gdprChecked: !gdprChecked });
  }

  render() {
    const { emailText, gdprChecked } = this.state;
    return (
      <div>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <div>
              {status === 'sending' && <Alert text="Sending..." />}
              {(status === 'error' || status === 'success') && <Alert text={message} />}
              <div>
                <form>
                  <div className={styles.sendForm}>
                    <input
                      type="email"
                      ref={this.email}
                      name="emailAddress"
                      placeholder="Your email address..."
                      className={styles.sendFormInput}
                      onChange={this.handleEmail}
                      value={emailText}
                    />
                  </div>
                  <div className={styles.gdpr}>
                    <p className={styles.gdprDescription}>
                      <input
                        type="checkbox"
                        id="gdpr_13803"
                        name="gdpr[13803]"
                        value={GDPR_DECLINE}
                        ref={this.gdprEmail}
                        className={styles.sendFormButton}
                        onChange={this.handleGdpr}
                      />
                      I have read <Link to={urls.declarationDetails()}>declaration details</Link>{' '}
                      and agree to have my data processed by Skalar.
                    </p>
                  </div>
                  <Button
                    kind="primary"
                    type="button"
                    className={styles.primaryAction}
                    onClick={() => this.onSubmit(subscribe)}
                    disabled={!emailText || !gdprChecked}
                  >
                    Try it out for free
                  </Button>
                </form>
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default TryTraq;

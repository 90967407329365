import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import cn from 'classnames';

import { withNamespaces } from 'react-i18next';

import { createCategory, getPersonalDataCategories } from 'src/api/personal_data_categories';
import { checkPDC } from 'src/utils/checkPDC';

import { personalDataCategoriesPropTypes } from 'src/prop_types/declaration_prop_types';

import withToast from 'src/components/Toast/withToast/withToast';
import Button from 'src/components/shared/Button/Button';
import InputField from 'src/components/shared/InputField/InputField';

import PersonalDataCategoriesField from './PersonalDataCategoriesField/PersonalDataCategoriesField';
import TagsListField from './TagsListField/TagsListField';

import styles from './PersonalDataCategoriesStep.module.scss';

class PersonalDataCategoriesStep extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    openToast: PropTypes.func.isRequired,
  };

  state = {
    categories: [],
  };

  componentDidMount() {
    this.fetchPersonalDataCategories();
  }

  checkPDCValidity = (...attrs) => {
    const { categories } = this.state;
    const { t } = this.props;
    return checkPDC({ categories: categories.map(({ value }) => ({ name: value, id: value })), t })(
      ...attrs,
    );
  };

  createNewPDC = (value, form) =>
    new Promise(resolve => {
      const { checkPDCValidity } = this;
      const { openToast, removePDC, appendPDC, t } = this.props;

      const isPDCValid = checkPDCValidity({ name: value.name });

      if (isPDCValid.message) {
        return resolve({
          name: isPDCValid.message,
        });
      }

      const newlyCreatedCategory = { id: value.name, label: value.name, value: value.name };

      this.setState(({ categories }) => ({
        categories: [...categories, newlyCreatedCategory],
      }));

      appendPDC(newlyCreatedCategory);
      resolve();

      return createCategory(value)
        .then(({ data: { id } }) => {
          removePDC(newlyCreatedCategory);

          appendPDC({
            ...newlyCreatedCategory,
            id,
          });

          form.reset();

          this.setState(({ categories }) => ({
            categories: categories.map(pdc => {
              if (pdc.id === newlyCreatedCategory.id) {
                return {
                  ...pdc,
                  id,
                };
              }

              return pdc;
            }),
          }));
        })
        .catch(() => {
          openToast({
            message: t('declaration_form.pdc_step.errors.create_pdc'),
            type: 'danger',
            inModal: true,
          });
        });
    });

  fetchPersonalDataCategories = () => {
    const { openToast, t } = this.props;

    return getPersonalDataCategories()
      .then(categories =>
        this.setState({
          categories: [...categories.map(({ name, id }) => ({ id, label: name, value: name }))],
        }),
      )
      .catch(() => {
        openToast({
          message: t('declaration_form.pdc_step.errors.fetch_pdc'),
          type: 'danger',
          inModal: true,
        });
      });
  };

  render() {
    const { categories } = this.state;
    const { selectedCategories, removePDC, appendPDC, t } = this.props;

    return (
      <div className={styles.pdcStep}>
        <div>
          <PersonalDataCategoriesField
            className={styles.pdcSelect}
            name="personalDataCategories"
            options={categories}
            values={selectedCategories.map(({ id }) => id)}
            appendPDC={appendPDC}
            required
            autoFocus
          />

          <TagsListField name="personalDataCategories" onTagRemove={removePDC} />
        </div>

        <div className={styles.separator}>
          <div className={styles.line} />

          <span className={styles.or}>{t('shared.or')}</span>

          <div className={styles.line} />
        </div>

        <Form onSubmit={this.createNewPDC}>
          {({ handleSubmit, invalid, touched, dirtySinceLastSubmit }) => (
            <div className={styles.pdcForm}>
              <InputField
                className={cn(styles.input, {
                  [styles.inputValid]: !invalid || !touched.name || dirtySinceLastSubmit,
                })}
                name="name"
                label={t('declaration_form.pdc_step.personal_data_category')}
                required
              />

              <Button kind="primary" onClick={handleSubmit} type="submit" className={styles.button}>
                {t('declaration_form.pdc_step.create_button')}
              </Button>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

PersonalDataCategoriesStep.defaultProps = {
  selectedCategories: [],
};

PersonalDataCategoriesStep.propTypes = {
  selectedCategories: personalDataCategoriesPropTypes,
  appendPDC: PropTypes.func.isRequired,
  removePDC: PropTypes.func.isRequired,
};

export default withNamespaces()(withToast(PersonalDataCategoriesStep));

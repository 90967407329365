import React from 'react';
import PropTypes from 'prop-types';

import styles from './ModalFooter.module.scss';

const ModalFooter = ({ children }) => <footer className={styles.footer}>{children}</footer>;

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalFooter;

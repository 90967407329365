import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ children, text, position }) =>
  React.cloneElement(children, { 'data-rh': text, 'data-rh-at': position });

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  text: PropTypes.string,
  position: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
};

Tooltip.defaultProps = {
  text: '',
  position: 'top',
};

export default Tooltip;

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Trans from 'src/components/shared/Trans/Trans';

import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = ({ foldable }) => (
  <div className={styles.termsAndConditions}>
    <h1 className={styles.h2}>
      <Trans noMd>legal.terms_and_conditions_title</Trans>
    </h1>

    <div className={cn(styles.content, { [styles.foldable]: foldable })}>
      <Trans>legal.terms_and_conditions</Trans>
    </div>
  </div>
);

TermsAndConditions.propTypes = {
  foldable: PropTypes.bool,
};

TermsAndConditions.defaultProps = {
  foldable: false,
};

export default TermsAndConditions;

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './List.module.scss';

const List = ({ children, ordered, tight }) =>
  ordered ? (
    <ol className={cn(styles.orderedList, { [styles.tight]: tight })}>{children}</ol>
  ) : (
    <ul className={cn(styles.unorderedList, { [styles.tight]: tight })}>{children}</ul>
  );

List.propTypes = {
  children: PropTypes.node.isRequired,
  ordered: PropTypes.bool.isRequired,
  tight: PropTypes.bool.isRequired,
};

export default List;

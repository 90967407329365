export const checkPDC = ({ categories, t }) => ({ id, name }) => {
  if (!name) {
    return {
      message: t('profile_page.personal_data_categories.errors.pdc_name_empty'),
    };
  }

  if (
    categories.find(pdc => pdc.name.toLowerCase() === name.toLowerCase() && pdc.id !== id) !==
    undefined
  ) {
    return {
      message: t('profile_page.personal_data_categories.errors.pdc_name_already_taken'),
    };
  }

  return {};
};

export default (scriptUrl, cssUrl, orgUrl, processorName, consentDeclarationId, widgetId) =>
  `
<div id="traq-container"></div>
<script>
  (function (w,d,s,o,f,css,js,fjs) {
    w["TRAQ-Widget"]=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
    js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs); w[o].css = css;
  }(window, document, "script", "traq", "${scriptUrl}", "${cssUrl}"));

  const widgetData = "INSERT WIDGET DATA HERE";
  traq("init", "${orgUrl}", "${processorName}", "${consentDeclarationId}", "${widgetId}", widgetData);
  traq("render");
</script>
    `.trim();

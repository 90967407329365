import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Trans from 'src/components/shared/Trans/Trans';

import Checkmark from 'src/components/image/Checkmark';

import styles from './Label.module.scss';

const Label = ({
  htmlFor,
  required,
  children,
  className,
  shouldDisplayCheckmark,
  hideOptional,
  hideCheckmark,
}) => (
  <label
    htmlFor={htmlFor}
    className={cn(styles.label, className, {
      [styles.labelOptional]: !hideOptional && !required && !shouldDisplayCheckmark,
    })}
  >
    <span>{children}</span>
    {shouldDisplayCheckmark
      ? !hideCheckmark && <Checkmark />
      : !hideOptional &&
        !required && (
          <span className={styles.optional}>
            <Trans noMd>shared.optional</Trans>
          </span>
        )}
  </label>
);

Label.defaultProps = {
  htmlFor: '',
  required: false,
  shouldDisplayCheckmark: false,
  children: '',
  className: '',
  hideOptional: false,
  hideCheckmark: false,
};

Label.propTypes = {
  htmlFor: PropTypes.string,
  required: PropTypes.bool,
  shouldDisplayCheckmark: PropTypes.bool,
  children: PropTypes.node,
  hideOptional: PropTypes.bool,
  hideCheckmark: PropTypes.bool,
  className: PropTypes.string,
};

export default Label;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { parse } from 'query-string';

import has from 'lodash/has';

import { authPropTypes } from 'src/prop_types/auth_prop_types';
import { setNewPassword, resetPassword } from 'src/api/auth';
import { activateUser } from 'src/api/users';

import withSession from 'src/components/AuthContext/withSession/withSession';
import LandingLayout from 'src/components/shared/LandingLayout/LandingLayout';
import FooterLanguagePicker from 'src/components/shared/FooterLanguagePicker/FooterLanguagePicker';

import withToast from 'src/components/Toast/withToast/withToast';
import toastPropTypes from 'src/prop_types/toastPropTypes';

import TermsAndConditions from 'src/components/shared/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from 'src/components/shared/PrivacyPolicy/PrivacyPolicy';
import ResetPasswordPasswordForm from './ResetPasswordPasswordForm/ResetPasswordPasswordForm';
import ResetPasswordEmailForm from './ResetPasswordEmailForm/ResetPasswordEmailForm';

import styles from './ResetPasswordPage.module.scss';

const Right = ({ state }) => {
  switch (state) {
    case 'tac': {
      return <TermsAndConditions foldable />;
    }
    case 'pp': {
      return <PrivacyPolicy />;
    }
    default: {
      return null;
    }
  }
};

class ResetPasswordPage extends Component {
  static propTypes = {
    openToast: toastPropTypes.openToast.isRequired,
    auth: authPropTypes.auth.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    location: ReactRouterPropTypes.location.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    right: null,
  };

  isInvite = () => {
    const parsedQS = this.getQueryParams();
    return has(parsedQS, 'invite') && parsedQS.invite === 'true';
  };

  getQueryParams = () => {
    const { location: { search: queryString } = {} } = this.props;
    return parse(queryString);
  };

  onPasswordSubmit = credentials => {
    const { isInvite, handleAccountActivation, handlePasswordReset } = this;

    if (isInvite()) {
      return handleAccountActivation(credentials);
    }

    return handlePasswordReset(credentials);
  };

  displayErrorToast = () => {
    const { openToast, t } = this.props;

    openToast({
      message: t('reset_password_page.errors.general'),
      type: 'danger',
      autoHideTimeout: 9000,
    });
  };

  handleAccountActivation = ({ password, terms, privacy_policy: privacyPolicy }) => {
    const {
      auth: { handleLogin },
      history,
    } = this.props;
    const { displayErrorToast } = this;

    const { token, email: email64 } = this.getQueryParams();
    const email = atob(email64);

    activateUser({ password, token, terms, privacyPolicy })
      .then(() => handleLogin({ email, password }))
      .then(() => history.push('/'))
      .catch(displayErrorToast);
  };

  handlePasswordReset = ({ password }) => {
    const {
      auth: { handleLogin },
      history,
    } = this.props;
    const { displayErrorToast } = this;

    const { token, email: email64 } = this.getQueryParams();
    const email = atob(email64);

    setNewPassword({ password, token })
      .then(() => handleLogin({ email, password }))
      .then(() => history.push('/'))
      .catch(displayErrorToast);
  };

  onTaCClick = e => {
    e.preventDefault();

    this.setState({
      right: 'tac',
    });
  };

  onPPClick = e => {
    e.preventDefault();

    this.setState({
      right: 'pp',
    });
  };

  onEmailSubmit = ({ email }) => {
    const { t, openToast } = this.props;

    resetPassword(email)
      .then(() => {
        openToast({ message: t('reset_password_page.email_form.toasts.reset_link_sent') });
      })
      .catch(() =>
        openToast({
          message: t('reset_password_page.email_form.errors.general'),
          type: 'danger',
          autoHideTimeout: 9000,
        }),
      );
  };

  render() {
    const { onPasswordSubmit, onEmailSubmit, onTaCClick, onPPClick, isInvite } = this;
    const { right } = this.state;
    const parsedQS = this.getQueryParams();

    return (
      <main className={styles.page}>
        <section className={styles.content}>
          <LandingLayout
            nav={false}
            right={right && <Right state={right} />}
            left={
              has(parsedQS, 'email') && has(parsedQS, 'token') ? (
                <ResetPasswordPasswordForm
                  onSubmit={onPasswordSubmit}
                  onTaCClick={onTaCClick}
                  onPPClick={onPPClick}
                  isInvite={isInvite()}
                />
              ) : (
                <ResetPasswordEmailForm onSubmit={onEmailSubmit} />
              )
            }
          />
        </section>

        <FooterLanguagePicker />
      </main>
    );
  }
}

export default withSession(withRouter(withNamespaces()(withToast(ResetPasswordPage))));

import React from 'react';
import PropTypes from 'prop-types';

import AddressIcon from 'src/components/image/AddressIcon';
import EmailIcon from 'src/components/image/EmailIcon';
import PhoneIcon from 'src/components/image/PhoneIcon';
import isEmpty from 'lodash/isEmpty';
import styles from './CompanyCard.module.scss';

const CompanyCard = ({
  company: { companyName, contactEmail, contactPhone, address1, address2 },
}) => (
  <div className={styles.companyCard}>
    <span className={styles.name}>{companyName}</span>
    <span className={styles.info}>
      <EmailIcon />
      {contactEmail}
    </span>
    {!isEmpty(contactPhone) && (
      <span className={styles.info}>
        <PhoneIcon />
        {contactPhone}
      </span>
    )}
    <span className={styles.addressInfo}>
      <AddressIcon />
      <div className={styles.address}>
        <span className={styles.info}>{address1}</span>
        <span className={styles.info}>{address2}</span>
      </div>
    </span>
  </div>
);

CompanyCard.defaultProps = {
  company: {},
};

CompanyCard.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
    contactEmail: PropTypes.string,
    contactPhone: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
  }),
};

export default CompanyCard;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './BreadcrumbTitle.module.scss';

const BreadcrumbTitle = ({ path, current, children }) => (
  <section className={styles.title}>
    <div className={styles.titleText}>
      {path.map(({ label, pathElement }) => (
        <Link key={pathElement} to={pathElement} className={styles.pathElement}>
          {label}/
        </Link>
      ))}
      <h2 className={styles.current}>{current}</h2>
    </div>
    {children}
  </section>
);

BreadcrumbTitle.defaultProps = {
  path: [{ label: 'base', pathElement: '/' }],
  current: 'current',
  children: null,
};

BreadcrumbTitle.propTypes = {
  children: PropTypes.node,
  path: PropTypes.arrayOf(PropTypes.object),
  current: PropTypes.string,
};

export default BreadcrumbTitle;

import React from 'react';
import { string, node } from 'prop-types';
import cn from 'classnames';

import styles from './Title.module.scss';

const Title = ({ tag, children, className }) => {
  const Tag = `h${tag}`;
  return <Tag className={cn(styles.title, className)}>{children}</Tag>;
};

Title.propTypes = {
  tag: string.isRequired,
  className: string,
  children: node.isRequired,
};

Title.defaultProps = {
  className: '',
};

export default Title;

import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

import withToast from 'src/components/Toast/withToast/withToast';
import { withNamespaces } from 'react-i18next';

import {
  isLoggedIn,
  setAccessToken,
  removeAccessToken,
  getUserData,
  getCurrentOrganization,
} from 'src/utils/auth';

import { getProfileDetails } from 'src/api/profile';

import logoutEventDispatcher from './logoutEventDispatcher';

const AuthContext = createContext();
export const AuthConsumer = AuthContext.Consumer;

class AuthProvider extends Component {
  static propTypes = {
    createSession: PropTypes.func.isRequired,
    destroySession: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({}).isRequired,
    openToast: PropTypes.func.isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  state = {
    isAuthenticated: isLoggedIn(),
    userDetails: getUserData(),
    currentOrganization: getCurrentOrganization(),
  };

  componentDidMount = async () => {
    const { isAuthenticated } = this.state;

    if (isAuthenticated) {
      const { i18n } = this.props;

      const {
        attributes: { email, full_name: fullName, language },
      } = await getProfileDetails();

      this.setState({
        userDetails: {
          email,
          fullName,
          language,
        },
      });

      if (language) {
        i18n.changeLanguage(language);
      }
    }

    logoutEventDispatcher.on(() => {
      this.handleLogout(true);
    });
  };

  handleUpdateUserDetails = ({ email }) => {
    this.setState(prevState => ({
      userDetails: {
        ...prevState.userDetails,
        email,
      },
    }));
  };

  handleLogin = credentials => {
    const { createSession, i18n } = this.props;
    return createSession(credentials).then(response => {
      setAccessToken(response);

      this.setState(
        {
          isAuthenticated: true,
          userDetails: getUserData(),
          currentOrganization: getCurrentOrganization(),
        },
        () => {
          const { language } = this.state.userDetails;
          if (language) {
            i18n.changeLanguage(language);
          }
        },
      );

      return this.state;
    });
  };

  handleLogout = sessionExpired => {
    const { destroySession, openToast, t } = this.props;

    destroySession().finally(() => {
      if (sessionExpired) {
        openToast({
          message: t('shared.error_session_expired'),
          type: 'danger',
        });
      }

      removeAccessToken();
      this.setState({ isAuthenticated: false });
    });
  };

  render() {
    const { children } = this.props;
    const { isAuthenticated, userDetails, currentOrganization } = this.state;
    const { handleLogin, handleLogout, handleUpdateUserDetails } = this;

    return (
      <AuthContext.Provider
        value={{
          isAuthenticated,
          handleLogin,
          handleLogout,
          userDetails,
          currentOrganization,
          handleUpdateUserDetails,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export default withNamespaces()(withToast(AuthProvider));

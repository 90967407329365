export default {
  plain: {
    backgroundColor: '#f4f7f9',
    color: '#152935',
    fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
    lineHeight: 1.5,
  },
  styles: [
    {
      types: ['punctuation'],
      style: {
        color: '#152935',
      },
    },
    {
      types: ['tag', 'attr', 'namespace', 'deleted'],
      style: {
        color: '#175aff',
      },
    },
    {
      types: ['function-name'],
      style: {
        color: '#6196cc',
      },
    },
    {
      types: ['boolean', 'number', 'function'],
      style: {
        color: '#83b5ff',
      },
    },
    {
      types: ['property', 'class-name', 'constant', 'symbol'],
      style: {
        color: '#83b5ff',
      },
    },
    {
      types: ['selector', 'important', 'atrule', 'keyword', 'builtin'],
      style: {
        color: '#df284f',
      },
    },
    {
      types: ['string', 'char', 'attr-value', 'regex', 'variable'],
      style: {
        color: '#2ec992',
      },
    },
    {
      types: ['operator', 'entity', 'url'],
      style: {
        color: '#67cdcc',
      },
    },
  ],
};

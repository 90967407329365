/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'react-router-dom';
import Trans from 'src/components/shared/Trans/Trans';

import urls from 'src/constants/urls';
import { declarationPropTypes } from 'src/prop_types/declaration_prop_types';

import IdPreview from 'src/components/shared/IdPreview/IdPreview';
import Tooltip from 'src/components/shared/Tooltip/Tooltip';

import InitialsAvatar from './InitialsAvatar/InitialsAvatar';
import styles from './DeclarationRow.module.scss';

const DeclarationRow = ({ declaration }) => (
  <tr className={styles.declarationRow}>
    <Tooltip text={declaration.createdBy ? declaration.createdBy.fullName : 'API'}>
      <td className={styles.createdBy}>
        <InitialsAvatar>{declaration.createdBy ? declaration.createdBy.fullName : 'API'}</InitialsAvatar>
      </td>
    </Tooltip>

    <td className={styles.longText}>
      <IdPreview>{declaration.id}</IdPreview>
    </td>

    <Tooltip text={declaration.title}>
      <td className={styles.longText}>{declaration.title}</td>
    </Tooltip>
    <Tooltip text={declaration.sanitizedPurpose}>
      <td className={styles.longText}>
        {declaration.sanitizedPurposeTruncated}
      </td>
    </Tooltip>

    <td>{ declaration.test ? "Test" : "Standard" }</td>
    <td className={styles.createdAt}>
      <Trans date>{declaration.updatedAt}</Trans>
    </td>
    <td className={styles.action}>
      <Link
        className={styles.viewButton}
        to={{
          pathname: urls.declarations(declaration.id),
        }}
      >
        <Trans noMd>shared.view</Trans>
      </Link>
    </td>
  </tr>
);

DeclarationRow.propTypes = {
  ...declarationPropTypes,
};

export default DeclarationRow;

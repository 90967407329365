import React from 'react';
import PropTypes from 'prop-types';

import DownloadIcon from 'src/components/image/DownloadIcon';

import styles from './DownloadButton.module.scss';

const DownloadButton = ({ onClick, children, description = '' }) => (
  <div>
    {description ? <p className={styles.description}>{description}</p> : null}
    <button type="button" className={styles.button} onClick={onClick}>
      <DownloadIcon width={20} height={20} />
      <span>{children}</span>
    </button>
  </div>
);

DownloadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  description: PropTypes.string,
};

DownloadButton.defaultProps = {
  description: '',
};

export default DownloadButton;

import React from 'react';
import source from 'images/no_contracts.svg';

import Icon from './Icon';

const NoContractsImage = ({ ...props }) => (
  <Icon alt="create contracts" src={source} {...props} />
);

export default NoContractsImage;

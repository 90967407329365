import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Trans from 'src/components/shared/Trans/Trans';

import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy = ({ foldable }) => (
  <React.Fragment>
    <div className={styles.privacyPolicy}>
      <h1 className={styles.h2}>
        <Trans noMd>legal.privacy_policy_title</Trans>
      </h1>

      <div className={cn(styles.content, { [styles.foldable]: foldable })}>
        <h3>Privacy</h3>
        <p>
          This Privacy Policy governs the manner in which Skalar AS collects, uses, maintains and
          discloses information collected from users (each, a “User”) of the traqcloud.no website
          (“Site”).
        </p>

        <h3>Personal identification information</h3>
        <p>
          We may collect personal identification information from Users in a variety of ways,
          including, but not limited to, when Users visit our site, register on the site, subscribe
          to the newsletter, respond to a survey, fill out a form, and in connection with other
          activities, services, features or resources we make available on our Site. Users may be
          asked for, as appropriate, name, email address. Users may, however, visit our Site
          anonymously. We will collect personal identification information from Users only if they
          voluntarily submit such information to us. Users can always refuse to supply personally
          identification information, except that it may prevent them from engaging in certain Site
          related activities.
        </p>

        <h3>Non-personal identification information</h3>
        <p>
          We may collect non-personal identification information about Users whenever they interact
          with our Site. Non-personal identification information may include the browser name, the
          type of computer and technical information about Users means of connection to our Site,
          such as the operating system and the Internet service providers utilized and other similar
          information.
        </p>

        <h3>Web browser cookies</h3>
        <p>
          Our Site may use “cookies” to enhance User experience. User’s web browser places cookies
          on their hard drive for record-keeping purposes and sometimes to track information about
          them. Users may choose to set their web browser to refuse cookies, or to alert you when
          cookies are being sent. If they do so, note that some parts of the Site may not function
          properly.
        </p>

        <h3>How we use collected information</h3>
        <p>Skalar AS may collect and use Users personal information for the following purposes:</p>
        <ul>
          <li>
            To improve our Site and Service ee may use feedback you provide to improve our products
            and services.
          </li>
          <li>
            To run a promotion, contest, survey or other features. To send Users information they
            agreed to receive about topics we think will be of interest to them.
          </li>
          <li>
            To send periodic emails If User decides to opt-in to our mailing list, they will receive
            emails that may include company news, updates, related product or service information,
            etc. If at any time the User would like to unsubscribe from receiving future emails, we
            include detailed unsubscribe instructions at the bottom of each email or User may
            contact us via our Site.
          </li>
        </ul>
        <p>
          We adopt appropriate data collection, storage, and processing practices and security
          measures to protect against unauthorized access, alteration, disclosure or destruction of
          your personal information, username, password, transaction information and data stored on
          our Site.
        </p>

        <h3>Sharing your personal information</h3>
        <p>
          We do not sell, trade, or rent Users personal identification information to others. We may
          use third-party service providers to help us operate our business and the Site or
          administer activities on our behalfs, such as sending out newsletters or surveys. We may
          share your information with these third parties for those limited purposes provided that
          you have given us your permission.
        </p>

        <h3>Third party websites and services</h3>
        <p>
          Users may find content on our Site that linking to the sites and services of our partners,
          suppliers, advertisers, sponsors, licensors and other third parties. We do not control the
          content or links that appear on these sites and are not responsible for the practices
          employed by websites linked to or from our Site. In addition, these sites or services,
          including their content and links, may be constantly changing. These sites and services
          may have their own privacy policies and customer service policies. Browsing and
          interaction on any other website, including websites which have a link to our Site, is
          subject to that website’s own terms and policies.
        </p>

        <h3>Changes to this privacy policy</h3>
        <p>
          Transfon has the discretion to update this privacy policy at any time. When we do, we will
          revise the updated date at the bottom of this page. We encourage Users to frequently check
          this page for any changes to stay informed about how we are helping to protect the
          personal information we collect. You acknowledge and agree that it is your responsibility
          to review this privacy policy periodically and become aware of modifications.
        </p>

        <h3>Your acceptance of these terms</h3>
        <p>
          By using this Site, you signify your acceptance of this policy. If you do not agree to
          this policy, please do not use our Site and services. Your continued use of the Site
          following the posting of changes to this policy will be deemed your acceptance of those
          changes.
        </p>

        <h3>Contacting us</h3>
        <p>
          If you have any questions about this Privacy Policy, the practices of this site, or your
          dealings with this site, please contact us at:
        </p>
        <p>Skalar AS - post@skalar.no</p>
      </div>
    </div>
  </React.Fragment>
);

PrivacyPolicy.propTypes = {
  foldable: PropTypes.bool,
};

PrivacyPolicy.defaultProps = {
  foldable: false,
};

export default PrivacyPolicy;

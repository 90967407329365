import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Status from 'src/components/shared/Status/Status';

const messages = {
  active: 'shared.active',
  deactivated: 'shared.deactivated',
  pending: 'shared.inactive',
};

const UserStatus = ({ status, t, uppercase, noBullet }) => {
  let type = '';

  switch (status) {
    case 'active':
      type = 'success';
      break;
    case 'pending':
      type = 'warning';
      break;
    case 'deactivated':
      type = 'danger';
      break;
    // no default
  }

  return (
    <Status
      type={!noBullet ? type : ''}
      message={t(`${messages[status]}${uppercase ? '_uppercase' : ''}`)}
    />
  );
};

UserStatus.propTypes = {
  status: PropTypes.string.isRequired,
  uppercase: PropTypes.bool,
  noBullet: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

UserStatus.defaultProps = {
  uppercase: false,
  noBullet: false,
};

export default withNamespaces()(UserStatus);

import React from 'react';

import PrivacyPolicy from 'src/components/shared/PrivacyPolicy/PrivacyPolicy';
import FooterLanguagePicker from 'src/components/shared/FooterLanguagePicker/FooterLanguagePicker';

import ScrollToTop from 'src/components/shared/ScrollToTop/ScrollToTop';

import styles from './PrivacyPolicyPage.module.scss';

const PrivacyPolicyPage = () => (
  <main className={styles.page}>
    <ScrollToTop />
    <div className={styles.content}>
      <div className={styles.tacContent}>
        <PrivacyPolicy />
      </div>
    </div>
    <FooterLanguagePicker />
  </main>
);

export default PrivacyPolicyPage;

import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Trans from 'src/components/shared/Trans/Trans';

import Button from 'src/components/shared/Button/Button';
import Modal from 'src/components/shared/Modal/Modal';
import ModalFooter from 'src/components/shared/ModalFooter/ModalFooter';

import InviteUserForm from './InviteUserForm/InviteUserForm';

const InviteUserModal = ({ isOpened, onClose, onSubmit, t }) => (
  <Modal open={isOpened} onClose={onClose} title={t('users_page.invite_user_modal.title')}>
    <InviteUserForm onSubmit={onSubmit}>
      <ModalFooter>
        <Button kind="outline" onClick={onClose} type="button">
          <Trans>shared.cancel</Trans>
        </Button>
        <Button kind="primary" type="submit">
          <Trans>shared.send_invite</Trans>
        </Button>
      </ModalFooter>
    </InviteUserForm>
  </Modal>
);

InviteUserModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(InviteUserModal);

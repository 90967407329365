import React from 'react';

import urls from 'src/constants/urls';

import Trans from 'src/components/shared/Trans/Trans';

import NavButton from 'src/components/shared/NavButton/NavButton';

import styles from './LandingNav.module.scss';

const LandingNav = () => (
  <nav className={styles.landingNav}>
    <NavButton className={styles.nav} activeClassName={styles.current} to={urls.signIn()}>
      <Trans>landing_page.sign_in_button</Trans>
    </NavButton>
    <NavButton className={styles.nav} activeClassName={styles.current} to={urls.signUp()}>
      <Trans>landing_page.sign_up_button</Trans>
    </NavButton>
  </nav>
);

export default LandingNav;

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

const OnBlurForm = ({ render, children, component: Component, ...props }) => (
  <Form validateOnBlur {...props}>
    {({ handleSubmit, form, active, ...attrs }) => {
      const renderAttributes = {
        ...attrs,
        form,
        active,
        handleSubmit: e => {
          if (active) {
            form.blur(active);
            form.focus(active);
          }

          handleSubmit(e);
        },
      };

      if (render) {
        return render(renderAttributes);
      }

      if (children) {
        return children(renderAttributes);
      }

      if (Component) {
        return <Component {...renderAttributes} />;
      }

      return null;
    }}
  </Form>
);

OnBlurForm.propTypes = {
  render: PropTypes.func,
  children: PropTypes.func,
  component: PropTypes.node,
};

OnBlurForm.defaultProps = {
  render: null,
  children: null,
  component: null,
};

export default OnBlurForm;

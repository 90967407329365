import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import CloseIcon from 'src/components/image/CloseIcon';
import HelpIcon from 'src/components/image/HelpIcon';

import styles from './FormHint.module.scss';

const FormHint = ({ title, children, onClose, className }) => (
  <div className={cn(styles.hintBox, className)}>
    <div className={styles.header}>
      <div className={styles.title}>
        <HelpIcon className={styles.help} />
        <span className={styles.mini}>{title}</span>
      </div>
      <button type="button" onClick={onClose} className={styles.closeButton}>
        <CloseIcon className={styles.close} />
      </button>
    </div>
    <div className={styles.content}>{children}</div>
  </div>
);

FormHint.defaultProps = {
  onClose: null,
  className: null,
};

FormHint.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default FormHint;

import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';

const processValue = parseFn => value => {
  if (isPlainObject(value)) return parseFn(value);
  if (isArray(value)) return value.map(processValue(parseFn));
  return value;
};

export const toSnakeCase = obj => {
  const handleValue = processValue(toSnakeCase);

  return Object.keys(obj).reduce(
    (memo, key) => ({
      ...memo,
      [snakeCase(key)]: handleValue(obj[key]),
    }),
    {},
  );
};

export const toCamelCase = obj => {
  const handleValue = processValue(toCamelCase);

  return Object.keys(obj).reduce(
    (memo, key) => ({
      ...memo,
      [camelCase(key)]: handleValue(obj[key]),
    }),
    {},
  );
};

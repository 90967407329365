import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { withNamespaces } from 'react-i18next';
import cn from 'classnames';

import withToast from 'src/components/Toast/withToast/withToast';
import toastPropTypes from 'src/prop_types/toastPropTypes';
import { getContracts, revokeContract } from 'src/api/contracts';

import withSort from 'src/components/shared/withSort/withSort';
import Pagination from 'src/components/shared/Pagination/Pagination';
import WarningModal from 'src/components/shared/WarningModal/WarningModal';
import Loading from 'src/components/shared/Loading/Loading';
import Title from 'src/components/shared/Title/Title';

import SelectedContractsInfo from './SelectedContractsInfo/SelectedContractsInfo';
import NoContracts from './NoContracts/NoContracts';
import ContractsList from './ContractsList/ContractsList';
import ContractSearcher from './ContractSearcher/ContractSearcher';

import styles from './ContractsPage.module.scss';

class ContractsPage extends Component {
  debounceHandler = null;

  static propTypes = {
    openToast: toastPropTypes.openToast.isRequired,
    getContracts: PropTypes.func,
    type: PropTypes.object,
  };

  static defaultProps = {
    getContracts,
  };

  state = {
    allSelected: false,
    currentPage: 1,
    type: {
      value: "all",
      label: "All",
    },
    totalPages: 1,
    contracts: {},
    modal: false,
    isLoading: true,
    searchValue: '',
  };

  componentDidMount() {
    const { currentPage, type } = this.state;

    this.getPageOfContracts(currentPage, type);
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const { currentPage, type } = this.state;

    if (props.sortBy !== prevProps.sortBy || props.sortMode !== prevProps.sortMode) {
      this.getPageOfContracts(currentPage, type);
    }
  }

  onNextClick = () => {
    const { currentPage, totalPages, type } = this.state;

    if (currentPage < totalPages) {
      this.setState({
        isLoading: true,
      });

      this.getPageOfContracts(currentPage + 1, type);
    }
  };

  onPrevClick = () => {
    const { currentPage, type } = this.state;

    if (currentPage > 1) {
      this.setState({
        isLoading: true,
      });

      this.getPageOfContracts(currentPage - 1, type);
    }
  };

  onSelect = (checked, id) => {
    const { contracts } = this.state;
    const selectedContract = { ...contracts[id] };
    selectedContract.selected = checked;

    contracts[id] = selectedContract;

    this.setState({ contracts });
  };

  onSelectAll = () =>
    this.setState(prevState => ({
      allSelected: !prevState.allSelected,
      contracts: Object.keys(prevState.contracts).reduce(
        (mem, id) => ({
          ...mem,
          [id]: { ...prevState.contracts[id], selected: !prevState.allSelected },
        }),
        {},
      ),
    }));

  getPageOfContracts = async (page, type) => {
    const { sortBy, sortMode } = this.props;
    const { searchValue } = this.state;

    const data = await this.props.getContracts(
      page, type.value,
      {
        [sortBy]: sortMode.toLowerCase(),
      },
      searchValue,
    );

    this.loadData(data);
  };

  loadData = ({ contracts, meta: { currentPage, totalPages } }) => {
    const contractsObj = contracts.reduce(
      (memo, contract) => ({ ...memo, [contract.id]: { selected: false, contract } }),
      {},
    );

    this.setState({ contracts: contractsObj, currentPage, totalPages, isLoading: false });
  };

  debounceSearchContract = () => {
    debounce(() => {
      const { currentPage, page } = this.state;
      this.getPageOfContracts(currentPage, type);
    }, 5000);
  };

  handleSearcherChange = value => {
    const { currentPage, type } = this.state;
    this.setState({ searchValue: value }, () => {
      if (this.debounceHandler) {
        clearTimeout(this.debounceHandler);
      }
      this.debounceHandler = setTimeout(() => {
        this.getPageOfContracts(currentPage, type);
      }, 500);
    });
  };

  handleSearcherClear = () => {
    this.setState({ currentPage: 1, searchValue: '' }, () => {
      const { currentPage, type } = this.state;
      this.getPageOfContracts(currentPage, type);
    });
  };

  onChangeType = (newType, changeCb) => {
    console.log(newType)
    this.setState({type: newType, currentPage: 1})
    this.getPageOfContracts(1, newType)
    changeCb(newType)
  }

  getSelected = contracts => map(filter(contracts, e => e.selected), e => e.contract);

  revokeSelected = () => {
    const { currentPage, contracts, type } = this.state;
    const selected = this.getSelected(contracts);
    const { t, openToast } = this.props;

    const contractsToRevokePromises = selected.map(
      c => (c.status ? revokeContract(c.id) : Promise.reject()),
    );

    new Promise(resolve => {
      let total = 0;
      let successful = 0;
      const increment = () => {
        total += 1;

        if (total === contractsToRevokePromises.length) {
          resolve(successful);
        }
      };

      contractsToRevokePromises.forEach(P => {
        P.then(() => {
          successful += 1;
          increment();
        }).catch(increment);
      });
    }).then(revokesSuccessful => {
      if (contractsToRevokePromises.length > 0 && revokesSuccessful === 0) {
        openToast({
          message: t('shared.contract_already_revoked_toast', {
            count: contractsToRevokePromises.length,
          }),
          type: 'danger',
        });
      } else {
        openToast({
          message: t('contracts_page.toasts.contracts_revoked', { count: revokesSuccessful }),
          type: 'danger',
        });
      }

      this.getPageOfContracts(currentPage, type);
      this.toggleRevokeModal();
    });
  };

  toggleRevokeModal = () => this.setState(prevState => ({ modal: !prevState.modal }));

  render() {
    const {
      onPrevClick,
      onNextClick,
      revokeSelected,
      onSelectAll,
      onSelect,
      toggleRevokeModal,
      getSelected,
    } = this;
    const {
      contracts,
      allSelected,
      modal,
      isLoading,
      totalPages,
      currentPage,
      searchValue,
    } = this.state;

    const { t, changeSort, sortBy, sortMode, fromContractDeclaration } = this.props;
    const { type } = this.state

    const selectedContracts = getSelected(contracts);
    const selectedAmount = selectedContracts.length;
    const revokeModalTitle = t('contracts_page.confirm_revoking_modal.title', {
      count: selectedAmount,
    });

    const revokeModalContent = t('shared.revoke_all_contracts_modal.content');

    return (
      <main className={styles.contractsPage}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Title title={t('shared.contracts')}>
              {!isEmpty(selectedContracts) && (
                <SelectedContractsInfo
                  className={styles.revokeWrap}
                  selected={selectedContracts}
                  onRevoke={toggleRevokeModal}
                />
              )}
            </Title>
            <ContractSearcher
              onChange={this.handleSearcherChange}
              onClear={this.handleSearcherClear}
              currentValue={searchValue}
              label={t('contracts_page.contracts_searcher.label')}
              placeholder={t('contracts_page.contracts_searcher.placeholder')}
              type={type}
              onChangeType={this.onChangeType}
            />

            <section
              className={cn(styles.content, {
                [styles.centered]: isEmpty(contracts),
              })}
            >
              {isEmpty(contracts) ? (
                <NoContracts fromContractDeclaration={fromContractDeclaration} />
              ) : (
                <Fragment>
                  <ContractsList
                    {...{ contracts, allSelected, onSelectAll, onSelect }}
                    changeSort={changeSort}
                    sortBy={sortBy}
                    sortMode={sortMode}
                  />

                  {!isEmpty(selectedContracts) && (
                    <SelectedContractsInfo
                      className={styles.revokeMargin}
                      selected={selectedContracts}
                      onRevoke={toggleRevokeModal}
                    />
                  )}

                  <Pagination
                    onPrevClick={onPrevClick}
                    onNextClick={onNextClick}
                    totalPages={totalPages}
                    currentPage={currentPage}
                  />
                </Fragment>
              )}
            </section>

            <WarningModal
              title={revokeModalTitle}
              open={modal}
              onClose={toggleRevokeModal}
              onAccept={revokeSelected}
              acceptLabel={t('shared.revoke')}
            >
              {revokeModalContent}
            </WarningModal>
          </Fragment>
        )}
      </main>
    );
  }
}

export default withNamespaces()(
  withToast(
    withSort({
      sortBy: 'date',
      sortMode: 'desc',
      reversedDefaults: ['date'],
    })(ContractsPage),
  ),
);

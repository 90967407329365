import PropTypes from 'prop-types';

export const userCardPropTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  lastSignInAt: PropTypes.string,
};

export const userDetailsPropTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  lastSignInAt: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
};

export const userFormPropTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
};

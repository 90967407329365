import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import cn from 'classnames';

import CalendarIcon from 'src/components/image/CalendarIcon';
import Label from 'src/components/shared/Label/Label';
import SelectField from 'src/components/shared/SelectField/SelectField';
import { withNamespaces } from 'react-i18next';
import ErrorMessage from 'src/components/shared/ErrorMessage/ErrorMessage';
import Trans from 'src/components/shared/Trans/Trans';

import { shouldDisplayError } from 'src/utils/shouldDisplayError';
import { reduceValidators, amountValidator } from 'src/utils/validators';

import styles from './ValidityField.module.scss';

const regex = new RegExp(/([^0-9])/gi);

const ValidityField = ({ t, autoFocus, unit: defaultUnit }) => {
  const [validity, setValidity] = React.useState({
    unit: null,
    amount: null,
  });

  React.useEffect(() => {
    setValidity(state => ({ ...state, unit: defaultUnit || null }));
  }, []);

  const parseValidityUnit = ({ value }) => value;
  const timeUnits = ['days', 'weeks', 'months', 'years'].map(value => ({
    label: t(`declaration_form.time_step.time_units.${value}`),
    value,
  }));

  const formatValidityUnit = unit => timeUnits.filter(({ value }) => value === unit)[0];

  const handleChangeUnit = (data, cb) => {
    setValidity(state => ({ ...state, unit: data.value }));
    cb(data);
  };

  const handleChangeAmount = input => e => {
    e.persist();

    const replaced = e.target.value.replace(regex, '');
    const value = !replaced ? replaced : Number(replaced);

    input.onChange(value);
    setValidity(state => ({ ...state, amount: value }));
  };

  const validate = reduceValidators([amountValidator(validity.unit)]);

  return (
    <Fragment>
      <Label htmlFor="validity">{t('declaration_form.time_step.consent_valid_until')}</Label>

      <div className={styles.validity} id="validity">
        <div className={styles.label}>
          <span>{t('declaration_form.time_step.select_time')}</span>

          <CalendarIcon />
        </div>

        <div className={styles.selector}>
          <SelectField
            className={styles.unitSelector}
            options={timeUnits}
            placeholder={t('declaration_form.time_step.time_unit')}
            name="validityUnit"
            parse={parseValidityUnit}
            format={formatValidityUnit}
            showSelected
            autoFocus={autoFocus}
            onChange={handleChangeUnit}
          />

          <Field key={validity.unit} name="validity" validate={validate}>
            {({
              input,
              meta: { valid, error, touched, dirty, submitError, dirtySinceLastSubmit },
            }) => (
              <div className={styles.wrapper}>
                {shouldDisplayError({
                  valid,
                  touched,
                  dirty,
                  dirtySinceLastSubmit,
                  submitError,
                }) && (
                  <ErrorMessage>
                    <Trans noMd>{error || submitError}</Trans>
                  </ErrorMessage>
                )}
                <input
                  {...input}
                  onChange={handleChangeAmount(input)}
                  className={cn(styles.amountSelector, {
                    [styles.error]: shouldDisplayError({
                      valid,
                      touched,
                      dirty,
                      dirtySinceLastSubmit,
                      submitError,
                    }),
                  })}
                />
              </div>
            )}
          </Field>
        </div>
      </div>
    </Fragment>
  );
};

ValidityField.propTypes = {
  t: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};

ValidityField.defaultProps = {
  autoFocus: false,
};

export default withNamespaces()(ValidityField);

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import TagsList from './TagsList/TagsList';

const TagsListField = ({ name, onTagRemove }) => (
  <Field name={name}>
    {({ input: { onChange, value } }) => (
      <TagsList onChange={onChange} tags={value} onTagRemove={onTagRemove} />
    )}
  </Field>
);

TagsListField.propTypes = {
  name: PropTypes.string.isRequired,
  onTagRemove: PropTypes.func.isRequired,
};

export default TagsListField;

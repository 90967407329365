export const shouldDisplayError = ({ valid, touched, dirtySinceLastSubmit, submitError }) => {
  if (valid) {
    return false;
  }

  if (!touched) {
    return false;
  }

  if (submitError && dirtySinceLastSubmit) {
    return false;
  }

  return true;
};

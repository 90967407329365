import React from 'react';

import TermsAndConditions from 'src/components/shared/TermsAndConditions/TermsAndConditions';
import FooterLanguagePicker from 'src/components/shared/FooterLanguagePicker/FooterLanguagePicker';
import ScrollToTop from 'src/components/shared/ScrollToTop/ScrollToTop';

import styles from './TermsAndConditionsPage.module.scss';

const TermsAndConditionsPage = () => (
  <main className={styles.page}>
    <ScrollToTop />
    <div className={styles.content}>
      <div className={styles.tacContent}>
        <TermsAndConditions />
      </div>
    </div>
    <FooterLanguagePicker />
  </main>
);

export default TermsAndConditionsPage;

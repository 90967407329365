import React, { Component } from 'react';
import Toast from './ToastElement/ToastElement';

import { Provider } from './context';

export default class ToastProvider extends Component {
  state = {
    message: '',
    type: 'info',
    visible: false,
  };

  timeout = null;

  componentWillUnmount() {
    this.clearTimeout();
  }

  clearTimeout = () => {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
  };

  openToast = ({ message, type = 'info', autoHide = true, autoHideTimeout = 3000, inModal }) => {
    this.clearTimeout();

    this.setState({
      visible: true,
      message,
      type,
      inModal,
    });

    if (autoHide) {
      this.timeout = setTimeout(() => {
        this.setState({
          visible: false,
        });

        this.timeout = null;
      }, autoHideTimeout);
    }
  };

  closeToast = () => {
    this.clearTimeout();

    this.setState({
      visible: false,
    });
  };

  render() {
    const { children } = this.props;
    const { message, type, visible, inModal } = this.state;
    const { openToast, closeToast } = this;

    return (
      <Provider value={{ openToast, closeToast }}>
        {children}
        {visible && <Toast {...{ message, type, inModal }} onClick={closeToast} />}
      </Provider>
    );
  }
}

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import cn from 'classnames';
import { withNamespaces } from 'react-i18next';

import { authPropTypes } from 'src/prop_types/auth_prop_types';
import toastPropTypes from 'src/prop_types/toastPropTypes';

import { deactivateUser, deleteUser, showUser } from 'src/api/users';
import { deleteProfile } from 'src/api/profile';
import urls from 'src/constants/urls';

import withSession from 'src/components/AuthContext/withSession/withSession';
import withToast from 'src/components/Toast/withToast/withToast';

import ConfirmPasswordModal from 'src/components/shared/ConfirmPasswordModal/ConfirmPasswordModal';
import BreadcrumbTitle from 'src/components/shared/BreadcrumbTitle/BreadcrumbTitle';
import CaptionedInfo from 'src/components/shared/CaptionedInfo/CaptionedInfo';
import WarningModal from 'src/components/shared/WarningModal/WarningModal';
import UserStatus from 'src/components/shared/UserStatus/UserStatus';
import Columns from 'src/components/shared/Columns/Columns';
import Loading from 'src/components/shared/Loading/Loading';
import Tooltip from 'src/components/shared/Tooltip/Tooltip';
import Trans from 'src/components/shared/Trans/Trans';
import ButtonWithInfo from 'src/components/shared/ButtonWithInfo/ButtonWithInfo';

import styles from './UserDetailsPage.module.scss';

class UserDetailsPage extends PureComponent {
  static propTypes = {
    openToast: toastPropTypes.openToast.isRequired,
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    auth: authPropTypes.auth.isRequired,
  };

  state = {
    user: {},
    deactivateModal: false,
    deleteModal: false,
    isLoading: true,
  };

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      openToast,
      t,
    } = this.props;

    showUser(id)
      .then(user => {
        this.setState({ user, isLoading: false });
      })
      .catch(() => {
        openToast({
          message: t('shared.error_user_details_general'),
        });
      });
  }

  handleSelfDeletion = ({ passwordConfirmation }) =>
    new Promise(resolve => {
      const {
        auth: { handleLogout },
        t,
        openToast,
      } = this.props;

      deleteProfile(passwordConfirmation)
        .then(() => {
          handleLogout();
          openToast({
            message: t('profile_page.your_profile.toasts.removed_successfully'),
          });

          return resolve(undefined);
        })
        .catch(({ status }) => {
          if (status === 422) {
            return resolve({
              passwordConfirmation: t('profile_page.your_profile.errors.incorrect_password'),
            });
          }

          return undefined;
        });
    });

  handleDeletion = () => {
    const {
      history,
      match: {
        params: { id },
      },
      t,
      openToast,
    } = this.props;
    const {
      user: { name },
    } = this.state;

    deleteUser(id).then(() => {
      openToast({ message: t('user_details_page.toasts.user_deleted', { name }), type: 'danger' });
      history.push(urls.users());
    });
  };

  handleDeactivation = () => {
    const {
      history,
      match: {
        params: { id },
      },
      t,
      openToast,
    } = this.props;
    const {
      user: { name },
    } = this.state;

    deactivateUser(id).then(() => {
      openToast({
        message: t('user_details_page.toasts.user_deactivated', { name }),
        type: 'danger',
      });
      history.push(urls.users());
    });
  };

  toggleDeactivateModal = () =>
    this.setState(prevState => ({ deactivateModal: !prevState.deactivateModal }));

  toggleDeleteModal = () => this.setState(prevState => ({ deleteModal: !prevState.deleteModal }));

  render() {
    const {
      user: { name, email, role, lastSignInAt, updatedAt, updatedBy, createdAt, createdBy, status },
      deactivateModal,
      deleteModal,
      isLoading,
    } = this.state;
    const {
      t,
      match: {
        params: { id },
      },
      auth: {
        userDetails: { id: currentUserId },
      },
    } = this.props;
    const {
      handleDeactivation,
      handleDeletion,
      toggleDeactivateModal,
      toggleDeleteModal,
      handleSelfDeletion,
    } = this;

    const isActive = status !== 'deactivated';
    const isUserCurrent = id === currentUserId;

    return (
      <main className={styles.processorDetailsPage}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <BreadcrumbTitle
              path={[
                {
                  label: t('shared.manage_users'),
                  pathElement: urls.users(),
                },
              ]}
              current={name}
            />

            <section className={styles.content}>
              <div className={styles.section}>
                <h3 className={styles.detailTitle}>
                  <Trans>user_details_page.personal_details_title</Trans>
                </h3>

                <Columns className={styles.row}>
                  {{
                    left: <CaptionedInfo title={t('shared.full_name')}>{name}</CaptionedInfo>,
                    right: (
                      <CaptionedInfo className={styles.emailCaption} title={t('shared.email')}>
                        <Tooltip text={email}>
                          <span>{email}</span>
                        </Tooltip>
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>

                <Columns className={styles.row}>
                  {{
                    left: (
                      <CaptionedInfo title={t('shared.role')}>
                        {t(`shared.roles.${role}`)}
                      </CaptionedInfo>
                    ),
                    right: (
                      <CaptionedInfo title={t('shared.status')}>
                        <UserStatus status={status} />
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>
              </div>

              <div className={styles.section}>
                <h3 className={styles.detailTitle}>
                  <Trans>shared.history</Trans>
                </h3>

                <div className={styles.row}>
                  <CaptionedInfo title={t('shared.last_signed_in')}>
                    <Trans date="ll">{lastSignInAt}</Trans>
                  </CaptionedInfo>
                </div>

                <Columns className={styles.row}>
                  {{
                    left: (
                      <CaptionedInfo title={t('shared.updated_at')}>
                        <Trans date="ll">{updatedAt}</Trans>
                      </CaptionedInfo>
                    ),
                    right: (
                      <CaptionedInfo title={t('shared.updated_by')}>
                        {updatedBy.fullName}
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>

                <Columns className={styles.row}>
                  {{
                    left: (
                      <CaptionedInfo title={t('shared.created_at')}>
                        <Trans date="ll">{createdAt}</Trans>
                      </CaptionedInfo>
                    ),
                    right: (
                      <CaptionedInfo title={t('shared.created_by')}>
                        {createdBy.fullName}
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>
              </div>

              <div className={styles.section}>
                <h3 className={styles.detailTitle}>
                  <Trans>user_details_page.deactivate_user_title</Trans>
                </h3>
                <div className={styles.row}>
                  <ButtonWithInfo
                    onClick={toggleDeactivateModal}
                    disabled={!isActive}
                    infoClassName={cn({ [styles.isNotActive]: !isActive })}
                    infoText={t('shared.user_deactivation_notice')}
                    kind="outlineDanger"
                    type="button"
                  >
                    <Trans>shared.deactivate_user_button</Trans>
                  </ButtonWithInfo>
                </div>
              </div>

              <div className={styles.section}>
                <h3 className={styles.detailTitle}>
                  <Trans>shared.delete_account</Trans>
                </h3>
                <div className={styles.row}>
                  <ButtonWithInfo
                    onClick={toggleDeleteModal}
                    infoText={t('shared.delete_user_account_modal.content')}
                    kind="outlineDanger"
                    type="button"
                  >
                    <Trans>user_details_page.delete_user_button</Trans>
                  </ButtonWithInfo>
                </div>
              </div>
            </section>

            <WarningModal
              title={t('user_details_page.deactivate_modal.title')}
              open={deactivateModal}
              onClose={toggleDeactivateModal}
              onAccept={handleDeactivation}
              acceptLabel={t('user_details_page.deactivate_modal.deactivate_button')}
            >
              <Trans>user_details_page.deactivate_modal.content</Trans>
            </WarningModal>

            {isUserCurrent ? (
              <ConfirmPasswordModal
                open={deleteModal}
                onClose={toggleDeleteModal}
                onAccept={handleSelfDeletion}
                title={t('profile_page.your_profile.delete_user_modal.title')}
                acceptLabel={t('shared.delete_account')}
              >
                <Trans>profile_page.your_profile.delete_user_modal.description</Trans>
              </ConfirmPasswordModal>
            ) : (
              <WarningModal
                title={t('user_details_page.delete_modal.title')}
                open={deleteModal}
                onClose={toggleDeleteModal}
                onAccept={handleDeletion}
                acceptLabel={t('shared.delete')}
              >
                <Trans>user_details_page.delete_modal.content</Trans>
              </WarningModal>
            )}
          </Fragment>
        )}
      </main>
    );
  }
}

export default withNamespaces()(withSession(withToast(UserDetailsPage)));

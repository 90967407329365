import { toCamelCase, toSnakeCase } from 'src/utils/caseMappers';
import apiRequest from './apiRequest';
import EP from './endpoints';

const formatProcessor = processor => ({
  data: { ...toSnakeCase(processor) }
});

export const getProcessorRecords = (controller) =>
  apiRequest(`${EP.processors}/${controller.id}/processors`).then(data => data);

export const createProcessor = (controller, processor) =>
  apiRequest(`${EP.processors}/${controller.id}/processors`, {
    body: formatProcessor(processor),
  });

export const showProcessor = (controller_id, id) =>
  apiRequest(`${EP.processors}/${controller_id}/processors/${id}`).then(data => toCamelCase(data));

export const updateProcessor = processor =>
  apiRequest(`${EP.processors}/${processor.processorId}/processors/${processor.id}`, {
    body: formatProcessor(processor),
    method: 'PATCH',
  });



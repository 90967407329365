import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Trans from 'src/components/shared/Trans/Trans';

import CopyButton from 'src/components/shared/CopyButton/CopyButton';
import ValidityStatus from 'src/components/shared/ValidityStatus/ValidityStatus';
import urls from 'src/constants/urls';
import { consentPropTypes } from 'src/prop_types/consent_prop_types';

import styles from './ConsentRow.module.scss';

const ConsentRow = ({
  consent: { id, identifier, processor, consentDeclarationName, date, validUntil, status, test },
  selected,
  onSelect,
}) => (
  <tr className={styles.consentRow}>
    <td>
      <input
        type="checkbox"
        checked={selected}
        onChange={e => onSelect(e.target.checked, id)}
        name={`consent_${id}`}
      />
    </td>
    <td data-identifier={identifier} className={styles.longText}>
      <CopyButton>{identifier}</CopyButton>
    </td>
    <td>{processor}</td>
    <td>{consentDeclarationName}</td>
    <td>
      <Trans date>{date}</Trans>
    </td>
    <td>
      <Trans date>{validUntil}</Trans>
    </td>
    <td>
      <ValidityStatus valid={status} />
    </td>

    <td>{ test ? "Test" : "Standard" }</td>

    <td>
      <Link
        className={styles.viewButton}
        to={{
          pathname: urls.consents(id),
          state: {
            consent: {
              id,
              processor,
              date,
              validUntil,
              status,
            },
          },
        }}
      >
        <Trans noMd>shared.view</Trans>
      </Link>
    </td>
  </tr>
);

ConsentRow.defaultProps = {
  selected: false,
  onSelect: null,
};

ConsentRow.propTypes = {
  consent: consentPropTypes.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default ConsentRow;

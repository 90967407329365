import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { toCamelCase, toSnakeCase } from 'src/utils/caseMappers';
import stringify from 'src/utils/stringify';

import i18next from 'src/utils/i18n';
import { showDeclaration } from './contract_declarations';
import { paginate, sortConsents } from './utils';

import apiRequest from './apiRequest';
import pagingParams from './pagingParams';
import EP from './endpoints';

export const parseContract = ({ id, attributes, relationships }) => {
  const { date, identifier, validUntil, type, test } = toCamelCase(attributes);
  const processor = get(relationships, 'processor.data.id', null);
  const declarationId = get(toCamelCase(relationships), 'contractDeclaration.data.id', null);

  return {
    id,
    identifier,
    date,
    test,
    processor,
    status: type === 'valid',
    validUntil,
    declarationId,
  };
};

export const parseIncludedProcessors = included =>
  included
    .filter(item => item.type === 'processor')
    .reduce((memo, { id, attributes: { name } }) => ({ ...memo, [id]: name }), {});

export const parseIncludedContractDeclarations = included =>
  included.reduce(
    (memo, { id, attributes: { title }, type }) =>
      type === 'contract_declaration' ? { ...memo, [id]: title } : memo,
    {},
  );

export const parseContractWithoutId = (data, included) => {
  const pdcs = data.attributes.personal_data_categories || [];
  const { attributes: processorAttributes } = included.find(
    item => item.type === 'processor' && item.id === data.relationships.processor.data.id,
  );

  return {
    purpose: data.attributes.purpose,
    validUntil: data.attributes.valid_until,
    processor: toCamelCase(processorAttributes),
    purposeTitle: i18next.t('contract_declaration.purpose_title'),
    contractValidTitle: i18next.t('contract_declaration.contract_valid_title'),
    processingOfDataTitle: i18next.t('contract_declaration.processing_of_data_title'),
    processorTitle: i18next.t('contract_declaration.processor_title'),
    withdrawalTitle: i18next.t('contract_declaration.withdrawal_title'),
    companyDetailsTitle: i18next.t('contract_declaration.company_details_title'),
    processorDescription: i18next.t('contract_declaration.processor_description'),
    validityIndefiniteTitle: i18next.t('shared.indefinite'),
  };
};

const revokeBody = id => ({
  data: {
    type: 'revocation',
    attributes: {
      reason: 'admin',
    },
    relationships: {
      contracts: {
        data: [
          {
            type: 'contract',
            id,
          },
        ],
      },
    },
  },
});

export const getContracts = (page, type, sort, identifier, perPage) => {
  const apiUrl = identifier ? `${EP.contracts}/index_by_identifier` : EP.contracts;
  const queryParams = {
    ...pagingParams(page, perPage),
    type: type,
    order: stringify(toSnakeCase(sort)),
  };
  if (identifier) {
    queryParams.identifier = identifier;
  }

  return apiRequest(apiUrl, {
    query: queryParams,
  }).then(({ data, included, meta }) => {
    const processors = isEmpty(included) ? [] : parseIncludedProcessors(included);
    const contractDeclarations = isEmpty(included) ? [] : parseIncludedContractDeclarations(included);

    const contracts = data.map(parseContract).map(contract => ({
      ...contract,
      processor: processors[contract.processor],
      contractDeclarationName: contractDeclarations[contract.declarationId],
    }));

    return { contracts, meta: toCamelCase(meta) };
  });
};

export const getContractsFnForDeclaration = async declarationId => {
  const {
    meta: { totalEntries: totalAmountOfContracts },
  } = await getContracts(1, 'all', { date: 'asc' }, null, 1);

  if (totalAmountOfContracts === 0) {
    return () => ({
      contracts: [],
      meta: {
        currentPage: 1,
        perPage: 25,
        totalEntries: 0,
        totalPages: 1,
      },
    });
  }

  const allDeclarationContracts = await getContracts(
    1,
    'all',
    { date: 'asc' },
    null,
    totalAmountOfContracts,
  ).then(data => data.contracts.filter(c => c.declarationId === declarationId));

  return async (page, type, sort, identifier) => {
    let contracts = allDeclarationContracts;
    let totalEntries = contracts.length;

    if (identifier) {
      contracts = await getContracts(page, type, sort, identifier, totalAmountOfContracts).then(data =>
        data.contracts.filter(c => c.declarationId === declarationId),
      );
      totalEntries = contracts.length;
    } else {
      contracts = sortConsents(contracts, sort);
    }

    return {
      contracts: paginate(contracts, 25)[page - 1] || [],
      meta: {
        currentPage: page,
        perPage: 25,
        totalEntries,
        totalPages: Math.ceil(totalEntries / 25),
      },
    };
  };
};

export const showContract = id =>
  apiRequest(`${EP.contracts}/${id}`)
    .then(({ data, included }) => {
      const parsedContract = parseContract(data);

      return {
        ...parsedContract,
        declaration: parseContractWithoutId(data, included),
      };
    })
    .then(contract => {
      const { declarationId } = contract;

      if (declarationId) {
        return showDeclaration(contract.declarationId).then(declaration => ({
          ...contract,
          declaration,
        }));
      }
      return contract;
    });

export const revokeContract = id =>
  apiRequest(EP.contractRevocations, {
    body: revokeBody(id),
  });

export const paginate = (arr, size) => {
  const pages = [];

  arr.forEach((val, i) => {
    const idx = Math.floor(i / size);
    const page = pages[idx] || [];
    pages[idx] = [...page, val];
  }, []);

  return pages;
};

export const sortConsents = (arr, sort) => {
  let sorted = [];

  if (sort.processor) {
    sorted = arr.sort(
      (a, b) => `${a.processor}`.localeCompare(b.processor) * (sort.processor === 'asc' ? 1 : -1),
    );
  }

  if (sort.date) {
    sorted = arr.sort(
      (a, b) =>
        sort.date === 'asc'
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date),
    );
  }

  if (sort.valid_until) {
    sorted = arr.sort(
      (a, b) =>
        sort.valid_until === 'asc'
          ? new Date(a.validUntil) - new Date(b.validUntil)
          : new Date(b.validUntil) - new Date(a.validUntil),
    );
  }

  if (sort.type) {
    sorted = arr.sort((a, b) => a.status - b.status);
    if (sort.type === 'asc') sorted = sorted.reverse();
  }

  return sorted;
};

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NavLink, Link } from 'react-router-dom';

import Trans from 'src/components/shared/Trans/Trans';
import ProfileIcon from 'src/components/image/ProfileIcon';
import withSession from 'src/components/AuthContext/withSession/withSession';
import { authPropTypes } from 'src/prop_types/auth_prop_types';
import { topbarUrlsPropTypes } from 'src/prop_types/topbarUrlsPropTypes';

import urls from 'src/constants/urls';

import styles from './MobileMenu.module.scss';

const MobileMenu = ({
  open,
  toggleMobileMenu,
  topbarUrls,
  auth: { handleLogout, userDetails },
}) => {
  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 27) {
      toggleMobileMenu();
    }
  };

  const logout = e => {
    e.preventDefault();
    toggleMobileMenu(e);
    handleLogout();
  };

  return (
    <div
      className={cn(styles.mobileMenu, { [styles.mobileMenuOpen]: open })}
      onKeyDown={onKeyDown}
      role="presentation"
    >
      <button type="button" className={styles.overlay} onClick={toggleMobileMenu} />

      <nav className={styles.nav}>
        {topbarUrls.map(({ to, text }) => (
          <NavLink
            key={to}
            to={to}
            className={cn(styles.navButton, styles.navButtonHoverable)}
            activeClassName={cn(styles.navButtonCurrent)}
            onClick={toggleMobileMenu}
          >
            <Trans>{text}</Trans>
          </NavLink>
        ))}

        <ul className={styles.options}>
          <li className={styles.navButton}>
            <ProfileIcon className={styles.profile} />
            <span className={styles.email}>{userDetails.email}</span>
          </li>
          <li>
            <NavLink
              to={urls.settings()}
              className={cn(styles.navButton, styles.navButtonHoverable)}
              activeClassName={cn(styles.navButtonCurrent)}
              onClick={toggleMobileMenu}
            >
              <Trans>topbar.profile_dropdown.settings</Trans>
            </NavLink>
          </li>

          <li>
            <Link
              to={urls.logout()}
              className={cn(styles.navButton, styles.navButtonHoverable)}
              onClick={logout}
            >
              <Trans>topbar.profile_dropdown.logout</Trans>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

MobileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
  topbarUrls: topbarUrlsPropTypes.isRequired,
  auth: authPropTypes.auth.isRequired,
};

export default withSession(MobileMenu);

import React from 'react';
import source from 'images/no_processors.svg';

import Icon from './Icon';

const NoProcessorsImage = ({ ...props }) => (
  <Icon alt="create controllers" src={source} {...props} />
);

export default NoProcessorsImage;

import React from 'react';
import styles from './HowWorksStep.module.scss';

const Separator = () => (
  <div className={styles.separatorWrapper}>
    <div className={styles.separator} />
  </div>
);

export default Separator;

import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { I18nextProvider } from 'react-i18next';
import i18n from 'src/utils/i18n';
import { createSession, destroySession } from 'src/api/auth';

import AuthProvider from 'src/components/AuthContext/AuthContext';
import ToastProvider from 'src/components/Toast/ToastProvider';
import TooltipProvider from 'src/components/TooltipProvider/TooltipProvider';

import Topbar from 'src/components/shared/Topbar/Topbar';

import { topbarUrls } from 'src/constants/topbarUrls';

import Routes from 'src/Routes';
import FloatingEmail from '../shared/FloatingEmail';

const Root = () => (
  <ToastProvider>
    <I18nextProvider i18n={i18n}>
      <AuthProvider createSession={createSession} destroySession={destroySession}>
        <BrowserRouter>
          <Fragment>
            <Topbar topbarUrls={topbarUrls} />
            <Routes />
            <FloatingEmail />
            <TooltipProvider />
          </Fragment>
        </BrowserRouter>
      </AuthProvider>
    </I18nextProvider>
  </ToastProvider>
);

export default Root;

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import ThirdParty from './ThirdParty/ThirdParty';

const ThirdPartiesField = ({ name }) => (
  <Field {...{ name }}>
    {({ input: { onChange, value: thirdParties } }) => (
      <ThirdParty {...{ onChange, thirdParties }} />
    )}
  </Field>
);

ThirdPartiesField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ThirdPartiesField;

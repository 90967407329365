import React from 'react';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';

import './TooltipProvider.scss';

const ReactHint = ReactHintFactory(React);

const TooltipProvider = () => <ReactHint autoPosition events delay={100} />;

export default TooltipProvider;

import React from 'react';
import cn from 'classnames';
import copy from 'clipboard-copy';

import Highlight, { Prism } from 'prism-react-renderer';
import theme from 'src/constants/prismTheme';
import Button from 'src/components/shared/Button/Button';

import Trans from 'src/components/shared/Trans/Trans';

import styles from './Code.module.scss';

const Code = ({ children, className, inline, language, onCopySuccess, onCopyFail, ...rest }) => {
  const onCopy = () => {
    copy(children)
      .then(onCopySuccess)
      .catch(onCopyFail);
  };

  return inline ? (
    <code {...rest} className={cn(styles.inlineCode, className)}>
      {children}
    </code>
  ) : (
    <Highlight Prism={Prism} theme={theme} code={children} language={language}>
      {({ className: prismClassName, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={`${prismClassName} ${styles.code}`} style={style}>
          <Button onClick={onCopy} type="button" kind="outline" className={styles.copyButton}>
            <Trans noMd>embed_page.copy_button</Trans>
          </Button>

          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => <span {...getTokenProps({ token, key })} />)}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};

export default Code;

import React from 'react';
import source from 'images/hero_bg.svg';

import Icon from './Icon';

const HeroBg = ({ ...props }) => (
  <Icon alt="Hero" src={source} width="314" height="303" {...props} />
);

export default HeroBg;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';

import Switch from 'src/components/shared/Switch/Switch';
import Label from 'src/components/shared/Label/Label';

import styles from './ConditionalFormPart.module.scss';

const ConditionalFormPart = ({ className, name, description, children, label, ...props }) => (
  <div className={cn(className, styles.conditional)}>
    <Label>{label}</Label>

    <Field name={name} type="checkbox">
      {({ input: { value, onChange } }) => (
        <Fragment>
          <div className={styles.checkboxControl}>
            <Switch checked={value} onChange={onChange} {...props} />

            <span className={styles.mini}>{description}</span>
          </div>

          {value && children}
        </Fragment>
      )}
    </Field>
  </div>
);

ConditionalFormPart.defaultProps = {
  className: '',
  description: '',
  label: '',
};

ConditionalFormPart.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
};

export default ConditionalFormPart;

import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
import trim from 'lodash/trim';
import { isWebUri } from 'valid-url';
import i18next from 'i18next';

import { taxFormats } from 'src/constants/taxFormats';

export const reduceValidators = validators => value =>
  validators.reduce((errors, validator) => errors || validator(value), undefined);

export const isRequired = value => {
  if (
    (isString(value) && isEmpty(trim(value))) ||
    (!isBoolean(value) && !isNumber(value) && isEmpty(value)) ||
    (isBoolean(value) && value === false)
  ) {
    return 'error_messages.required';
  }

  return undefined;
};

export const isTaxNumber = value => {
  const countryCode = value.substring(0, 2);
  if (Object.keys(taxFormats).includes(countryCode)) {
    return !value || new RegExp(taxFormats[countryCode]).test(value)
      ? undefined
      : 'error_messages.invalid_tax';
  }
  return undefined;
};

export const isPhone = value =>
  !value || new RegExp(/^(?:\+?\d{1,3}\s*-?)?\(?(?:\d{3})?\)?[- ]?\d{2,3}[- ]?\d{2,4}$/).test(value)
    ? undefined
    : 'error_messages.invalid_phone';

export const isEmail = value =>
  !value ||
  new RegExp(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  ).test(value)
    ? undefined
    : 'error_messages.email';

export const isURL = value => (isWebUri(value) !== undefined ? undefined : 'error_messages.url');

export const isOnlyLatinChars = value =>
  // eslint-disable-next-line no-control-regex
  !new RegExp(/[^\u0000-\u007f]/).test(value) ? undefined : 'error_messages.non_latin_chars';

export const maxLength = max => value =>
  value.length <= max ? undefined : 'error_messages.max_length_exceeded';

export const amountValidator = (unit, maxYearValidConsentPeriod = 3) => value => {
  if (!value) return undefined;

  const maxDaysValidConsentPeriod = maxYearValidConsentPeriod * 365;

  const validatorObj = {
    days: 1,
    weeks: 7,
    months: 30,
    years: 365,
  };

  if (value * validatorObj[unit] > maxDaysValidConsentPeriod) {
    return i18next.t('error_messages.consent_valid_until_exceeded', { maxYearValidConsentPeriod });
  }

  return undefined;
};

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import DeclarationEditableText from '../DeclarationEditableText/DeclarationEditableText';
import styles from './DeclarationHeader.module.scss';

const DeclarationHeader = ({ isEditable, name, initialValue, placeholder, className }) => (
  <DeclarationEditableText
    isEditable={isEditable}
    name={name}
    className={cn(styles.h3, className)}
    iconClassName={styles.editIcon}
    initialValue={initialValue}
    placeholder={placeholder}
  />
);

DeclarationHeader.defaultProps = {
  className: '',
};

DeclarationHeader.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  initialValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default DeclarationHeader;

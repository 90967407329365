import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import last from 'lodash/last';

import { withNamespaces } from 'react-i18next';
import Trans from 'src/components/shared/Trans/Trans';
import withSort from 'src/components/shared/withSort/withSort';
import withToast from 'src/components/Toast/withToast/withToast';

import { createDeclaration, getDeclarations } from 'src/api/consent_declarations';
import Button from 'src/components/shared/Button/Button';
import DeclarationFormModal from 'src/components/shared/DeclarationFormModal/DeclarationFormModal';
import Loading from 'src/components/shared/Loading/Loading';
import Pagination from 'src/components/shared/Pagination/Pagination';
import Title from 'src/components/shared/Title/Title';
import urls from 'src/constants/urls';
import SelectField from 'src/components/shared/SelectField/SelectField';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';

import DeclarationsList from './DeclarationsList/DeclarationsList';
import NoDeclarations from './NoDeclarations/NoDeclarations';
import i18next from 'src/utils/i18n';

import styles from './ConsentDeclarationsPage.module.scss';

class ConsentDeclarationsPage extends Component {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    openToast: PropTypes.func.isRequired,
    type: PropTypes.object,
    sortBy: PropTypes.string.isRequired,
    sortMode: PropTypes.string.isRequired,
    changeSort: PropTypes.func.isRequired,
  };

  state = {
    currentPage: 1,
    totalPages: 1,
    isLoading: true,
    type: {
      value: "all",
      label: i18next.t("shared.type.all"),
    },
    declarations: [],
  };

  componentDidMount() {
    const { currentPage, type } = this.state;

    this.getPageOfDeclarations(currentPage, type);
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const { currentPage, type } = this.state;

    if (props.sortBy !== prevProps.sortBy || props.sortMode !== prevProps.sortMode) {
      this.getPageOfDeclarations(currentPage, type);
    }
  }

  onNextClick = () => {
    const { currentPage, totalPages, type } = this.state;

    if (currentPage < totalPages) {
      this.setState({
        isLoading: true,
      });

      this.getPageOfDeclarations(currentPage + 1, type);
    }
  };

  onPrevClick = () => {
    const { currentPage, type } = this.state;

    if (currentPage > 1) {
      this.setState({
        isLoading: true,
      });

      this.getPageOfDeclarations(currentPage - 1, type);
    }
  };

  onSubmit = ({ id, ...declaration }) => {
    const { currentPage, type } = this.state;
    const { openToast, t } = this.props;

    return createDeclaration(declaration)
      .then(() => this.getPageOfDeclarations(currentPage, type))
      .then(this.closeModal)
      .catch(() => {
        openToast({
          message: t('error_messages.consent_declaration_submit'),
          type: 'danger',
          inModal: true,
        });
      });
  };

  getPageOfDeclarations = (page, type) => {
    const { openToast, t, sortBy, sortMode } = this.props;

    getDeclarations(page, type.value, {
      [sortBy]: sortMode.toLowerCase(),
    })
      .then(({ declarations, meta: { totalPages, currentPage } }) =>
        this.setState({ declarations, currentPage, totalPages, isLoading: false }),
      )
      .catch(() => {
        openToast({
          message: t('consent_declarations_page.errors.fetch_declaration'),
          type: 'danger',
        });
      });
  };

  closeModal = () => {
    const { history } = this.props;
    history.push(urls.declarations());
  };

  openModal = () => {
    const { history } = this.props;
    history.push(urls.declarations('new'));
  };

  onChangeType = (newType, changeCb) => {
    this.setState({type: newType, currentPage: 1})
    this.getPageOfDeclarations(1, newType)
    changeCb(newType)
  }

  render() {
    
    const { declarations, isLoading, totalPages, currentPage } = this.state;
    const { t, changeSort, sortBy, sortMode } = this.props;
    const { openModal, onPrevClick, onNextClick, closeModal, onSubmit, onChangeType } = this;
    const modalOpen = last(get(this.props, 'match.url', '').split('/')) === 'new';
    const typeOptions = [{value: "all", label: i18next.t("shared.type.all") }, {value: "standard", label: i18next.t("shared.type.standard") }, {value: "test", label: i18next.t("shared.type.test")}]

    return (
      <main className={styles.consentDeclarationsPage}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Title title={t('shared.consent_declarations')}>
              <Button kind="primary" onClick={openModal} type="button">
                <Trans>consent_declarations_page.create_declaration_button</Trans>
              </Button>
            </Title>

            <section className={styles.filterWrapper}>
              <Form className={styles.form} onSubmit={()=>{}} initialValues={{ type: this.state.type }}>
                {({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <SelectField
                      label={t('shared.sandbox_header')}
                      name="type"
                      options={typeOptions}
                      showSelected
                      required=""
                      onChange={onChangeType}
                      hideCheckmark
                    />
                  </form>
                )}
              </Form>
            </section>

            <section
              className={cn(styles.content, {
                [styles.centered]: isEmpty(declarations),
              })}
            >
              {isEmpty(declarations) ? (
                <NoDeclarations />
              ) : (
                <Fragment>
                  <DeclarationsList
                    declarations={declarations}
                    changeSort={changeSort}
                    sortBy={sortBy}
                    sortMode={sortMode}
                  />
                  <Pagination
                    onPrevClick={onPrevClick}
                    onNextClick={onNextClick}
                    totalPages={totalPages}
                    currentPage={currentPage}
                  />
                </Fragment>
              )}
            </section>

            <DeclarationFormModal
              open={modalOpen}
              onClose={closeModal}
              onSubmit={onSubmit}
              declaration={{ id: 'new_declaration', active: true, required: true }}
            />
          </Fragment>
        )}
      </main>
    );
  }
}

export default withRouter(
  withNamespaces()(
    withToast(
      withSort({
        sortBy: 'updatedAt',
        sortMode: 'desc',
        reversedDefaults: ['updatedAt'],
      })(ConsentDeclarationsPage),
    ),
  ),
);

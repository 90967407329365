const urls = {
  settings: '/settings',
  landing: '/landing',
  signIn: '/landing/sign_in',
  users: '/manage_users',
  organizations: '/organizations',
  organizationUser: '/user',
  passwordReset: '/reset_password',
};

const buildUrl = (url, id) => url + (id ? `/${id}` : '');

const landing = () => urls.landing;
const signIn = () => urls.signIn;
const users = id => buildUrl(urls.users, id);
const organizations = id => buildUrl(urls.organizations, id);
const settings = () => urls.settings;
const organizationUser = (orgId, userId) =>
  `${buildUrl(urls.organizations, orgId)}${buildUrl(urls.organizationUser, userId)}}`;
const passwordReset = () => urls.passwordReset;

export default {
  landing,
  signIn,
  users,
  organizations,
  settings,
  organizationUser,
  passwordReset,
};

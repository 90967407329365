import React from 'react';
import PropTypes from 'prop-types';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import { Field } from 'react-final-form';
import cn from 'classnames';
import { withNamespaces } from 'react-i18next';

import Trans from 'src/components/shared/Trans/Trans';

import Button from 'src/components/shared/Button/Button';

import styles from './PdcInlineForm.module.scss';

const PdcInlineForm = ({ id, name, onSubmit, onCancel, className, onFocus, onBlur, t }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={{ name, id }}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} className={cn(className, styles.form)}>
        <Field
          name="name"
          component="input"
          className={styles.editField}
          type="text"
          autoFocus
          placeholder={t('profile_page.personal_data_categories.new_pdc_placeholder')}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        <span className={styles.editButtons}>
          <Button kind="ghost" onClick={onCancel} type="button">
            <Trans>shared.cancel</Trans>
          </Button>
          <Button kind="ghostPrimary" type="submit">
            <Trans>shared.save</Trans>
          </Button>
        </span>
      </form>
    )}
  />
);

PdcInlineForm.defaultProps = {
  id: null,
  name: null,
  className: '',
  onFocus: () => {},
  onBlur: () => {},
};

PdcInlineForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(PdcInlineForm);

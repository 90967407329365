import React from 'react';
import source from 'images/how_second.svg';

import Icon from './Icon';

const HowSecond = ({ ...props }) => (
  <Icon alt="How second" src={source} width="130" height="128" {...props} />
);

export default HowSecond;

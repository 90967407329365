import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from 'src/components/shared/Card/Card';
import Trans from 'src/components/shared/Trans/Trans';

import styles from './DashboardCard.module.scss';

const DashboardCard = ({ title, main, to, label, subtitle, showAddedToday, consentsAddedToday }) => (
  <Card component="div" className={styles.card}>
    <div className={styles.content}>
      {main}
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
      
      {consentsAddedToday > 0 && (
        <div style={{ display: 'inherit'}}>
          {showAddedToday && (
            <div style={{ display: 'inherit', color: '#578f57' }}>
              <Trans>dashboard_page.consents.added_today</Trans>
              <span>: +{consentsAddedToday}</span>  
            </div> 
          )}
        </div>
      )}
    </div>

    <Link className={styles.button} to={to}>
      {label}
    </Link>
  </Card>
);

DashboardCard.defaultProps = {
  subtitle: '',
};

DashboardCard.propTypes = {
  title: PropTypes.string.isRequired,
  main: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default DashboardCard;

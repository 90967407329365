const base = '/api/admin';

export default {
  declarations: `${base}/consent_declarations`,
  consents: `${base}/consents`,
  contractDeclarations: `${base}/contract_declarations`,
  contracts: `${base}/contracts`,
  revocations: `${base}/revocations`,
  contractRevocations: `${base}/contract_revocations`,
  processors: `${base}/controllers`,
  users: `${base}/users`,
  roles: `${base}/roles`,
  dataCategories: `${base}/personal_data_categories`,
  invitations: `${base}/invitations`,
  dashboard: `${base}/dashboard`,
  session: `${base}/session`,
  password: `${base}/password`,
  registration: `${base}/registration`,
  profile: `${base}/profile`,
  organization: `${base}/organization`,
  declarationWithToken: `/api/consent_declarations`,
  contractDeclarationWithToken: `/api/contract_declarations`,
};

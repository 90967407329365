import { error, log } from './logger';

import './elements.scss';

export default ({
  api,
  cssPath,
  customElement = document.querySelector('#traq-container'),
  customForm,
  captureSubmit,
} = {}) => {
  const widgetData = api.getWidgetData();

  if (cssPath) {
    const $cssLink = document.createElement('link');
    $cssLink.setAttribute('rel', 'stylesheet');
    $cssLink.setAttribute('href', cssPath);
    document.head.appendChild($cssLink);
  }

  const checkboxName = 'traq-acceptance-box';
  const $container = customElement;
  const $form = customForm || $container.closest('form');

  if (!$container) {
    return error('Could not find element with id "traq-container"');
  }

  if (!widgetData === undefined) {
    return error('Widget data was not provided');
  }

  const { identifier } = widgetData;

  if (captureSubmit && !identifier && !$form) {
    return error('Could not find form element');
  }

  $container.innerHTML = '';

  $container.classList.add('traq__box');
  $container.style.display = 'none';

  const $checkboxContainer = document.createElement('div');
  $checkboxContainer.classList.add('traq__box__space-between');

  const $checkbox = document.createElement('input');
  $checkbox.classList.add('traq__checkbox');
  $checkbox.setAttribute('type', 'checkbox');
  $checkbox.setAttribute('id', checkboxName);

  if (!identifier) {
    $checkbox.setAttribute('required', true);
  }

  const $label = document.createElement('span');
  $label.classList.add('traq__box__label');
  $label.innerText = 'required';

  $checkboxContainer.appendChild($checkbox);
  $checkboxContainer.appendChild($label);

  $container.appendChild($checkboxContainer);

  const $acceptanceText = document.createElement('div');
  $acceptanceText.classList.add('traq__box__description');
  $acceptanceText.innerText = '';

  const $declarationLink = document.createElement('a');
  $declarationLink.classList.add('traq__box__link');
  $declarationLink.innerText = 'Read full consent declaration';
  $declarationLink.setAttribute('target', '_blank');
  $declarationLink.setAttribute('href', api.getConsentDeclarationUrl());

  $container.appendChild($acceptanceText);
  $container.appendChild($declarationLink);

  api.getConsentDeclaration().then(response => {
    $acceptanceText.innerText = response.body;
  });

  const submit = () => {
    if (captureSubmit) {
      $form.submit();
    }
  };

  $checkbox.checked = widgetData.consent_valid;

  const submitForm = () => {
    const submitWidgetData = api.getWidgetData();
    const wasConsentGiven = submitWidgetData.consent_valid;

    if (submitWidgetData === undefined) {
      return Promise.reject(error('Widget data was not provided'));
    }

    if (wasConsentGiven && !$checkbox.checked) {
      /* eslint-disable no-alert, no-restricted-globals, consistent-return */
      const shouldRevoke = confirm(
        'Disabling the consent switch will mean revoking your consent for processing your personal data. Do you want to do this?',
      );

      if (!shouldRevoke) {
        $checkbox.checked = true;
        return;
      }

      return api.revokeConsent().then(submit);
    }

    if (!wasConsentGiven && $checkbox.checked) {
      return api.createConsent().then(submit);
    }

    return Promise.resolve(submit());
  };

  if (captureSubmit) {
    $form.addEventListener('submit', e => {
      e.preventDefault();

      return submitForm();
    });
  }
  /* eslint-enable */

  log('Widget rendered successfully');

  return submitForm;
};

import React, { Component } from 'react';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import DefaultFooter from './DefaultFooter/DefaultFooter';

export default class MulitPageForm extends Component {
  static Page = ({ children, component: Comp = 'div', ...props }) => (
    <Comp {...props}>{children}</Comp>
  );

  static defaultProps = {
    footer: DefaultFooter,
    header: null,
  };

  state = {
    page: 0,
  };

  getChildren = () => {
    const { children } = this.props;
    return React.Children.toArray(children);
  };

  getCurrentPage = () => {
    const { getChildren } = this;
    const { page } = this.state;

    const currentPage = getChildren()[page];

    return currentPage;
  };

  goToNextPage = () => {
    this.setState(prevState => ({
      page: prevState.page + 1,
    }));
  };

  goToPreviousPage = () => {
    this.setState(prevState => ({
      page: prevState.page - 1,
    }));
  };

  onSubmit = (values, form) => {
    const { page } = this.state;
    const { getChildren, goToNextPage } = this;
    const { onSubmit } = this.props;
    const totalPages = getChildren().length;

    if (page < totalPages - 1) {
      goToNextPage();
      return undefined;
    }

    return onSubmit(values, form);
  };

  render() {
    const { getCurrentPage, goToPreviousPage, getChildren, onSubmit } = this;
    const { page } = this.state;
    const {
      footer: Footer,
      header: Header,
      initialValues,
      onSubmit: propsSubmit,
      ...props
    } = this.props;
    const currentPage = getCurrentPage();
    const totalPages = getChildren().length;

    return (
      <Form onSubmit={onSubmit} initialValues={initialValues} {...props}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {Header && (
              <Header
                {...{
                  goToPreviousPage,
                  currentPage: page + 1,
                  totalPages,
                }}
              />
            )}
            {currentPage}
            {Footer && (
              <Footer
                {...{
                  goToPreviousPage,
                  currentPage: page + 1,
                  totalPages,
                }}
              />
            )}
          </form>
        )}
      </Form>
    );
  }
}

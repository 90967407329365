import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import last from 'lodash/last';
import { withNamespaces } from 'react-i18next';

import Trans from 'src/components/shared/Trans/Trans';

import { getProcessors, createProcessor } from 'src/api/processors';

import Button from 'src/components/shared/Button/Button';
import Loading from 'src/components/shared/Loading/Loading';
import Pagination from 'src/components/shared/Pagination/Pagination';
import ProcessorFormModal from 'src/components/shared/ProcessorFormModal/ProcessorFormModal';
import Title from 'src/components/shared/Title/Title';
import urls from 'src/constants/urls';

import NoProcessors from './NoProcessors/NoProcessors';
import ProcessorsList from './ProcessorsList/ProcessorsList';

import styles from './ProcessorsPage.module.scss';

class ProcessorsPage extends Component {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    currentPage: 1,
    totalPages: 1,
    processors: [],
    isLoading: true,
    lastSubmittedProcessorName: '',
  };

  componentDidMount() {
    const { currentPage } = this.state;

    this.getPageOfProcessors(currentPage);
  }

  onNextClick = () => {
    const { currentPage, totalPages } = this.state;

    if (currentPage < totalPages) {
      this.getPageOfProcessors(currentPage + 1);
    }
  };

  onPrevClick = () => {
    const { currentPage } = this.state;

    if (currentPage > 1) {
      this.getPageOfProcessors(currentPage - 1);
    }
  };

  onSubmit = processor =>
    new Promise(resolve => {
      const { t } = this.props;
      const { currentPage } = this.state;

      createProcessor(processor)
        .then(() => {
          this.setState({
            lastSubmittedProcessorName: processor.name,
          });

          this.getPageOfProcessors(currentPage).then(() => this.closeModal());
          return resolve(undefined);
        })
        .catch(() => {
          resolve({
            name: t('error_messages.processor_name_already_exists'),
          });
        });
    });

  getPageOfProcessors = page =>
    getProcessors(page).then(({ processors, meta: { currentPage, totalPages } }) =>
      this.setState({ processors, currentPage, totalPages, isLoading: false }),
    );

  closeModal = () => {
    const { history } = this.props;

    history.push(urls.processors());
  };

  openModal = () => {
    const { history } = this.props;

    history.push(urls.processors('new'));
  };

  render() {
    const {
      isLoading,
      processors,
      totalPages,
      lastSubmittedProcessorName,
      currentPage,
    } = this.state;
    const { onNextClick, onPrevClick } = this;
    const { t } = this.props;

    const modalOpened = last(get(this.props, 'match.url', '').split('/')) === 'new';

    return (
      <main className={styles.processorsPage}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Title title={t('shared.processors')}>
              <Button kind="primary" onClick={this.openModal} type="button">
                <Trans>shared.new_processor</Trans>
              </Button>
            </Title>

            <section className={styles.content}>
              {isEmpty(processors) ? (
                <NoProcessors />
              ) : (
                <Fragment>
                  <ProcessorsList {...{ processors }} />
                  <Pagination
                    onPrevClick={onPrevClick}
                    onNextClick={onNextClick}
                    totalPages={totalPages}
                    currentPage={currentPage}
                  />
                </Fragment>
              )}
            </section>

            <ProcessorFormModal
              open={modalOpened}
              onSubmit={this.onSubmit}
              onClose={this.closeModal}
              lastSubmittedProcessorName={lastSubmittedProcessorName}
            />
          </Fragment>
        )}
      </main>
    );
  }
}

export default withRouter(withNamespaces()(ProcessorsPage));

import React from 'react';
import PropTypes from 'prop-types';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import { withNamespaces } from 'react-i18next';

import WarningModal from 'src/components/shared/WarningModal/WarningModal';
import PasswordField from 'src/components/shared/PasswordField/PasswordField';

import styles from './ConfirmPasswordModal.module.scss';

const ConfirmPasswordModal = ({ onAccept, t, open, onClose, title, acceptLabel, children }) =>
  open && (
    <Form
      onSubmit={onAccept}
      initialValues={{}}
      render={({ handleSubmit }) => (
        <WarningModal
          title={title}
          open
          onClose={onClose}
          onAccept={handleSubmit}
          acceptLabel={acceptLabel}
        >
          <form onSubmit={handleSubmit}>
            <div className={styles.gap}>{children}</div>

            <PasswordField name="passwordConfirmation" label={t('shared.password')} required />
          </form>
        </WarningModal>
      )}
    />
  );

ConfirmPasswordModal.propTypes = {
  t: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  acceptLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default withNamespaces()(ConfirmPasswordModal);

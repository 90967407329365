import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import cn from 'classnames';

import { withNamespaces } from 'react-i18next';

import DoubleArrow from 'src/components/image/DoubleArrow';

import styles from './Select.module.scss';

const equalsLocale = (baseString, searchString) =>
  baseString.toLocaleLowerCase().localeCompare(searchString.toLocaleLowerCase(), 'en', {
    sensitivity: 'base',
  }) === 0;

const statsWithLocale = (baseString, searchString) =>
  equalsLocale(baseString.substring(0, searchString.length), searchString);

class Select extends PureComponent {
  dropdown = React.createRef();

  searchString = '';

  searchStringTimeout = null;

  searchOffset = 0;

  static propTypes = {
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    showSelected: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  };

  static defaultProps = {
    showSelected: false,
    placeholder: null,
    className: '',
    value: {},
    id: null,
  };

  state = {
    onTop: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const {
      dropdown: { current: dropdown },
    } = this;
    const { onTop } = this.state;

    if (!dropdown) {
      return null;
    }

    const { bottom } = dropdown.getBoundingClientRect();

    if (!onTop) {
      this.oldOnTop = bottom + window.scrollY;
    }

    if (window.scrollY + window.innerHeight > this.oldOnTop) {
      return this.setState({
        onTop: false,
      });
    }

    return this.setState({
      onTop: true,
    });
  };

  handleKeyDown = ({ selectItem, setHighlightedIndex, isOpen }) => e => {
    const { options } = this.props;
    const { oldSearchString } = this;
    const key = e.key.toLocaleLowerCase();

    const handleAction = index =>
      isOpen ? setHighlightedIndex(index) : selectItem(options[index]);

    if (!key || key.length > 1 || key === ' ') {
      return undefined;
    }

    clearTimeout(this.searchStringTimeout);

    if (this.searchString === key) {
      this.searchString = key;
    } else {
      this.searchString += key;
    }

    this.oldSearchString = this.searchString;

    this.searchStringTimeout = setTimeout(() => {
      this.searchString = '';
      this.searchOffset = 0;
      this.oldSearchString = '';
    }, 800);

    const optionsStartingWithString = options.reduce((acc, option, i) => {
      if (statsWithLocale(option.label, this.searchString)) {
        return [
          ...acc,
          {
            index: i,
            value: option,
          },
        ];
      }

      return acc;
    }, []);

    if (optionsStartingWithString.length < 1) {
      return undefined;
    }

    if (oldSearchString !== this.searchString) {
      return handleAction(optionsStartingWithString[0].index);
    }

    if (
      optionsStartingWithString[this.searchOffset + 1] &&
      statsWithLocale(
        optionsStartingWithString[this.searchOffset + 1].value.label,
        this.searchString,
      )
    ) {
      this.searchOffset += 1;
    } else {
      this.searchOffset = 0;
    }

    return handleAction(optionsStartingWithString[this.searchOffset].index);
  };

  onStateChange = ({ isOpen }) => {
    if (isOpen) {
      this.handleScroll();
    }
  };

  render() {
    const {
      onChange,
      options,
      placeholder,
      className,
      showSelected,
      value,
      id,
      onFocus,
      onBlur,
      t,
      autoFocus,
    } = this.props;

    const { onTop } = this.state;

    const { dropdown, onStateChange, handleKeyDown } = this;

    const translatedPlaceholder =
      placeholder === null ? t('shared.select_placeholder') : placeholder;

    return (
      <Downshift
        onChange={onChange}
        selectedItem={options.find(option => value.value === option.value) || null}
        defaultHighlightedIndex={options.findIndex(option => value.value === option.value)}
        itemToString={item => (item ? item.label : '')}
        onStateChange={onStateChange}
      >
        {({
          getItemProps,
          getToggleButtonProps,
          highlightedIndex,
          isOpen,
          selectedItem,
          selectItem,
          setHighlightedIndex,
        }) => (
          <div className={styles.wrapper} id={id}>
            <button
              {...getToggleButtonProps({
                tabIndex: '0',
                type: 'button',
                className: cn(styles.select, className, {
                  [styles['select--placeholder']]: !(showSelected && selectedItem),
                }),
                onClick: ({ currentTarget }) => currentTarget.focus(),
                onFocus,
                onBlur,
                onKeyDown: handleKeyDown({ selectItem, setHighlightedIndex, isOpen }),
              })}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              type="button"
            >
              <DoubleArrow />
              {showSelected && selectedItem ? selectedItem.label : translatedPlaceholder}
            </button>
            {!isOpen ? null : (
              <ul
                className={cn(styles.options, {
                  [styles['options--on-top']]: onTop,
                })}
                ref={dropdown}
              >
                {options.map((item, index) => (
                  <li
                    className={cn({
                      [styles.selected]: highlightedIndex === index,
                      [styles.item]: true,
                    })}
                    key={item.value}
                    {...getItemProps({
                      item,
                      index,
                    })}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </Downshift>
    );
  }
}

export default withNamespaces()(Select);

import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';

import { userCardPropTypes } from 'src/prop_types/user_prop_types';

import DateCard from 'src/components/shared/DateCard/DateCard';
import UserStatus from 'src/components/shared/UserStatus/UserStatus';

import urls from 'src/constants/urls';

import styles from './UsersList.module.scss';

const UsersList = ({ users, t }) => (
  <ul className={styles.usersList}>
    {users.map(({ id, name, email, lastSignInAt, status }) => (
      <li key={id}>
        <DateCard
          title={name}
          subtitle={email}
          date={lastSignInAt}
          dateTitle={t('shared.last_signed_in')}
          pillVisible={status !== 'active'}
          pillText={<UserStatus status={status} uppercase />}
          logoClassName={styles.userCardLogo}
          cardComponent={Link}
          dateFallback={t('shared.never')}
          cardProps={{
            to: urls.users(id),
          }}
        />
      </li>
    ))}
  </ul>
);

UsersList.defaultProps = {
  users: [],
};

UsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(userCardPropTypes)),
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(UsersList);

import { Component } from 'react';
import PropTypes from 'prop-types';

export default class WithSort extends Component {
  static propTypes = {
    initialValues: PropTypes.shape({
      sortBy: PropTypes.string.isRequired,
      sortMode: PropTypes.string.isRequired,
    }).isRequired,
    reversedDefaults: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.func.isRequired,
  };

  static defaultProps = {
    reversedDefaults: [],
  };

  state = {
    sortBy: this.props.initialValues.sortBy, // eslint-disable-line react/destructuring-assignment
    sortMode: this.props.initialValues.sortMode, // eslint-disable-line react/destructuring-assignment
  };

  changeSort = sortBy => () => {
    const { reversedDefaults } = this.props;

    this.setState(prevState => {
      if (prevState.sortBy === sortBy) {
        return {
          sortMode: prevState.sortMode === 'desc' ? 'asc' : 'desc',
        };
      }

      return {
        sortBy,
        sortMode: reversedDefaults.includes(sortBy) ? 'desc' : 'asc',
      };
    }, this.getPageOfDeclarations);
  };

  render() {
    const { sortBy, sortMode } = this.state;
    const { children } = this.props;
    const { changeSort } = this;

    return children({
      sortBy,
      sortMode,
      changeSort,
    });
  }
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import LandingNav from './LandingNav/LandingNav';
import styles from './LandingLayout.module.scss';

const LandingLayout = ({ left, right, nav }) => (
  <Fragment>
    {nav && <LandingNav />}
    <div className={styles.landingLayout}>
      {left && <div className={styles.landingLeft}>{left}</div>}

      {right && <div className={styles.landingRight}>{right}</div>}
    </div>
  </Fragment>
);

LandingLayout.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  nav: PropTypes.bool,
};

LandingLayout.defaultProps = {
  left: null,
  right: null,
  nav: true,
};

export default LandingLayout;

/* eslint-disable react/prop-types */
import React from 'react';

import Icon from 'src/components/image/Icon';

import benefit1 from 'images/benefit-01.svg';
import benefit2 from 'images/benefit-02.svg';
import benefit3 from 'images/benefit-03.svg';
import benefit4 from 'images/benefit-04.svg';
import benefit5 from 'images/benefit-05.svg';
import benefit6 from 'images/benefit-06.svg';

import styles from './Content.module.scss';

const Benefit = ({ imgSource, description }) => (
  <div className={styles.benefit}>
    <Icon alt="Benefit" src={imgSource} width="128" height="128" />
    <p className={styles.benefitDesc}>{description}</p>
  </div>
);

const Benefits = () => {
  const benefits = [
    {
      imgSource: benefit1,
      description: 'Cost-effective',
    },
    {
      imgSource: benefit2,
      description: 'Cloud-based',
    },
    {
      imgSource: benefit3,
      description: 'Easily integrated',
    },
    {
      imgSource: benefit4,
      description: 'GDPR-compliant',
    },
    {
      imgSource: benefit5,
      description: 'Centralised',
    },
    {
      imgSource: benefit6,
      description: 'Reduces risk of fines',
    },
  ];

  return (
    <div className={styles.benefits}>
      {benefits.map(benefit => (
        <Benefit
          key={benefit.description}
          imgSource={benefit.imgSource}
          description={benefit.description}
        />
      ))}
    </div>
  );
};

export default Benefits;

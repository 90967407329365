import React from 'react';
import source from 'images/envelope.svg';

import Icon from './Icon';

const Envelope = ({ ...props }) => (
  <Icon width="25" height="25" alt="envelope" src={source} {...props} />
);

export default Envelope;

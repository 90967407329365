import React from 'react';
import source from 'images/landing_section_2.svg';

import Icon from './Icon';

const LandingSectionSecond = ({ ...props }) => (
  <Icon alt="Landing section" src={source} width="360" height="467" {...props} />
);

export default LandingSectionSecond;

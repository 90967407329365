import PropTypes from 'prop-types';
import { declaration } from './declaration_prop_types';

const consent = {
  id: PropTypes.string,
  processor: PropTypes.string,
  date: PropTypes.string,
  validUntil: PropTypes.string,
  declarationId: PropTypes.string,
  status: PropTypes.bool,
  test: PropTypes.bool,
};

export const consentPropTypes = PropTypes.shape(consent);

export const consentWithDeclarationPropTypes = PropTypes.shape({
  ...consent,
  declaration,
});

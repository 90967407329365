import React from 'react';

import Trans from 'src/components/shared/Trans/Trans';
import SortArrows from 'src/components/shared/SortArrows/SortArrows';

import { declarationListPropTypes } from 'src/prop_types/declaration_prop_types';

import DeclarationRow from './DeclarationRow/DeclarationRow';

import styles from './DeclarationsList.module.scss';

const DeclarationsList = ({ sortBy, sortMode, declarations, changeSort }) => (
  <table className={styles.declarationsList}>
    <thead>
      <tr className={styles.titleRow}>
        <th>
          <button onClick={changeSort('created_by')} type="button">
            <Trans>shared.created_by</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="created_by" />
          </button>
        </th>
        <th>
          <Trans>contract_declarations_page.declarations_list.widget_id</Trans>
        </th>
        <th>
          <button onClick={changeSort('title')} type="button">
            <Trans>contract_declarations_page.declarations_list.title</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="title" />
          </button>
        </th>
        <th>
          <button onClick={changeSort('purpose')} type="button">
            <Trans>contract_declarations_page.declarations_list.purpose</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="purpose" />
          </button>
        </th>
        
        <th>
          <Trans>shared.sandbox_header</Trans>
        </th>
        <th className={styles.createdAt}>
          <button onClick={changeSort('updatedAt')} type="button">
            <Trans>shared.updated_at</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="updatedAt" />
          </button>
        </th>

        <th className={styles.action}></th>
      </tr>
    </thead>
    <tbody>
      {declarations.map(declaration => (
        <DeclarationRow key={declaration.id} declaration={declaration} />
      ))}
    </tbody>
  </table>
);

DeclarationsList.propTypes = {
  ...declarationListPropTypes,
};

export default DeclarationsList;

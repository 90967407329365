import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withNamespaces } from 'react-i18next';

import Columns from 'src/components/shared/Columns/Columns';
import CountriesField from 'src/components/shared/CountriesField/CountriesField';
import InputField from 'src/components/shared/InputField/InputField';
import { isEmail, isPhone, isTaxNumber } from 'src/utils/validators';

import styles from './OrganizationFields.module.scss';

const OrganizationFields = ({ t }) => (
  <Fragment>
    <Columns>
      {{
        left: <InputField name="company_name" required label={t('shared.company_name')} />,
        right: (
          <InputField
            name="company_email"
            label={t('shared.company_email')}
            validators={[isEmail]}
            required
          />
        ),
      }}
    </Columns>

    <InputField
      className={styles.row}
      label={t('shared.contact_person')}
      name="contact_person"
      type="text"
      required
    />

    <InputField
      className={styles.row}
      label={t('shared.phone_number')}
      name="contact_phone"
      type="text"
      validators={[isPhone]}
      required
    />

    <InputField
      className={styles.row}
      name="tax_number"
      validators={[isTaxNumber]}
      required
      label={t('shared.tax_number')}
    />

    <InputField className={styles.row} name="address_1" label={t('shared.address_1')} required />

    <InputField className={styles.row} name="address_2" label={t('shared.address_2')} />
    <Columns className={styles.row}>
      {{
        left: <CountriesField required name="country" />,
      }}
    </Columns>
  </Fragment>
);

OrganizationFields.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(OrganizationFields);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import get from 'lodash/get';

import { getData } from 'src/api/dashboard';
import Title from 'src/components/shared/Title/Title';
import Loading from 'src/components/shared/Loading/Loading';
import urls from 'src/constants/urls';
import getInitials from 'src/utils/getInitials';
import withSession from 'src/components/AuthContext/withSession/withSession';
import { getProfileDetails } from 'src/api/profile';

import DashboardCard from './DashboardCard/DashboardCard';

import styles from './DashboardPage.module.scss';

class DashboardPage extends Component {
  state = {
    consents: 0,
    consentsAddedToday: 0,
    consentDeclarations: 0,
    contracts: 0,
    contractDeclarations: 0,
    processors: 0,
    users: 0,
    isLoading: true,
    userFullName: get(this.props, 'auth.userDetails.fullName'),
  };

  componentDidMount() {
    getData().then(data => this.setState({ ...data, isLoading: false }));
    getProfileDetails().then(details =>
      this.setState({
        userFullName: details.attributes.full_name,
      }),
    );
  }

  render() {
    const {
      consents,
      consentsAddedToday,
      consentDeclarations,
      contracts,
      contractDeclarations,
      processors,
      users,
      isLoading,
      userFullName,
    } = this.state;
    const { t } = this.props;

    return (
      <main className={styles.dashboard}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Title title={`${t('dashboard_page.welcome_user', { userFullName })}`} />

            <div className={styles.cardsList}>
              <DashboardCard
                title={t('dashboard_page.user.title')}
                main={<div className={styles.initials}>{getInitials(userFullName)}</div>}
                subtitle={userFullName}
                label={t('dashboard_page.user.go_to_profile_button')}
                to={urls.settings()}
              />

              <DashboardCard
                title={t('shared.consent_declarations')}
                main={<h3>{consentDeclarations}</h3>}
                label={t('dashboard_page.consent_declarations.new_declaration_button')}
                to={urls.declarations('new')}
              />

              <DashboardCard
                title={t('shared.consents')}
                main={<h3>{consents}</h3>}
                showAddedToday={true}
                consentsAddedToday={consentsAddedToday}
                label={t('dashboard_page.consents.see_all_consents_button')}
                to={urls.consents()}
              />

              <DashboardCard
                title={t('shared.contract_declarations')}
                main={<h3>{contractDeclarations}</h3>}
                label={t('dashboard_page.contract_declarations.new_declaration_button')}
                to={urls.contractDeclarations('new')}
              />

              <DashboardCard
                title={t('shared.contracts')}
                main={<h3>{contracts}</h3>}
                label={t('dashboard_page.contracts.see_all_contracts_button')}
                to={urls.contracts()}
              />

              <DashboardCard
                title={t('shared.processors')}
                main={<h3>{processors}</h3>}
                label={t('shared.new_processor')}
                to={urls.processors('new')}
              />

              <DashboardCard
                title={t('dashboard_page.users.title', { count: users })}
                main={<h3>{users}</h3>}
                label={t('dashboard_page.users.invite_new_user_button')}
                to={urls.users('new')}
              />
            </div>
          </Fragment>
        )}
      </main>
    );
  }
}

DashboardPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withSession(withNamespaces()(DashboardPage));

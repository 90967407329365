import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from 'src/components/image/CloseIcon';

import styles from './Tag.module.scss';

const Tag = ({ children, onClick }) => (
  <button type="button" className={styles.tag} onClick={onClick}>
    <span className={styles.content}>{children}</span>
    <CloseIcon />
  </button>
);

Tag.defaultProps = {
  children: '',
  onClick: null,
};

Tag.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Tag;

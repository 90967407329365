import React from 'react';
import PropTypes from 'prop-types';
import Trans from 'src/components/shared/Trans/Trans';

import { contractPropTypes } from 'src/prop_types/contract_prop_types';

import SortArrows from 'src/components/shared/SortArrows/SortArrows';

import ContractRow from './ContractRow/ContractRow';

import styles from './ContractsList.module.scss';

const ContractsList = ({
  sortBy,
  sortMode,
  contracts,
  onSelectAll,
  onSelect,
  allSelected,
  changeSort,
}) => (
  <table className={styles.contractsList}>
    <thead>
      <tr className={styles.titleRow}>
        <th>
          <input type="checkbox" name="checkAll" checked={allSelected} onChange={onSelectAll} />
        </th>
        <th>
          <Trans>contracts_page.contracts_list.user_id</Trans>
        </th>
        <th>
          <button onClick={changeSort('processor')} type="button">
            <Trans>contracts_page.contracts_list.processor</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="processor" />
          </button>
        </th>
        <th>
          <Trans>contracts_page.contracts_list.contract_declaration</Trans>
        </th>
        <th>
          <button onClick={changeSort('date')} type="button">
            <Trans>shared.given_at</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="date" />
          </button>
        </th>
        <th>
          <button onClick={changeSort('valid_until')} type="button">
            <Trans>contracts_page.contracts_list.expiry_date</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="valid_until" />
          </button>
        </th>
        <th>
          <button onClick={changeSort('type')} type="button">
            <Trans>shared.status</Trans>
            <SortArrows {...{ sortBy, sortMode }} sortHandle="type" />
          </button>
        </th>

        <th>
          <Trans>shared.sandbox_header</Trans>
        </th>
        <th className={styles.action} />
      </tr>
    </thead>
    <tbody>
      {Object.values(contracts).map(({ selected, contract }) => (
        <ContractRow key={contract.id} onSelect={onSelect} selected={selected} contract={contract} />
      ))}
    </tbody>
  </table>
);

ContractsList.defaultProps = {
  contracts: {},
  onSelectAll: () => {},
  onSelect: () => {},
  allSelected: false,
  changeSort: () => {},
};

ContractsList.propTypes = {
  contracts: PropTypes.objectOf(
    PropTypes.shape({
      selected: PropTypes.bool,
      contract: contractPropTypes.isRequired,
    }),
  ),
  onSelectAll: PropTypes.func,
  onSelect: PropTypes.func,
  changeSort: PropTypes.func,
  allSelected: PropTypes.bool,
  sortBy: PropTypes.string.isRequired,
  sortMode: PropTypes.oneOf(['asc', 'desc']).isRequired,
};

export default ContractsList;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { withRouter, Route } from 'react-router-dom';

import { withNamespaces } from 'react-i18next';

import Trans from 'src/components/shared/Trans/Trans';

import { getUsers, createUser } from 'src/api/users';

import withToast from 'src/components/Toast/withToast/withToast';
import Button from 'src/components/shared/Button/Button';
import Pagination from 'src/components/shared/Pagination/Pagination';
import Loading from 'src/components/shared/Loading/Loading';
import Title from 'src/components/shared/Title/Title';
import urls from 'src/constants/urls';

import UsersList from './UsersList/UsersList';
import InviteUserModal from './InviteUserModal/InviteUserModal';

import styles from './UsersPage.module.scss';

class UsersPage extends Component {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    t: PropTypes.func.isRequired,
    openToast: PropTypes.func.isRequired,
  };

  state = {
    currentPage: 1,
    users: [],
    totalPages: 1,
    isLoading: true,
  };

  componentDidMount() {
    const { currentPage } = this.state;
    this.getPageOfUsers(currentPage);
  }

  onNextClick = () => {
    const { currentPage, totalPages } = this.state;

    if (currentPage < totalPages) {
      this.getPageOfUsers(currentPage + 1);
    }
  };

  onPrevClick = () => {
    const { currentPage } = this.state;

    if (currentPage > 1) {
      this.getPageOfUsers(currentPage - 1);
    }
  };

  onSubmit = user =>
    new Promise(resolve => {
      const { currentPage } = this.state;
      const { t, openToast } = this.props;

      return createUser({ ...user, role: user.role.value })
        .then(() => this.getPageOfUsers(currentPage))
        .then(() => {
          this.closeModal();
          resolve(undefined);
        })
        .catch(({ status }) => {
          if (status === 422) {
            return resolve({
              email: t('shared.invite_user_modal.errors.email_taken'),
            });
          }

          openToast({
            message: t('shared.invite_user_modal.errors.general'),
            inModal: true,
            type: 'danger',
          });

          return resolve({});
        });
    });

  getPageOfUsers = page =>
    getUsers(page).then(({ users, meta: { currentPage, totalPages } }) =>
      this.setState({ users, currentPage, totalPages, isLoading: false }),
    );

  openModal = () => {
    const { history } = this.props;
    history.push(urls.users('new'));
  };

  closeModal = () => {
    const { history } = this.props;
    history.push(urls.users());
  };

  render() {
    const { users, isLoading, totalPages, currentPage } = this.state;
    const { onPrevClick, onNextClick } = this;
    const { t } = this.props;

    return (
      <main className={styles.usersPage}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <Title title={t('shared.manage_users')}>
              <Button kind="primary" onClick={this.openModal} type="button">
                <Trans>users_page.new_user_button</Trans>
              </Button>
            </Title>

            <section className={styles.content}>
              <UsersList users={users} />

              <Pagination
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                totalPages={totalPages}
                currentPage={currentPage}
              />
            </section>

            <Route
              path={urls.users('new')}
              exact
              render={() => (
                <InviteUserModal isOpened onClose={this.closeModal} onSubmit={this.onSubmit} />
              )}
            />
          </Fragment>
        )}
      </main>
    );
  }
}

export default withToast(withRouter(withNamespaces()(UsersPage)));

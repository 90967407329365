import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ alt, src, className, ...props }) => (
  <img alt={alt} src={src} className={className} {...props} />
);

Icon.defaultProps = {
  className: '',
};

Icon.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Icon;

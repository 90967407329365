import React from 'react';
import PropTypes from 'prop-types';

import LeftArrow from 'src/components/image/LeftArrow';
import RightArrow from 'src/components/image/RightArrow';
import Button from 'src/components/shared/Button/Button';

import styles from './Pagination.module.scss';

const Pagination = ({ onPrevClick, onNextClick, currentPage, totalPages }) =>
  totalPages > 1 && (
    <div className={styles.pagination}>
      <Button
        kind="outline"
        className={styles.left}
        onClick={onPrevClick}
        type="button"
        disabled={currentPage <= 1}
      >
        <LeftArrow />
      </Button>

      <span className={styles.pageNumbers}>
        {currentPage} / {totalPages}
      </span>

      <Button
        kind="outline"
        className={styles.right}
        onClick={onNextClick}
        type="button"
        disabled={currentPage >= totalPages}
      >
        <RightArrow />
      </Button>
    </div>
  );

Pagination.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default Pagination;

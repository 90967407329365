import React from 'react';
import { string, node } from 'prop-types';

import styles from './Usage.module.scss';
import Title from '../Title/Title';
import Text from '../Text/Text';

const Usage = ({ children, title, description }) => (
  <div className={styles.wrapper}>
    <div className={styles.image}>{children}</div>
    <div className={styles.textWrapper}>
      <Title tag="3" className={styles.title}>
        {title}
      </Title>
      <Text className={styles.description}>{description}</Text>
    </div>
  </div>
);

Usage.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  children: node.isRequired,
};

export default Usage;

import React, { Component, Fragment } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import { withNamespaces } from 'react-i18next';

import Trans from 'src/components/shared/Trans/Trans';

import {
  showProcessor,
  updateProcessor,
} from 'src/api/processor_records';
import withToast from 'src/components/Toast/withToast/withToast';
import Button from 'src/components/shared/Button/Button';
import BreadcrumbTitle from 'src/components/shared/BreadcrumbTitle/BreadcrumbTitle';
import CaptionedInfo from 'src/components/shared/CaptionedInfo/CaptionedInfo';
import Columns from 'src/components/shared/Columns/Columns';
import ProcessorFormModal from 'src/components/shared/ProcessorRecordFormModal/ProcessorFormModal';
import Loading from 'src/components/shared/Loading/Loading';
import urls from 'src/constants/urls';
import styles from './ProcessorDetailsPage.module.scss';

class ProcessorDetailsPage extends Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
  };

  state = {
    processor: {},
    controller_id: null,
    editModal: false,
    isLoading: true,
    lastSubmittedProcessorName: '',
  };

  componentDidMount() {
    this.getProcessor();
  }

  onSubmit = processor =>
    new Promise(resolve => {
      const { history, t } = this.props;
      updateProcessor(processor)
        .then(result => {
          this.setState({
            lastSubmittedProcessorName: processor.name,
          });
          
          console.log(urls.processors(processor.processorId))
          history.push(urls.processors(processor.processorId));

          return resolve(result);
        })
        .catch(() => {
          resolve({
            name: t('error_messages.processor_name_already_exists'),
          });
        });
    });

  getProcessor = () => {
    const {
      match: {
        params: { controller_id, id },
      },
    } = this.props;

    showProcessor(controller_id, id).then(processor => this.setState({ processor: processor, isLoading: false, controller_id }));
  };

  toggleEditModal = () => this.setState(prevState => ({ editModal: !prevState.editModal }));

  render() {
    const {
      processor: {
        name,
        contactName,
        companyName,
        contactEmail,
        contactPhone,
        controllerName,
        additionalDetails,
      },
      controller_id,
      processor,
      editModal,
      isLoading,
      lastSubmittedProcessorName,
    } = this.state;

    const {
      onSubmit,
      toggleEditModal,
    } = this;

    const { t } = this.props;

    return (
      <main className={styles.processorDetailsPage}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <BreadcrumbTitle
              path={[
                {
                  label: t('shared.processors'),
                  pathElement: urls.processors(),
                },
                {
                  label: controllerName,
                  pathElement: urls.processors(controller_id),
                },
              ]}
              current={name}
            />

            <section className={styles.content}>
              <div className={styles.details}>
                <h3 className={styles.detailTitle}>
                  <Trans>processor_details_page.processor_details_title</Trans>
                  <div>
                    <Button kind="outline" onClick={toggleEditModal} type="button">
                      {t('shared.edit')}
                    </Button>
                  </div>
                </h3>

                <div className={styles.row}>
                  <CaptionedInfo title={t('processor_details_page.captioned_info.details')}>
                    {additionalDetails || t('processor_details_page.no_details')}
                  </CaptionedInfo>
                </div>
              </div>

              <div className={styles.organization}>
                <h3 className={styles.detailTitle}>
                  <Trans>processor_details_page.organization.detail_title</Trans>
                </h3>

                <Columns className={styles.row}>
                  {{
                    left: (
                      <CaptionedInfo title={t('shared.company_name')}>{companyName}</CaptionedInfo>
                    ),
                    right: (
                      <CaptionedInfo title={t('shared.company_email')}>
                        {contactEmail}
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>

                <Columns className={styles.row}>
                  {{
                    left: (
                      <CaptionedInfo title={t('shared.contact_person')}>
                        {contactName}
                      </CaptionedInfo>
                    ),
                    right: (
                      <CaptionedInfo title={t('shared.contact_phone')}>
                        {contactPhone}
                      </CaptionedInfo>
                    ),
                  }}
                </Columns>
              </div>
            </section>

            <ProcessorFormModal
              open={editModal}
              onClose={toggleEditModal}
              {...{ onSubmit, processor }}
              isEdit
              lastSubmittedProcessorName={lastSubmittedProcessorName}
            />
          </Fragment>
        )}
      </main>
    );
  }
}

export default withToast(withNamespaces()(ProcessorDetailsPage));

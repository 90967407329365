import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';
import { withNamespaces } from 'react-i18next';

import withToast from 'src/components/Toast/withToast/withToast';

import Trans from 'src/components/shared/Trans/Trans';
import { createProcessor, getAllProcessors } from 'src/api/processors';

import Button from 'src/components/shared/Button/Button';
import ProcessorFormFields from 'src/components/shared/ProcessorFormFields/ProcessorFormFields';

import ProcessorField from './ProcessorField/ProcessorField';
import Checkbox from 'src/components/shared/Checkbox/Checkbox';
import styles from './ProcessorStep.module.scss';
import Label from 'src/components/shared/Label/Label';

class ProcessorStep extends Component {
  static propTypes = {
    setProcessorField: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    openToast: PropTypes.func.isRequired,
  };

  state = {
    processorRecords: [],
    processorOptions: [],
  };

  componentDidMount() {
    this.fetchProcessorsOptions();
  }

  fetchProcessorsOptions = () =>
    getAllProcessors()
    .then(({ processors }) => {
        const { processorId } = this.props
        const mappedProcessors = processors.map(({ name, id }) => ({ label: name, value: id }));
        console.log(mappedProcessors)
        const controller = processors.find(p => p.id === processorId);
        this.setState({ processorOptions: mappedProcessors, processors });
        
        if (controller !== undefined) {
          this.setState({ processorRecords: controller.processorRecords});
        }

        return mappedProcessors;
      })
      .catch(() => {
        const { t, openToast } = this.props;

        openToast({
          message: t('declaration_form.processor_step.errors.fetch_processor'),
          type: 'danger',
          inModal: true,
        });
      });

  createNewProcessor = processor =>
    new Promise(resolve => {
      const { setProcessorField, t, openToast } = this.props;
      const { findProcessorById } = this;

      createProcessor(processor)
        .then(this.fetchProcessorsOptions)
        .then(processors => processors.find(({ label }) => label === processor.name))
        .then(({ value: id }) => {
          setProcessorField(findProcessorById(id));
          resolve();
        })
        .catch(({ status }) => {
          if (status === 422) {
            return resolve({
              name: t('error_messages.processor_name_already_exists'),
            });
          }

          return openToast({
            message: t('declaration_form.processor_step.errors.create_processor'),
            type: 'danger',
            inModal: true,
          });
        });
    });

  findProcessorById = id => {
    const { processors } = this.state;
    const controller = processors.find(p => p.id === id);
    this.setState({processorRecords: controller.processorRecords})

    return controller;
  };

  render() {
    const { processorOptions, processorRecords } = this.state;
    const { createNewProcessor, findProcessorById } = this;

    console.log(processorOptions)

    return (
      <div className={styles.processorStep}>
        <div className={styles.processorSelect}>
          <ProcessorField
            required
            name="processor"
            options={processorOptions}
            findProcessorById={findProcessorById}
            autoFocus
          />

          <div style={{ marginTop: '20px' }}>
            <Label required={true}>
              <Trans noMd>declaration_form.processor_step.processors</Trans>
            </Label>

            { processorRecords.map((processor) => (
              <div style={{ marginTop: '5px' }}>
                <Checkbox
                  key={ processor.id }
                  className={styles.row}
                  id={ processor.id }
                  value={ processor.id }
                  name="processorRecordIds"
                  label={ processor.name }
                />
              </div>
            ))}
          </div>
        </div>


        <div className={styles.separator}>
          <div className={styles.line} />

          <span className={styles.or}>
            <Trans noMd>shared.or</Trans>
          </span>

          <div className={styles.line} />
        </div>

        <Form onSubmit={createNewProcessor}>
          {({ handleSubmit }) => (
            <div>
              <ProcessorFormFields />

              <div className={styles.buttonRow}>
                <Button kind="primary" onClick={handleSubmit} type="submit">
                  <Trans noMd>declaration_form.processor_step.create_button</Trans>
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

export default withNamespaces()(withToast(ProcessorStep));

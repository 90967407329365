import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';

import Trans from 'src/components/shared/Trans/Trans';
import Switch from 'src/components/shared/Switch/Switch';

import { reduceValidators, isRequired } from 'src/utils/validators';

import styles from './AcceptanceBox.module.scss';

const AcceptanceBox = ({ children, required, name, validators, className }) => {
  const prefilledValidators = required ? [isRequired, ...validators] : validators;
  const validate = reduceValidators(prefilledValidators);

  return (
    <Field name={name} type="checkbox" validate={validate}>
      {({ meta: { invalid, touched }, input }) => (
        <div
          className={cn(styles.box, className, {
            [styles.error]: touched && invalid,
          })}
        >
          <div className={styles.spaceBetween}>
            <Switch {...input} />
            <span className={styles.label}>
              {required ? <Trans>shared.required</Trans> : <Trans>shared.optional</Trans>}
            </span>
          </div>
          <div className={styles.description}>{children}</div>
        </div>
      )}
    </Field>
  );
};

AcceptanceBox.defaultProps = {
  children: null,
  required: false,
  validators: [],
  className: '',
};

AcceptanceBox.propTypes = {
  children: PropTypes.node,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  validators: PropTypes.arrayOf(PropTypes.func),
};

export default AcceptanceBox;

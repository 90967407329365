import React from 'react';
import PropTypes from 'prop-types';
import { webhookPropTypes } from 'src/prop_types/processor_prop_types';

import { isURL } from 'src/utils/validators';

import { webhookEvents } from 'src/constants/webhookEvents';

import InputField from 'src/components/shared/InputField/InputField';
import Button from 'src/components/shared/Button/Button';
import Form from 'src/components/shared/OnBlurForm/OnBlurForm';

import Checkbox from 'src/components/shared/Checkbox/Checkbox';

import { withNamespaces } from 'react-i18next';
import styles from './WebhookForm.module.scss';

const WebhookForm = ({
  t,
  onSubmit,
  currentlyEditedWebhook: { events, ...currentlyEditedWebhook },
}) => (
  <Form
    onSubmit={(...args) => onSubmit(...args)}
    initialValues={{
      events_choice: events.length === webhookEvents.length ? 'send_everything' : 'let_me_choose',
      events: events.reduce(
        (acc, key) => ({
          ...acc,
          [key]: true,
        }),
        {},
      ),
      ...currentlyEditedWebhook,
    }}
  >
    {({ handleSubmit, values }) => (
      <form onSubmit={handleSubmit}>
        <InputField
          className={styles.row}
          label={t('processor_details_page.webhooks_modal.payload_url')}
          name="targetUrl"
          validators={[isURL]}
          required
        />

        <p className={styles.labelHeading}>
          {t('processor_details_page.webhooks_modal.trigger_question')}
        </p>

        <Checkbox
          className={styles.row}
          id="send_everything"
          value="send_everything"
          name="events_choice"
          label={t('processor_details_page.webhooks_modal.send_everything')}
          type="radio"
        />

        <Checkbox
          className={styles.row}
          id="let_me_choose"
          value="let_me_choose"
          name="events_choice"
          label={t('processor_details_page.webhooks_modal.let_me_choose')}
          type="radio"
        />

        {values.events_choice === 'let_me_choose' && (
          <div className={styles.eventsChoices}>
            {webhookEvents.map(event => (
              <Checkbox
                className={styles.row}
                key={event}
                id={event}
                name={`events[${event}]`}
                label={t(`processor_details_page.webhooks_modal.events_names.${event}`)}
              />
            ))}
          </div>
        )}
        <Button kind="primary" type="submit" className={styles.saveButton}>
          {t('shared.save')}
        </Button>
      </form>
    )}
  </Form>
);

WebhookForm.defaultProps = {
  currentlyEditedWebhook: {
    events: [],
    events_choice: 'send_everything',
  },
};

WebhookForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentlyEditedWebhook: webhookPropTypes,
};

export default withNamespaces()(WebhookForm);

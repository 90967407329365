import PropTypes from 'prop-types';

export const personalDataCategoriesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    created_at: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    organization_id: PropTypes.string,
    updated_at: PropTypes.string,
  }),
);

export const declaration = PropTypes.shape({
  id: PropTypes.string,
  body: PropTypes.string,
  author: PropTypes.string,
  dataSources: PropTypes.arrayOf(PropTypes.string),
  personalDataCategories: personalDataCategoriesPropTypes,
  processing: PropTypes.string,
  purpose: PropTypes.string,
  validity: PropTypes.number,
  validityUnit: PropTypes.string,
  withdrawDetails: PropTypes.string,
  automatedDecisionMakingEnabled: PropTypes.bool,
  automatedDecisionMakingDescription: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  thirdParties: PropTypes.array,
  validUntil: PropTypes.string,
  createdBy: PropTypes.shape({
    fullName: PropTypes.string,
    id: PropTypes.string,
  }),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  processorId: PropTypes.string,
  title: PropTypes.string,
});

export const declarationPropTypes = { declaration };

export const declarationListPropTypes = {
  declarations: PropTypes.arrayOf(declaration),
};

export const eventPropTypes = PropTypes.shape({
  version: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  requestId: PropTypes.string,
  declarationId: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  remoteIp: PropTypes.string.isRequired,
});

export const versionPropTypes = PropTypes.shape({
  remoteIp: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
});

export const eventListPropTypes = PropTypes.arrayOf(eventPropTypes);

export const thirdParty = PropTypes.shape({
  companyName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  country: PropTypes.string,
});

export const thirdPartyPropTypes = { thirdParty };

export const thirdPartyListPropTypes = {
  thirdParties: PropTypes.arrayOf(thirdParty),
};

export const declarationFormPropTypes = {
  declaration: PropTypes.shape({
    title: PropTypes.string,
    personalDataCategories: personalDataCategoriesPropTypes,
    purpose: PropTypes.string,
    body: PropTypes.string,
    processing: PropTypes.string,
    automatedDecisionMakingEnabled: PropTypes.bool,
    automatedDecisionMakingDescription: PropTypes.string,
    withdrawDetails: PropTypes.string,
    id: PropTypes.string,
    processorId: PropTypes.string,
    validityUnit: PropTypes.string,
    validity: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    thirdParties: PropTypes.array,
  }),
};

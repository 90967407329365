import React from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import Status from 'src/components/shared/Status/Status';

const messages = {
  valid: 'shared.valid',
  invalid: 'shared.invalid',
};

const ValidityStatus = ({ valid, t }) => {
  const type = valid ? 'success' : 'danger';
  const msgType = valid ? 'valid' : 'invalid';

  return <Status type={type} message={t(messages[msgType])} />;
};

ValidityStatus.propTypes = {
  valid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(ValidityStatus);

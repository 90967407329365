import React from 'react';
import PropTypes from 'prop-types';
import cb from 'clipboard-polyfill';
import cn from 'classnames';
import { withNamespaces } from 'react-i18next';
import Trans from 'src/components/shared/Trans/Trans';

import Button from 'src/components/shared/Button/Button';
import CopyIcon from 'src/components/image/CopyIcon';

import styles from './CopyButton.module.scss';

const CopyButton = ({ children }) => {
  const [isClicked, setIsClicked] = React.useState(false);

  const handleClick = () => {
    cb.writeText(children);
    setIsClicked(true);
  };

  const handleDisableClicked = () => setIsClicked(false);

  return (
    <Button
      kind="outline"
      className={cn(styles.copyButton, {
        [styles['copy-button--clicked']]: isClicked,
      })}
      onClick={handleClick}
      onAnimationEnd={handleDisableClicked}
      type="button"
    >
      <span className={styles.normal}>
        <CopyIcon />
      </span>
      <span className={styles.hover}>
        <Trans noMd>id_preview.hover_label</Trans>
      </span>
      <span className={styles.clicked}>
        <Trans noMd>id_preview.clicked_label</Trans>
      </span>
    </Button>
  );
};

CopyButton.propTypes = {
  children: PropTypes.string.isRequired,
};

export default withNamespaces()(CopyButton);

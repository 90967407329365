import PropTypes from 'prop-types';
import { declaration } from './declaration_prop_types';

const contract = {
  id: PropTypes.string,
  processor: PropTypes.string,
  date: PropTypes.string,
  validUntil: PropTypes.string,
  declarationId: PropTypes.string,
  status: PropTypes.bool,
  test: PropTypes.bool,
};

export const contractPropTypes = PropTypes.shape(contract);

export const contractWithDeclarationPropTypes = PropTypes.shape({
  ...contract,
  declaration,
});

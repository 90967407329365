import React from 'react';
import Trans from 'src/components/shared/Trans/Trans';

import NoContractsImage from 'src/components/image/NoContractsImage';
import urls from 'src/constants/urls';

import styles from './NoContracts.module.scss';

const NoContracts = ({ fromContractDeclaration = false }) => (
  <div className={styles.noContracts}>
    <NoContractsImage />

    {!fromContractDeclaration && (
      <>
        <h3>
          <Trans>contracts_page.no_contracts.title</Trans>
        </h3>
        <Trans vars={{ contract_declarations_href: urls.declarations() }}>
          contracts_page.no_contracts.description
        </Trans>
      </>
    )}
  </div>
);

export default NoContracts;

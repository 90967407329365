import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Columns.module.scss';

const Columns = ({ children, className }) => (
  <div className={cn(styles.columns, className)}>
    <div className={cn({ [styles.noContent]: !children.left }, styles.left)}>{children.left}</div>
    <div className={cn({ [styles.noContent]: !children.right }, styles.right)}>
      {children.right}
    </div>
  </div>
);

Columns.defaultProps = {
  children: {},
  className: '',
};

Columns.propTypes = {
  children: PropTypes.shape({
    left: PropTypes.node,
    right: PropTypes.node,
  }),
  className: PropTypes.string,
};

export default Columns;

import React from 'react';
import source from 'images/landing_section_1.svg';

import Icon from './Icon';

const LandingSection = ({ ...props }) => (
  <Icon alt="Landing section" src={source} width="360" height="409" {...props} />
);

export default LandingSection;
